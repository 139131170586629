import {createSelector} from '@reduxjs/toolkit';
import {M} from 'common/helpers';
import {range} from 'lodash';
import {initialState, State, timelineSliceName} from './slice';
import dayjs from 'dayjs';

const selectDomain = (state: any): State => state[timelineSliceName] || initialState;

export const startHour = createSelector(selectDomain, ({timeStart}) => timeStart);
export const workdayLength = createSelector(selectDomain, ({timelineLengthHours}) => timelineLengthHours);

export const timelineHours = createSelector(
  [selectDomain, (_, props: { isTablet: boolean}) => props.isTablet],
  ({timelineLengthHours, timeStart}, isTablet) => range(timelineLengthHours)
    .map((workHour) => ({
      hour: (timeStart + workHour) % 24,
      shift: workHour,
    }))
    .filter((_, idx) => {
      if (!isTablet) return true;
      return idx % 2 === 0;
    }),
);

/**
 * Смещение слайдера относительно сейчас
 */
export const shift = createSelector(selectDomain, ({sliderMinutesShift}) => (sliderMinutesShift !== null ?
  M.duration(sliderMinutesShift, 'minutes') :
  null));


export const getSliderTime = createSelector(
  [shift, startHour],
  (shiftDuration, start) => () => {
    if (!shiftDuration) return M();
    // Начало рабочего дня может начинаться не с целого часа.
    // Получаем дробную часть и конвертируем ее в минуты
    const minutes = (start % 1) * 60;
    return M().hour(start).startOf('hour')
      .minute(minutes)
      .add(shiftDuration)
      .startOf('minute');
  },
);

export const getTimestamp = createSelector(selectDomain, ({timestamp}) => timestamp || dayjs().valueOf())

export const getIsActualTime = createSelector(selectDomain, ({isActualTime}) => isActualTime)
