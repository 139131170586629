import {useMemo} from "react";
import cn from "classnames";
import {Field, useField, useForm, useFormState} from "react-final-form";
import {useSelector} from "react-redux";

import {BottomBar, Button, Labeled, ICONS} from "ui-kit";
import {config} from "config";
import {sourcesSelector} from "features/AppContex/selectors";
import {useFromProxyActions} from "features/BookingFormProxy";
import {useHallSchemaActions} from "features/HallSchema";
import {appContextSelectors} from "features/AppContex";
import * as select from "features/BookingFormProxy/selectors";
import {PlaceSelect} from "components/PlaceSelect";

import {ChoiceDateInput, ChoiceTimeInput} from "../sub-renders/date-time";
import {ChoiceClientPhoneInputRedux} from "../sub-renders/phone";
import {ChoiceClientInfoRedux} from "../sub-renders/client";
import {ChoiceVisitTimeButtonsInput, ChoiceVisitTimeControls} from "../sub-renders/visit-time";
import {
  ChoiceAutoTableInputRedux,
  ChoicePersonsInput,
  ChoiceTablesInputRedux,
} from "../sub-renders/location";
import {ChoiceNotesInput} from "../sub-renders/note";
import {ChoiceTagsInput} from "../sub-renders/tags";
import {ChoiceSourceInput} from "../sub-renders/source";
import {ChoiceDepositInput} from "../sub-renders/deposit";
import {ChoiceHostessInput} from "../sub-renders/hostess";
import {ChoiceDefaultUser} from "../sub-renders/default-user";
import {phoneValidInput, requiredField, validateClient} from "../validators/validators";
import {useAppUsers} from "contexts/AppUsersContext";
import {FormComplexityType} from "./types";


import {useIntlUtils} from "hooks/useIntlUtils";
import {ETranslations} from "types/translates";
import {capitalizeString} from "utils";
import {Client} from "types/client";
import {VisitControl} from "./FormControls/VisitControl";
import {Prolongate} from "./FormControls/Prolongate";
import {CancelBooking} from "./FormControls/CancelBooking";
import {Edit} from "./FormControls/Edit";
import {PrintBooking} from "./FormControls/PrintBooking";


import styles from "./Form.module.scss";
import {Booking} from "../../../types/booking";

interface BookingFormProps extends FormComplexityType<any> {
  isRegisterVisit?: boolean;
}

export const BookingForm = (props: BookingFormProps) => {
  const {
    isRegisterVisit,
  } = props;
  const {
    intl,
    getIntlJoinedParts,
    getIntlNumberOf,
    isRussianLocale,
    getIntlErrorNotSelected,
    getIntlAddOf,
  } = useIntlUtils();
  const form = useForm();
  const {valid, submitting} = useFormState();
  const {reset, focus, submit} = form;
  const {userList} = useAppUsers();
  const {setActiveTables} = useHallSchemaActions();
  const {resetClient} = useFromProxyActions();
  const sources = useSelector(sourcesSelector);
  const defaultClient = useSelector(appContextSelectors.defaultUserSelector);
  const isEditMode = useSelector(select.isEdit);

  const {input: {value: tables}} = useField("tables", {subscription: {value: true}});
  const {input: {value: client}} = useField<Client>("client", {subscription: {value: true}});
  const {input: {value: phone}} = useField<string>("phone", {subscription: {value: true}});
  const {input: {value: bookingId}} = useField<number>("bookingId", {subscription: {value: true}});
  const {input: {value: notes}} = useField<string>("notes", {subscription: {value: true}});

  const isCreateBooking = !bookingId;
  const disabled = !isCreateBooking && !isEditMode;

  const intlTitles = useMemo(() => {
    const came = intl.formatMessage({id: ETranslations.CAME});
    const create = isRussianLocale ? capitalizeString(intl.formatMessage(
        {id: ETranslations.BASE_ENTITY_CREATION},
        {entity: intl.formatMessage({id: ETranslations.BASE_BOOK}).toLowerCase()},
      ))
      : getIntlAddOf(ETranslations.PLURAL_BOOKING);
    return {
      tableNumber: getIntlNumberOf(isRussianLocale ? ETranslations.PLURAL_TABLES_ALT : ETranslations.PLURAL_TABLE),
      addReservation: isRegisterVisit ? came : create,
      guestWithoutPhone: getIntlJoinedParts([ETranslations.PLURAL_GUEST, ETranslations.WITHOUT, ETranslations.OF_PHONE]),
    };
  }, [isRegisterVisit]);

  const waitListEnabled = useMemo(() => {
    if (tables?.length) return false;
    if (isRegisterVisit) return false;
    return valid;
  }, [tables, valid]);

  const handleReset = () => {
    resetClient();
    setActiveTables({activeTables: []});
    reset();
  };

  // touching phone at init
  isCreateBooking && setTimeout(() => focus("phone"), 180);

  const canBeCreated = useMemo(() => {
    if (submitting) return false;
    if (waitListEnabled) return true;
    return valid;
  }, [submitting, waitListEnabled, valid]);

  const isNeedToValidatePhone = useMemo(() => client.client_id === defaultClient?.client_id, [client, defaultClient]);
  const isNotesFieldVisible = useMemo(() => !isEditMode && notes?.length > 0, [isEditMode, notes])

  return (
    <>
      <form
        className={cn(styles.form, "custom-scroll")}
      >
        {isRegisterVisit ? (
          Boolean(config) && (
            <Field
              name="auto_user"
              component={ChoiceDefaultUser}
              buttonTitle={intlTitles.guestWithoutPhone}
            />
          )
        ) : (
          <Labeled
            label={getIntlJoinedParts([ETranslations.BASE_DATE, ETranslations.AND, ETranslations.BASE_TIME])}
            required as="div"
          >
            <div className={cn("content", styles.dateTime)}>
              <Field
                name="date"
                component={ChoiceDateInput}
                disabled={disabled}
                validate={(value) => requiredField(value, intl.formatMessage({id: ETranslations.ERROR_DATE_NOT_SPECIFIED}))}
              />
              <ChoiceTimeInput allowValueFromOutsideRange={!isCreateBooking} disabled={disabled} />
            </div>
          </Labeled>
        )}

        <Field
          name="phone"
          component={ChoiceClientPhoneInputRedux}
          validate={(value) => (isNeedToValidatePhone ? undefined : phoneValidInput(value))}
          autoFocus
          required
          disabled={disabled || isNeedToValidatePhone}
          label={intl.formatMessage({id: ETranslations.PHONE})}
          className={cn("phone book add required", {hasValue: !phoneValidInput(phone)})}
        />
        <Field
          name="client"
          component={ChoiceClientInfoRedux}
          validate={(value) => validateClient(value, defaultClient, intl.formatMessage({id: ETranslations.ERROR_CLIENT_MUST_BE_VALID}))}
          disabled={disabled}
          canEditNames={!disabled || isCreateBooking}
          isCreateBooking={isCreateBooking}
        />
        <Labeled
          className={styles.fullRow}
          label={intl.formatMessage({id: ETranslations.DURATION})}
          as="div"
        >
          <div className={styles.visitTime}>
            {isCreateBooking && <Field name="visit_time" component={ChoiceVisitTimeButtonsInput} />}
            <Field
              name="visit_time"
              component={ChoiceVisitTimeControls}
              validate={(value) => !value}
              disabled={disabled}
            />
          </div>
        </Labeled>
        <div className={cn(styles.fullRow, styles.location)}>
          <Field
            name="persons"
            component={ChoicePersonsInput}
            label={capitalizeString(intl.formatMessage({id: ETranslations.PLURAL_GUESTS_NOM}))}
            validate={(value) => requiredField(value, intl.formatMessage({id: ETranslations.ERROR_REQUIRED_FIELD}))}
            required
            disabled={disabled}
          />
          <Labeled
            label={intl.formatMessage({id: ETranslations.SEATING_AREA})}
            required
          >
            <PlaceSelect disabled={disabled} />
          </Labeled>
          <Labeled required label={intlTitles.tableNumber}>
            <div className="openSchemeContainer">
              <Field
                name="tables"
                component={ChoiceTablesInputRedux}
                disabled={disabled}
                validate={(value) => {
                  if (isRegisterVisit) return !value.length;
                  return false;
                }}
                isShowTableScheme={!isRegisterVisit && !isCreateBooking}
              />
            </div>
          </Labeled>
          <ChoiceAutoTableInputRedux disabled={disabled} />
        </div>
        <div className={cn(styles.fullRow, styles.twoCol)}>
          <Field name="comment" component={ChoiceNotesInput}
                 label={intl.formatMessage({id: ETranslations.RESERVATION_NOTE})}
                 disabled={disabled}
          />
          {isNotesFieldVisible && <Field name="notes" component={ChoiceNotesInput}
                  label={intl.formatMessage({id: ETranslations.TABLE_PLACE})}
                  disabled
          />}
        </div>
        <Labeled label={intl.formatMessage({id: ETranslations.RESERVATION_TAGS})}>
          <Field name="tags" component={ChoiceTagsInput} disabled={disabled} />
        </Labeled>
        <Labeled label={intl.formatMessage({id: ETranslations.SOURCE})}>
          <Field name="source" component={ChoiceSourceInput} options={sources} disabled={disabled} />
        </Labeled>
        <Labeled label={intl.formatMessage({id: ETranslations.DEPOSIT})}>
          <Field name="deposit" component={ChoiceDepositInput} disabled={disabled} />
        </Labeled>
        <Labeled required label={intl.formatMessage({id: ETranslations.BOOKED_BY})}>
          <Field
            name="hostess"
            component={ChoiceHostessInput}
            options={userList}
            validate={(value) => requiredField(value, getIntlErrorNotSelected(ETranslations.HOSTESS))}
            disabled={disabled}
          />
        </Labeled>
        {!isCreateBooking && config.isPrintAvailable && (
          <div className={styles.printRow}>
            <PrintBooking />
          </div>
        )}
      </form>
      {isCreateBooking ? <BottomBar>
        <BottomBar.Part placement="left">
          <Button
            variant={waitListEnabled ? "dangerous" : "primary"}
            className={cn({loader: submitting})}
            type="submit"
            disabled={!canBeCreated}
            onClick={submit}
          >
            {!waitListEnabled ? intlTitles.addReservation : intl.formatMessage({id: ETranslations.WAITING_LIST})}
            {submitting ? <img src={ICONS.loadingSmall} alt="" /> : <></>}
          </Button>
          <Button
            variant="secondary"
            type="button"
            onClick={handleReset}
          >
            {intl.formatMessage({id: ETranslations.BASE_RESET})}
          </Button>
        </BottomBar.Part>
      </BottomBar> : <BottomBar>
        <BottomBar.Part placement="left">
          <VisitControl />
        </BottomBar.Part>
        <BottomBar.Part placement="center">
          <Prolongate />
        </BottomBar.Part>
        <BottomBar.Part placement="right">
          <CancelBooking />
          <Edit showSave />
        </BottomBar.Part>
      </BottomBar>}
    </>
  );
};
