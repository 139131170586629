import {Source, SourceServer} from "../../models/booking.model";
import {Client} from "../../models/client.model";
import {IResponse} from "../../models/common";
import {useMemo} from "react";
import {Status} from "../../types/booking";
import {ELocales, Response} from "../../types/commons";
import {config} from "config";
import {api, relativePathApi} from "./api";
import {ETranslations} from "../../types/translates";
import {getUserLocale} from "../../utils";

export const dictionariesApi = api.enhanceEndpoints({addTagTypes: ["Dicts"]}).injectEndpoints({
  endpoints: (build) => ({
    fetchAllSources: build.query<Source[], any>({
      query: () => ({
        url: "v2/sources",
      }),
      providesTags: ["Dicts"],
      transformResponse: (response: IResponse<SourceServer[]>) => response.data.map((it) => ({
        ...it,
        label: it.source_name,
        value: it.id,
      })),
    }),
    fetchAllStatus: build.query<Status[], void>({
      query: () => ({
        url: "v2/status",
      }),
      providesTags: ["Dicts"],
      transformResponse: (response: Response<Status[]>) => response.data,
    }),
    fetchDefaultUser: build.query<Client, any>({
      query: () => ({
        url: "/reservation/client",
        params: {
          client_id: config.autoGuestId,
        },
      }),
      providesTags: ["Dicts"],
      transformResponse: (response: IResponse<Client>) => response.data,
    }),
  }),
});

export const localResourcesApi = relativePathApi.injectEndpoints({
  endpoints: (build) => ({
    fetchTranslates: build.query<Record<ETranslations, string>, string>({
      query: (locale) => ({
        url: `/lang/${locale}.json`,
      }),
    }),
  }),
});

export const {
  useFetchAllSourcesQuery,
  useFetchDefaultUserQuery,
  useFetchAllStatusQuery,
} = dictionariesApi;

export const {useFetchTranslatesQuery} = localResourcesApi;

const EMPTY: Status[] = [];

export function useAllStatuses() {
  const {data, ...rest} = useFetchAllStatusQuery();
  const sorted = useMemo(() => Array.from(data ?? EMPTY).sort((a, b) => a.step - b.step), [data]);
  return {...rest, data: sorted};
}

export function useActiveStatuses() {
  const {data: allStatuses, ...rest} = useFetchAllStatusQuery();
  const active = useMemo(() => allStatuses?.filter((s) => !s.isTerminal) ?? EMPTY, [allStatuses]);
  return {...rest, data: active};
}

export function useCompleteStatuses() {
  const {data: allStatuses, ...rest} = useFetchAllStatusQuery();
  const complete = useMemo(() => allStatuses?.filter((s) => s.isTerminal) ?? EMPTY, [allStatuses]);
  return {...rest, data: complete};
}

export function useTranslates(userLocale?: ELocales): {translates: Record<ETranslations, string>, isSuccess: boolean} {
  const locale = (userLocale || getUserLocale()).split('_')[0];
  const {data: translates = ({} as Record<ETranslations, string>), isSuccess} = useFetchTranslatesQuery(locale);
  return {translates, isSuccess};
}
