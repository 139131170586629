import React, {useCallback, useEffect, useMemo, useState} from 'react';
import cn from 'classnames';
import {FieldRenderProps, useField, useForm} from 'react-final-form';
import {Button, Input} from 'ui-kit';
import {ICONS} from '../../../common/helpers';
import {useIsTablet} from '../../../hooks/useIsTablet';
import {HallMode, useHallSchemaActions} from '../../../features/HallSchema';
import {useSelector} from 'react-redux';
import {hallModeSelector} from '../../../features/HallSchema/selectors';
import {isRegView} from '../../hall-scheme/redux/HallSchemaV2/hall-helpersFn';
import {useFetchGuestsPageQuery} from '../../../features/api/guest-api';
import {guestListFilter} from '../../../features/GuestsList/selectors';
import {formatPhoneNumberIntl, isValidPhoneNumber} from 'react-phone-number-input';
import {useGuestListActions} from '../../../features/GuestsList';
import {useFromProxyActions} from '../../../features/BookingFormProxy';
import {selectedGuestSelector} from 'features/BookingFormProxy/selectors';
import {defaultUserSelector} from 'features/AppContex/selectors';
import {throttle} from 'lodash';
import {isRealClient} from '../validators/validators';

import styles from './Phone.module.scss';
import commonStyles from './style.module.scss';

import {useThrottle} from 'react-use';

const ChoicePhoneRedux = ({value, onChange, onFocus, onBlur, disabled, autoFocus, className, ...props}: any) => {
  const hallMode = useSelector(hallModeSelector);
  const filter = useSelector(guestListFilter);
  const {applyFilter, reset} = useGuestListActions();
  const {setClient, resetClient, setEditClient} = useFromProxyActions();
  const client = useSelector(selectedGuestSelector);
  const defaultClient = useSelector(defaultUserSelector);
  const [searchPhone, setSearchPhone] = useState(value);

  useThrottle(() => {
    setSearchPhone(value);
  }, 1e3);

  const filterWithPhone = useMemo(() => ({
      ...filter,
      phone: searchPhone,
    }), [filter, searchPhone]);
  // this hook is for cases when guest component is unmount
  const {data = []} = useFetchGuestsPageQuery(filterWithPhone);
  const isTablet = useIsTablet();
  const form = useForm();
  // @ts-ignore
  const isDefaultClient = useMemo(() => client && (client?.user_id === defaultClient?.user_id), [client, defaultClient]);

  const throttledFilter = useMemo(() => throttle(applyFilter, 1e3), [applyFilter]);

  useEffect(() => {
    if (disabled) return;
    if (isDefaultClient) return;
    const [nextClient] = data;
    if (isValidPhoneNumber(value) || isTablet) {
      if (data?.length === 1 && nextClient.phone.replace(/\D/g, '') === value.replace(/\D/g, '')) {
        setClient({client: data[0]});
        form.batch(() => {
          form.change('client', data[0]);
          form.change('phone', formatPhoneNumberIntl(data[0].phone));
          form.change('client_id', data[0].client_id)
        });
      }
    }
  }, [data, isDefaultClient, disabled, value]);

  useEffect(() => {
    if (!isValidPhoneNumber(value) && !client) {
      resetClient();
    }
  }, [value, client]);

  useEffect(() => {
    if (isRealClient(client) && client?.phone && !disabled) {
      const clientPhone = formatPhoneNumberIntl(client.phone);
      clientPhone && onChange(clientPhone);
    }
  }, [client]);

  const {switchMode} = useHallSchemaActions();

  const showClients = () => {
    if (isRegView(hallMode)) {
      switchMode(HallMode.REGISTRATION_GUESTS);
      return;
    }
    if (hallMode === HallMode.TABLE_BOOKINGS_EDIT) {
      switchMode(HallMode.TABLE_BOOKINGS_EDIT_GUEST);
      return;
    }
    switchMode(HallMode.BOOKING_GUEST);
  };



  const clearPhoneNumber = useCallback(() => {
    if (disabled) return;
    onChange('');
    reset();
    setEditClient(false);
    form.batch(() => {
      form.change('client', undefined);
      form.change('client_id', undefined);
      form.change('phone', undefined);
    });
    resetClient();
  }, [onChange, disabled]);

  useEffect(() => {
    if (value && value.length >= 3) {
      throttledFilter({...filter, phone: value});
    }
  }, [value]);

  return (
    <div className={cn(styles.container, className)}>
      <Input.Phone
        autoFocus={autoFocus}
        value={value}
        onChange={onChange}
        disabled={disabled || client}
        onBlur={onBlur}
        onFocus={onFocus}
        className={styles.phone}
        autoComplete="off"
        inputClassName={commonStyles.boldText}
        suffix={!disabled && !!value.length && (
          <img
            src={ICONS.crossGrey}
            onClick={clearPhoneNumber}
            alt="Clear phone number"
            height={20}
            width={20}
            className={styles.clear}
          />
        )}
        {...props}
      />
      {!disabled && !client && (
      <button
        type="button"
        onClick={showClients}
        className={styles.guestButton}
      >
        <ICONS.GuestsIcon width="100%" height="100%" className={styles.guestIcon} />
      </button>
    )}
    </div>
  );
};

export const ChoiceClientPhoneInputRedux:React.FC<FieldRenderProps<any>> = ({input, meta, ...rest}) => (
  <ChoicePhoneRedux {...input} {...meta} {...rest} />
);
