import React, {forwardRef} from "react";
import {clientFullName, ICONS} from "common/helpers";
import {Client} from "types/client";
import {config} from "config";


import styles from "./BookingClientInfo.module.scss";

interface BookingClientInfoProps {
  client: Client;
}

export const BookingClientInfo = forwardRef<HTMLSpanElement, BookingClientInfoProps>(({client}, ref) => {
  return (
    <div className={styles.bookingClientInfo}>
      {config.vipSign && client.vip && <img width={20} className="star" src={ICONS.StarA} alt="vip" />}
      <span className={styles.clientName} ref={ref}>
            {clientFullName(client)}
      </span>
      {config.clientsHasLoyalty && Boolean(client.grade_name) && (
        <>
          <ICONS.DotIcon />
          <span className={styles.gradeName}>
            {client.grade_name}
          </span>
        </>
      )}
    </div>
  );
});
