import {CSSProperties, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useBoolean, useLocalStorage} from 'react-use';
import cn from 'classnames';
import {ICONS, M, pad2} from 'common/helpers';
import SnapTime from 'common/time';
import {TimeRangePicker, Range} from './TimeRangePicker';
import {Spinner} from 'ui-kit';
import styles from './ScheduleLanding.module.scss';
import {useGetBookingPlacementQuery} from 'features/api/hallschema-api';
import {appContextSelectors} from 'features/AppContex';
import {useSelector} from 'react-redux';

import Checkbox from 'common/components/checkbox';
import {useAllStatuses} from 'features/api/dictionaries-api';
import {Tabs} from 'ui-kit';
import {SeatType} from 'types/place';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {BookingStatus} from 'types/booking';
import {SelectCheckbox, Card} from 'ui-kit'
import {useIntl} from "react-intl";
import {ETranslations} from "../../types/translates";
import {STATUSES_TITLES, HALLS_TITLES} from "../../constants";
import {capitalizeString} from '../../utils';
import {ELocales} from '../../types/commons';
import dayjs from 'dayjs';

const IN_FACT_STATUSES = [
  BookingStatus.IN_HALL,
  BookingStatus.CLOSED,
];

enum Tab {
  booked = 'booked',
  fact = 'fact',
}

export function ScheduleLanding() {
  const intl = useIntl();
  const [timeRange, setTimeRange] = useState<Range>([null, null]);
  const date = useSelector(appContextSelectors.date);
  const restaurant = useSelector(appContextSelectors.restaurant);
  const [tab, setTab] = useState<string>(Tab.booked);

  const {data: allStatuses} = useAllStatuses();

  const calculateStatuses = useCallback((currentTab: Tab) => (currentTab === Tab.booked
      ? allStatuses : allStatuses.filter((s) => IN_FACT_STATUSES.includes(s.systemName))), [allStatuses]);

  const availableStatuses = useMemo(() => calculateStatuses(tab as Tab), [allStatuses, tab]);

  const statusesOptions = useMemo(() => availableStatuses.map((s) => ({label: s.name, value: s.id, color: s.color})), [availableStatuses]);

  // const [selectedStatuses, setSelectedStatuses] = useState(statusesOptions);
  const [userLocale] = useLocalStorage<ELocales>('locale');
  const [selectedStatuses = statusesOptions, setSelectedStatuses] = useLocalStorage<typeof statusesOptions>(
    `schedule:filter:statuses:${tab}:${userLocale}`
  );

  const onlySelectedStatuses = useMemo(() => availableStatuses
    .filter((s) => selectedStatuses.some((o) => o.value === s.id)), [availableStatuses, selectedStatuses]);

  const placesOptions = useMemo(() => restaurant.places.map((p) => ({label: p.name, value: p.id})), [restaurant]);
  const [selectedPlaces, setSelectedPlaces] = useState(placesOptions);

  const [withResidenceTime, setWithResidenceTime] = useBoolean(true);

  const [excludeFact, setExcludeFact] = useState(false);

  const {data, isFetching} = useGetBookingPlacementQuery(selectedStatuses.length ? {
    restaurant_id: restaurant.restaurant_id,
    date: date.format('YYYY-MM-DD'),
    start_time_filter: timeRange[0] || undefined,
    end_time_filter: timeRange[1] || undefined,
    places_filter: selectedPlaces.map((p) => p.value),
    status_filter: selectedStatuses.map((s) => s.value),
    use_visit_time: !withResidenceTime,
    seat_type: tab === Tab.fact ?
      [SeatType.IN_WALK] :
      [excludeFact ? undefined : SeatType.IN_WALK, SeatType.BOOK, SeatType.WAIT_LIST].filter((v): v is SeatType => !!v),
  } : skipToken);


  const restaurantWorkedTime = useMemo(() => {
    return {start: '00:00', duration: (24 * 60) - 1}
  }, []);



  useEffect(() => {
    setSelectedPlaces(placesOptions);
  }, [placesOptions]);

  const activeRow = useRef<HTMLTableRowElement>(null);

  useEffect(() => {
    activeRow.current?.scrollIntoView();
  }, [data]);

  const handleTabChange = useCallback((nextTab: Tab) => {
    setTab(nextTab);
   }, [setTab]);

  const renderTableRows = () => {
    if (!data?.length) {
      return null;
    }

    const currentTime = SnapTime.constructorMoment(M());
    const currentStr = `${currentTime.hours}:${pad2(currentTime.mins)}`;

    const totalStatus: Record<string, {guests: number, bookings: number }> = { };
    const currentDate = dayjs(date.format('YYYY-MM-DD'))

    const arrayRows = data.filter(({time}) => {
      const timeItem = dayjs(time, 'DD/MM/YY');
      return currentDate.isSame(timeItem, 'day')
    }).map((item, idx, arr) => {
      const isTimeActive = currentStr === item.time.slice(0, 5);
      const next = arr[idx + 1];
      const isNextActive = next && currentStr === next.time.slice(0, 5);
      let totalBookings = 0;
      let totalGuests = 0;
      return (
        <tr key={item.time} className={cn(styles.row, {[styles.activeRow]: isTimeActive})} ref={isNextActive ? activeRow : undefined}>
          <td>{dayjs(item.time, 'DD/MM/YY HH:mm').format('HH:mm')}</td>
          {onlySelectedStatuses.map((status) => {
            const {bookings, guests} = item[status.systemName] ?? {bookings: 0, guests: 0};
            totalBookings += bookings;
            totalGuests += guests;

            const statsForStatus = totalStatus[status.systemName] ?? {};
            statsForStatus.bookings = (statsForStatus?.bookings ?? 0) + bookings;
            statsForStatus.guests = (statsForStatus?.guests ?? 0) + guests;
            totalStatus[status.systemName] = statsForStatus;
            return (
            <td key={status.id}>
                {bookings}
                <span className={styles.guests}>
                  &nbsp;/&nbsp;
                  {guests}
                </span>
            </td>
            );
          })}
          <td>
            {totalBookings}
            <span className={styles.guests}>
              &nbsp;/&nbsp;
              {totalGuests}
            </span>
          </td>
        </tr>
      );
    });

    const total = Object.values(totalStatus).reduce((acc, value) => {
      acc.guests += value.guests;
      acc.bookings += value.bookings;
      return acc;
    }, {bookings: 0, guests: 0});

    return (
      <>
        <tbody>
          {arrayRows}
        </tbody>
        <tfoot className={styles.footer}>
          <tr>
            <td>{intl.formatMessage({id: ETranslations.BASE_TOTAL})}</td>
            {onlySelectedStatuses.map((status) => (
              <td key={status.id} style={{'--status-color': status.color} as CSSProperties}>
                {totalStatus[status.systemName]?.bookings ?? 0}
                <span className={styles.guests}>
                  &nbsp;/&nbsp;
                  {totalStatus[status.systemName]?.guests ?? 0}
                </span>
              </td>
            ))}
            <td>
              {total.bookings ?? 0}
                <span className={styles.guests}>
                  &nbsp;/&nbsp;
                  {total.guests ?? 0}
                </span>
            </td>
          </tr>
        </tfoot>
      </>
    );
  };

    return (
      <Card className={styles.layout}>
        <Card.HeaderTabs  activeTab={tab} onChange={handleTabChange} >
          <Tabs.Item title={intl.formatMessage({id: ETranslations.BOOKED_PLURAL})} icon={ICONS.Booked} tabName={Tab.booked} />
          <Tabs.Item title={intl.formatMessage({id: ETranslations.IN_FACT})} icon={ICONS.InHall} tabName={Tab.fact} />
        </Card.HeaderTabs>
        <Card.Content>
          <div className={styles.filters}>
          <SelectCheckbox
            value={selectedPlaces}
            //@ts-ignore
            onChange={setSelectedPlaces}
            placeholder={intl.formatMessage({id: ETranslations.PLURAL_HALLS_NOM})}
            listSize={9}
            titles={HALLS_TITLES}
            options={placesOptions}
          />
          <SelectCheckbox
            value={selectedStatuses}
            //@ts-ignore
            onChange={setSelectedStatuses}
            placeholder={intl.formatMessage({id: ETranslations.PLURAL_STATUSES_NOM})}
            listSize={9}
            titles={STATUSES_TITLES}
            options={statusesOptions}
          />
          <TimeRangePicker range={timeRange} onRangeChange={setTimeRange} restaurantWorkedTime={restaurantWorkedTime} />
        </div>
        <div className={styles.checkboxes}>
          {tab !== Tab.fact && (
            <Checkbox
              text={intl.formatMessage({id: ETranslations.IN_FACT_EXCLUDE})}
              onChange={setExcludeFact}
              value={excludeFact}
            />
          )}
          <Checkbox
            text={intl.formatMessage({id: ETranslations.STAY_TIME_EXCLUDE})}
            onChange={setWithResidenceTime}
            value={withResidenceTime}
          />
        </div>
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead className={styles.header}>
                <tr>
                  <th>{capitalizeString(intl.formatMessage({id: ETranslations.BASE_TIME}))}</th>
                  {onlySelectedStatuses.map((status) => (
                    <th key={status.id} style={{color: status.color || ''}}>
                      {status.name}
                    </th>
                  ))}
                  <th>{intl.formatMessage({id: ETranslations.BASE_TOTAL})}</th>
                </tr>
              </thead>
              {renderTableRows()}
            </table>
          </div>
          {isFetching && <Spinner className={styles.spinner} />}
        </Card.Content>
      </Card>
    );
}
