import React, {ChangeEvent, useRef} from 'react';
import {Button, ICONS} from 'ui-kit';
import styles from "./FileInput.module.scss"
import {Popup, PopupRef} from '../../Popup';
import {useBooleanState} from '../../../hooks/useBooleanState';
import {UploadActions} from './UploadActions';
import {Client} from 'models/client.model';
import {useLazyFetchClientQuery, useUpdateGuestMutation} from '../../../features/api/guest-api';
import {TNullable} from 'types/commons';

type TProps = {
    onChangeFile: (file: File | null) => void
    guest?: Client
    previewUrl: TNullable<string>
}
export const FileInput: React.FC<TProps> = ({onChangeFile, guest, previewUrl}) => {

    const fileInputRef = useRef<HTMLInputElement>(null);
    const popup = useRef<PopupRef>(null);
    const [, , hide] = useBooleanState(false);
    const [, setWasOpen] = useBooleanState(false);
    const [updateGuest] = useUpdateGuestMutation()
    const [getClient] = useLazyFetchClientQuery()


    const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if(file){
            onChangeFile(file)
            event.target.value = ''
        }
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const removeProfileImage =async () => {
        if(previewUrl) {
            onChangeFile(null)
        }
        if(guest){
            await updateGuest({...guest, client_photo: ''})
            await getClient(guest.client_id)
        }
    }

    return (
        <div style={{height: 0}}>
            {guest?.client_photo || previewUrl ? (
              <Popup placement="right" ref={popup} onClose={hide} onOpen={setWasOpen}>
                  <Popup.Trigger>
                      <Button variant="phantom">
                          <ICONS.EditSmall
                            className={styles.editPhoto}
                          />
                      </Button>
                  </Popup.Trigger>
                  <Popup.Content noPadding>
                      <UploadActions
                        handleButtonClick={handleButtonClick}
                        removeProfileImage={removeProfileImage}
                      />
                  </Popup.Content>
              </Popup>
            ) : (
              <ICONS.EditSmall
                className={styles.editPhoto}
                onClick={handleButtonClick}
              />
            )}
            <input
                type="file"
                accept="image/*"
                onChange={handleFileSelect}
                ref={fileInputRef}
                className={styles.inputFile}
            />
        </div>
    );
}
