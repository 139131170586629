import {Client as OldClient} from '../../../models/client.model';
import parsePhoneNumber from 'libphonenumber-js';
import {Client} from 'types/client';

export function requiredField(value: any, message?: string) {
  return (value ? undefined : (message || 'Required'));
}

export function validateClient(value: Client | OldClient | undefined, defaultClient: Client | null, message: string) {
  if (value === defaultClient) return true;
  return [value?.name, value?.surname].filter(Boolean).length < 1 ? message : undefined;
}

export const isRealClient = (client: Client | OldClient | null | undefined) => client && Boolean(client?.client_id);

export function phoneValidInput(value: string, message?: string) {
  return (value && parsePhoneNumber(value)?.isValid() ?
    undefined :
    (message || 'Phone not valid format'));
}
