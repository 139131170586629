import {IResponse} from "../../models/common";
import {SwapTarget} from "../../models/booking.model";
import {useSelector} from "react-redux";
import {appContextSelectors} from "features/AppContex";
import moment from "moment";
import {middlePollingInterval} from "./utils";
import {Booking} from "types/booking";
import {Notification} from "services/notification";
import {bookingApi} from "./bookings-api";
import {api} from "./api";
import {useEffect, useMemo} from "react";
import {SeatType} from "types/place";
import {TimelineShift} from "../../types/timeline";
import {prepareTimelineValues, TimeLineShiftValue} from "../../components/hall-scheme/redux/ShiftsTimeline/utils";
import dayjs from "dayjs";
import {useTimelineActions} from "../Timeline";
import {TNullable} from "../../types/commons";
import {isNull, isUndefined} from "lodash";

export type HallScheduleQResponse = {
  minTime: string,
  maxTime: string,
  workStartHour: number,
  workDurationInHour: number,
  bookingInterval: string
  workDurationInMinutes: number
  bookingIntervalInMinutes: number;
}

export type SwapRequest = {
  force?: boolean;
  origin: SwapTarget,
  destination: SwapTarget,
}

export type MoveRequest = {
  force?: boolean;
  origin: SwapTarget,
  destination: {
    booking_id?: number,
    tables_id: number[],
  },
}

export type SlotEvent = {
  /** @deprecated лучше использовать время */
  startShift: number,
  /** @deprecated лучше использовать время */
  endShift: number,
  bookingStartTime: string,
  booking: Booking,
  slotStatus: string,
  isLocked?: boolean,
  depositStatus?: boolean,
  hasComment?: boolean,
  isVIP?: boolean,
}

type TableSchema = {
  width: number,
  height: number,
  x: number,
  y: number,
  shape: "square" | "round"
}

export type Table = {
  table_id: number,
  number: number | string,
  type: number,
  schema: TableSchema
  placeId: number
}

export type HallSlotsQResponse = {
  table: Table,
  slots: SlotEvent[],
}

export interface PlacementFilter {
  date: string;
  restaurant_id: number;
  places_filter: number[];
  status_filter: number[];
  seat_type: SeatType[];
  start_time_filter?: string;
  end_time_filter?: string;
  use_visit_time?: boolean;
}

export type PlacementStats = {
  [key: string]: {
    bookings: number;
    guests: number;
  };
} & { time: string; }

export type HallSchemeShiftsPayload = {
  restaurant_id: number,
  place_id: number,
  date: string
}

export type HallSchemeShiftsResponse = {
  timestamp: Date,
  data: TimelineShift[],
}


const SLOT_TAGS = [{type: "Slots", id: "hallSlots"} as const];

export const hallSchemaApi = api.enhanceEndpoints({
  addTagTypes: ["Slots", "TableOptions"],
}).injectEndpoints({
  endpoints: (build) => ({
    fetchHallSchedule: build.query<HallScheduleQResponse, { placeId: number, weekDay: number }>({
      query: ({placeId, weekDay}) => ({
        url: `/hall-schema/schedule/${placeId}`,
        params: {
          weekDay,
        },
      }),
      transformResponse: (response: IResponse<HallScheduleQResponse>) => response.data,
    }),
    fetchHallShiftsSchedule: build.query<TimelineShift[], HallSchemeShiftsPayload>({
      query: (params) => ({
        url: "/v2/hall-schema/schedule",
        params,
      }),
      providesTags: ['Timeline'],
      keepUnusedDataFor: 60,
      transformResponse: (response: HallSchemeShiftsResponse) => response.data
    }),
    fetchSlots: build.query<HallSlotsQResponse[], { placeId: number, date: string, time: string }>({
      query: ({placeId, date, time}) => ({
        url: `/hall-schema/slots/${placeId}`,
        params: {
          date,
          time,
        },
      }),
      transformResponse: (response: IResponse<HallSlotsQResponse[]>) => response.data,
      providesTags: () => SLOT_TAGS,
    }),
    getBookingPlacement: build.query<PlacementStats[], PlacementFilter>({
      query: (filter) => ({
        url: "v2/hall/booking/placement",
        method: "POST",
        body: filter,
      }),
      keepUnusedDataFor: 0,
      transformResponse: (response: IResponse<PlacementStats[]>) => response.data,
      async onQueryStarted(id, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (err) {
          if (err?.error?.data?.errorCode) {
            Notification.error({
              title: err?.error?.data?.errorMessage,
            });
          }
          throw err;
        }
      },
    }),
    swap: build.mutation<void, SwapRequest>({
      query: ({force, ...body}: SwapRequest) => ({
        url: "/v2/booking/swap",
        method: "POST",
        body,
        params: {
          force,
        },
      }),
      invalidatesTags: [...SLOT_TAGS, "TableOptions"],
      async onQueryStarted(id, {queryFulfilled, dispatch}) {
        try {
          await queryFulfilled;
          dispatch(bookingApi.util.invalidateTags(["Bookings"]));
        } catch (e) {
          let message;
          switch (e?.error?.data?.errorCode) {
            case 10100:
              break;
            case 10000: {
              message = e?.error?.data?.errorMessage;
              break;
            }
            default:
              message = "Не удалось поменять брони местами";
          }
          message && Notification.error({
            title: message,
          });
          throw e;
        }
      },
    }),
    move: build.mutation<void, MoveRequest>({
      query: ({force, ...body}: MoveRequest) => {
        delete body.destination.booking_id;
        return {
          url: "/v2/booking/transfer",
          method: "POST",
          body,
          params: {
            force,
          },
        };
      },
      invalidatesTags: [...SLOT_TAGS, "TableOptions"],
      async onQueryStarted(id, {queryFulfilled, dispatch}) {
        try {
          await queryFulfilled;
          dispatch(bookingApi.util.invalidateTags(["Bookings"]));
        } catch (e) {
          let message;
          switch (e?.error?.data?.errorCode) {
            case 10100:
              break;
            case 10000: {
              message = e?.error?.data?.errorMessage;
              break;
            }
            default:
              message = "Не удалось перенести бронь";
          }
          message && Notification.error({
            title: message,
          });
          throw e;
        }
      },
    }),
  }),
});

export const {useSwapMutation, useMoveMutation, useGetBookingPlacementQuery} = hallSchemaApi;
const {useFetchHallScheduleQuery, useFetchHallShiftsScheduleQuery} = hallSchemaApi;


export function useSlots(interval?: TNullable<number>) {
  const place = useSelector(appContextSelectors.place);
  const date = useSelector(appContextSelectors.date);
  const pollingInterval = (isUndefined(interval) || isNull(interval)) ? middlePollingInterval : interval;
  return hallSchemaApi.useFetchSlotsQuery({
    placeId: place,
    date: date.format("YYYY-MM-DD"),
    time: moment().format("HH:mm"),
  }, {pollingInterval, refetchOnMountOrArgChange: true});
}


export function useCurrentHallSchedule() {
  const placeId = useSelector(appContextSelectors.place);
  const date = useSelector(appContextSelectors.date);
  const weekDay = useMemo(() => date.isoWeekday(), [date]);
  // request to build timeline
  return useFetchHallScheduleQuery({placeId, weekDay});
}

export function useCurrentHallShiftsSchedule() {
  const place_id = useSelector(appContextSelectors.place);
  const date = useSelector(appContextSelectors.date);
  const restaurant = useSelector(appContextSelectors.restaurant);

  const params: HallSchemeShiftsPayload = {
    restaurant_id: restaurant.restaurant_id,
    date: date.format("YYYY-MM-DD"),
    place_id,
  };

  return useFetchHallShiftsScheduleQuery(params);

}

export function usePrepareShiftsSchedule(){
  const {data : intervals} = useCurrentHallShiftsSchedule();
  const {setTimelineConfig} = useTimelineActions();

  useEffect(() => {
    //Вычисляем время работы ресторана на основе шифтов
    if(!intervals) return

    const firstValue = intervals[0]?.values
    const lastValue = intervals[intervals.length - 1]?.values

    if(!firstValue || !lastValue) return;

    const start = firstValue[0];
    const end = lastValue[lastValue.length - 1];
    const startShift = dayjs(start);
    const minutesShift = startShift.minute() / 60;
    const hoursShift = startShift.hour() + 1;
    const timeStart = hoursShift + minutesShift;
    const timelineLengthHours = dayjs(end).diff(startShift, 'h', true);
    setTimelineConfig({timeStart, timelineLengthHours});
  }, [intervals]);
}

export /**
 * @deprecated инвалидация должна происходить на уровне api
 */
const invalidateHallSlots = () => hallSchemaApi.util.invalidateTags(SLOT_TAGS);
