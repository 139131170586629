import React, {memo, MouseEventHandler, useCallback, useMemo, useRef, useState} from "react";
import styles from "../../MoveStatus.module.scss";
import {useSelector} from "react-redux";
import {moveBookingSelectors, useMoveBookingActions} from "features/MoveBooking";
import {SwapRequest, useMoveMutation, useSwapMutation} from "features/api/hallschema-api";
import {ConfirmOverbookingModal} from "components/modals/ConfirmOverbookingModal";
import {useBooleanState} from "hooks/useBooleanState";
import cn from "classnames";
import {useTablesBookings} from "../TableBookingList/useTableBookings";
import {Booking} from "../../../../types/booking";
import {BookingCardDetailList} from "../TableBookingList/BookingCardDetailList";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {Modal} from "../../../modal";
import {Button} from "ui-kit";
import {useBoolean} from "react-use";
import {HallMode, useHallSchemaActions} from "features/HallSchema";
import {useIntlUtils} from "../../../../hooks/useIntlUtils";
import {ETranslations} from "../../../../types/translates";
import {useTimelineActions} from '../../../../features/Timeline';

const Container = (props: any) => <div className={styles.moveStatus} {...props} />;


const MoveControls: React.FC<any> = () => {
  const {intl, getIntlSelectEntity} = useIntlUtils();
  const bookingSelected = useSelector(moveBookingSelectors.isSourceSelected);
  const {clearSelection} = useMoveBookingActions();
  const {switchMode} = useHallSchemaActions();
  const moveOrSwapData = useSelector(moveBookingSelectors.moveData);
  const sourceTableNumbers = useSelector(moveBookingSelectors.sourceTableNumber);
  const targetTablesNumbers = useSelector(moveBookingSelectors.targetTablesNumbers);
  const [move] = useMoveMutation();
  const [swap] = useSwapMutation();
  const [isForce, setForce] = useState<true | undefined>();
  const [isProcessing, start, stop] = useBooleanState();
  const tableId = moveOrSwapData?.destination.tables_id[0];
  const {resetTimeShift} = useTimelineActions()

  const {data} = useTablesBookings(tableId, !tableId, '', true);
  const isHasMultipleBookings = useMemo(() => (data?.bookings?.length ?? 0) > 1, [data]);
  const [pickBooking, setPickTrue, setPickFalse] = useBooleanState();
  const [isSwapping, setIsSwapping] = useBoolean(false);
  const canSwap = moveOrSwapData?.destination.booking_id && targetTablesNumbers.length === 1;

  const swapPayload = useRef<SwapRequest | null>(null);

  const resetForce = useCallback(() => {
    setForce(undefined);
    setIsSwapping(false);
  }, [setForce]);

  const cancel = useCallback(() => {
    resetTimeShift()
    clearSelection();
    switchMode(HallMode.TABLE_BOOKINGS_LIST);
  }, []);

  const processSwap = useCallback(async (payload: SwapRequest) => {
    swapPayload.current = payload;
    start();
    setIsSwapping(true);
    try {
      await swap(payload).unwrap();
      cancel();
      resetForce();
    } catch (e) {
      if (!isForce && e?.data?.errorCode === 10100) {
        setForce(true);
      }
      if (isForce && e?.data?.errorCode === 10_000) {
        resetForce();
        clearSelection();
      }
    } finally {
      setPickFalse();
      stop();
    }
  }, [isForce, resetForce, swap]);

  const retrySwap = useCallback(() => {
    const {current: payload} = swapPayload;
    if (!payload) return undefined;
    return processSwap({...payload, force: true});
  }, []);

  const process = useCallback(async () => {
    start();
    setIsSwapping(false);
    try {
      if (!moveOrSwapData) return;
      await move({...moveOrSwapData, force: isForce}).unwrap();
      cancel();
      resetForce();
      } catch (e) {
      if (!isForce && e?.data?.errorCode === 10100) {
        setForce(true);
      }
      if (isForce && e?.data?.errorCode === 10_000) {
        resetForce();
        clearSelection();
      }
    } finally {
      stop();
    }
  }, [move, moveOrSwapData, isForce, resetForce]);

  const pickExactBooking = useCallback(async (booking: Booking) => {

    if (moveOrSwapData) {
      const payload = {
        origin: {
          booking_id: moveOrSwapData.origin.booking_id,
        },
        destination: {
          booking_id: booking.bookingId,
        },
        force: isForce,
      };
      await processSwap(payload);

    }
  }, [moveOrSwapData, isForce]);

  const handlePick: MouseEventHandler = useCallback((e) => {
    if (!moveOrSwapData) return;
    if (isHasMultipleBookings) {
      e.stopPropagation();
      setPickTrue();
      return;
    }
    const payload = {
      origin: {
        booking_id: moveOrSwapData.origin.booking_id,
      },
      destination: {
        booking_id: moveOrSwapData.destination.booking_id!,
      },
      force: isForce,
    };

    processSwap(payload);

  }, [isHasMultipleBookings, isForce, moveOrSwapData]);

  const tablesNumbers = useMemo(() => {
    if (!sourceTableNumbers.length || !targetTablesNumbers.length) return '';
    const source = sourceTableNumbers.join(', ');
    const target = targetTablesNumbers.join(', ');

    return ` ${source} → ${target}`;

  }, [sourceTableNumbers, targetTablesNumbers]);

  return (
    <>
    <Container style={{display: bookingSelected ? 'flex' : 'none'}}>
      {canSwap && (
        <Button
          variant="primary"
          type="button"
          disabled={isProcessing}
          onClick={handlePick}
        >
          {intl.formatMessage({id: ETranslations.SEATS_SWAP})}
          {tablesNumbers}
        </Button>
      )}
      <Button
        variant="primary"
        type="button"
        disabled={!moveOrSwapData || isProcessing}
        onClick={process}
      >
        {intl.formatMessage({id: ETranslations.DOUBLE_BOOKING})}
        {tablesNumbers}
      </Button>
      {bookingSelected && (
        <button
          className={cn('secondary', styles.cancelButton)}
          type="button"
          onClick={cancel}
        >
          {intl.formatMessage({id: ETranslations.BASE_CANCEL})}
        </button>
      )}

    </Container>
    <ConfirmOverbookingModal onDecline={resetForce} isOpen={isForce} onConfirm={isSwapping ? retrySwap : process} disabled={isProcessing} />
    <Modal onClose={setPickFalse} title={getIntlSelectEntity(ETranslations.PLURAL_BOOKING)} isOpen={pickBooking}>
      <Modal.Content>
        <BookingCardDetailList data={data?.bookings || []} onClick={pickExactBooking} />
      </Modal.Content>
    </Modal>
    </>
  );
};

export const HallMoveStatusControls = memo(MoveControls);
