import React, {FC} from 'react';
import {SvgForeignObject, SvgForeignObjectBody} from './SvgForeignObject';
import {HallSlotsQResponse} from '../../features/api/hallschema-api';
import {SCHEME_FACTOR} from '../hall-scheme/redux/HallSchemaV2/hall-schema';
import cn from 'classnames';
import {getSlotExtraOptions, selectSlotByShift} from './utils';
import {useSliderDatetime} from '../../hooks/useSliderDatetime';

export const TableGroup: FC<HallSlotsQResponse> = ({table, slots}) => {
  const timeWithDate = useSliderDatetime();
  const slot = selectSlotByShift(slots, timeWithDate());
  const {tableColor, slot: {booking} = {}} = getSlotExtraOptions(slot, timeWithDate());
  const {schema: {x, y, width, height, shape}} = table;
  const isShow = booking && booking.places.length > 1;

  if (!isShow) return null;

  return (
      <SvgForeignObject
        x={SCHEME_FACTOR * x - (SCHEME_FACTOR / 2)}
        y={SCHEME_FACTOR * y - (SCHEME_FACTOR / 2)}
        width={SCHEME_FACTOR * width + SCHEME_FACTOR}
        height={SCHEME_FACTOR * height + SCHEME_FACTOR}
        className={cn('table', shape)}
      >
        <SvgForeignObjectBody>
          <div
            className={cn('table-border', tableColor, shape)}
            style={{
              width: SCHEME_FACTOR * width + SCHEME_FACTOR,
              height: SCHEME_FACTOR * height + SCHEME_FACTOR,
              borderRadius: shape === 'round' ? '50%' : '8px',
            }}
          />
        </SvgForeignObjectBody>
      </SvgForeignObject>
  );
};
