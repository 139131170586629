import React from 'react';
import {Table} from '../../features/api/hallschema-api';
import {ICON_SIZE} from './utils';
import {SCHEME_FACTOR} from '../hall-scheme/redux/HallSchemaV2/hall-schema';
import {SvgForeignObject} from './SvgForeignObject';
import {OverbookedLabel} from './OverbookedLabel';

export const TableOverbooked: React.FC<Table> = ({schema}) => (
  <SvgForeignObject
    x={(SCHEME_FACTOR * (schema.x + schema.width / 2)) - (ICON_SIZE + 20) / 2}
    y={(SCHEME_FACTOR * schema.y) - (ICON_SIZE + 20) / 2}
    width={ICON_SIZE + 20}
    height={ICON_SIZE + 20}
  >
    <OverbookedLabel />
  </SvgForeignObject>
);
