import {FunctionComponent, useCallback, useState} from "react";
import {SearchBlock} from "./SearchBlock";
import {BookingStatus} from "types/booking";
import {BookingClientHistoryList} from "./BookingClientHistoryList";
import moment from "moment";
import {OrderClientHistoryList} from "./OrderClientHistoryList";
import GuestBadge from '../GuestContent/GuestBadge/GuestBadge';
import {useFetchClientQuery} from '../../features/api/guest-api';


interface Props {
  clientId: number;
}

export interface IPayloadClientHistory {
  from: Date | string,
  to: Date | string,
  statuses?: BookingStatus[],
  count: number,
  offset: number,
}

export const bookingStatuses = [
  BookingStatus.NEW,
  BookingStatus.CLOSED,
  BookingStatus.IN_HALL,
  BookingStatus.CANCELED,
  BookingStatus.CONFIRMED,
  BookingStatus.EXTERNAL,
  BookingStatus.NOT_COME,
  BookingStatus.WAIT_LIST,
];

// const orderStatutes = [OrderStatus.OPEN, OrderStatus.CREATED, OrderStatus.REJECTED];

export type TModeHistory = 'order' | 'booking'


const initialPayload = {
  from: moment().format('YYYY-MM-DD'),
  to: moment().format('YYYY-MM-DD'),
  statuses: bookingStatuses,
  count: 10,
  offset: 0,
};

export const BookingClientHistory: FunctionComponent<Props> = ({clientId}) => {
  const [payload, setPayload] = useState<IPayloadClientHistory>(initialPayload);
  const [mode, setMode] = useState<TModeHistory>('booking');
  const {data : client} = useFetchClientQuery(clientId)

  if (!clientId) return null;

  const paginationHandler = useCallback((offset:number) => {
    setPayload((oldState) => ({...oldState, offset: (offset * oldState.count) - oldState.count}));
  }, []);



  return (
    <section>
      <GuestBadge guest={client} isHideEditPhoto/>
      <SearchBlock mode={mode} payload={payload} setPayload={setPayload} setMode={setMode}/>
      {
        mode === 'booking'
          ? (<BookingClientHistoryList mode={mode} payload={payload} clientId={clientId} paginationHandler={paginationHandler} />)
          : (<OrderClientHistoryList mode={mode} clientId={clientId} payload={payload} paginationHandler={paginationHandler} />)
      }
    </section>
  );
};
