import React from 'react';
import {Table} from '../../features/api/hallschema-api';
import {ICON_SIZE} from './utils';
import {ICONS} from '../../common/helpers';
import {SCHEME_FACTOR} from '../hall-scheme/redux/HallSchemaV2/hall-schema';
import {SvgForeignObject} from './SvgForeignObject';

import style from './TableIconLock.module.scss';

export const TableIconLock: React.FC<Table & { position: 'left' | 'center'}> = ({schema, position}) => (
  <SvgForeignObject
    className={style.lock}
    x={(SCHEME_FACTOR * (schema.x + schema.width / (position === 'center' ? 2 : 1))) - ICON_SIZE / 2.5}
    y={(SCHEME_FACTOR * (schema.y + schema.height)) - ICON_SIZE / 2.5}
    width={ICON_SIZE}
    height={ICON_SIZE}
  >
    <ICONS.LockIcon className="lock" />
  </SvgForeignObject>
);
