import React, {Dispatch, FC, SetStateAction, useMemo, useState} from 'react';
import {Card, DatePicker, Input, Labeled, SelectBasic, Textarea} from 'ui-kit';
import styles from "./GuestForm.module.scss";
import {ETranslations} from "types/translates";
import {Field, Form} from "react-final-form";
import {Client} from "models/client.model";
import {ChoiceDateInput} from "../../registration/sub-renders/date-time";
import {useIntlUtils} from "hooks/useIntlUtils";
import Checkbox from "common/components/checkbox";
import {UserTags} from "../../UserTags";
import {useGuestListActions} from "features/GuestsList";
import useGuestForm from "./useForm";
import {buildDictionaryOptions, getDictionaryOptionById} from "./utils";
import Control, {TButtons} from "../Control/Control";
import GuestBadge from "../GuestBadge/GuestBadge";
import {TView} from 'containers/Guests/GuestCard';
import {required} from "../../shifts/utils";
import {TNullable} from "types/commons";
import {toBase64} from "utils";
import {HideWhen} from "../../HideWhen";
import {config} from "config";
import {phoneValidInput} from '../../registration/validators/validators';
import {emptyStringIfNull, getMaskPhone} from '../../../common/helpers';

interface TProps {
    guest?: Client;
    view: TView;
    buttons: TButtons[];
    setView: Dispatch<SetStateAction<TView>>;
}

const GuestForm: FC<TProps> = ({guest, view, buttons, setView}) => {
    const {intl, getIntlDateOf, getIntlChooseEntity, isRussianLocale} = useIntlUtils()
    const {setSelectedClient} = useGuestListActions()
    const {salutationDictionary, genderDictionary, updateGuest, createGuest, getClient, isFormLoading} = useGuestForm()

    const [selectedFile, setSelectedFile] = useState<TNullable<File>>(null);

    const init = useMemo(() => {
        if (guest) {
            return {
                ...guest,
                salutation: guest.salutation?.id && salutationDictionary
                    && getDictionaryOptionById(salutationDictionary, guest.salutation.id, isRussianLocale),
                birthday: guest.birthday && new Date(guest.birthday),
                sex: guest.sex && genderDictionary && getDictionaryOptionById(genderDictionary, guest.sex?.id, isRussianLocale),
                vip: !!guest.vip,
                phone: getMaskPhone(emptyStringIfNull(guest.phone)),
                work_phone: getMaskPhone(emptyStringIfNull(guest.work_phone))
            }
        } else {
            return {}
        }

    }, [guest, salutationDictionary, genderDictionary])

    const onSubmit = async (formData: any) => {

        let clientPhoto
        if (selectedFile) {
            clientPhoto = await toBase64(selectedFile)
        }

        const data = {
            ...formData,
            salutation_id: formData.salutation?.value,
            sex_id: formData.sex?.value,
            client_photo: clientPhoto || formData?.client_photo
        }

        delete data.sex
        delete data.salutation

        if (view === 'create') {
            const newClient = await createGuest(data)
            //@ts-ignore
            const clientId = newClient?.data?.data.client_id
            if (clientId) {
                const updatedClient = await getClient(clientId)
                setSelectedClient(updatedClient.data)
            }
        }

        if (view === 'edit') {
            const response = await updateGuest(data)
            //@ts-ignore
            if(response?.data?.status === "ERROR") return
            if (guest?.client_id) {
                await getClient(guest.client_id)
            }
        }

        setView('info');
    }

    return (
        <Form initialValues={init} onSubmit={onSubmit}>
            {({handleSubmit}) => (
                <Card onClose={close}>
                    <Card.Content noPadding>
                        <form id="guest-form" onSubmit={handleSubmit}>
                            <GuestBadge guest={guest} setSelectedFile={setSelectedFile}>
                                <Control buttons={buttons} isLoading={isFormLoading}/>
                            </GuestBadge>
                            <div className={styles.dataBlock}>
                                <HideWhen condition={!config.vipSign}>
                                    <div className={styles.row}>
                                        <Field
                                          name="salutation"
                                          required
                                          validate={required}
                                        >
                                            {({input}) => (
                                                <Labeled label={intl.formatMessage({id: ETranslations.SALUTATION})}
                                                         required>
                                                    <SelectBasic
                                                        {...input}
                                                        options={buildDictionaryOptions(salutationDictionary || [], isRussianLocale)}
                                                        changedMaxSize
                                                        isDisabled={input.disable}
                                                        onChange={input.onChange}
                                                        isValid={input.value}
                                                        placeholder={intl.formatMessage({id: ETranslations.SALUTATION})}
                                                    />
                                                </Labeled>
                                            )}
                                        </Field>
                                    </div>
                                </HideWhen>

                                <div className={styles.row}>
                                    <Labeled label={intl.formatMessage({id: ETranslations.LAST_NAME})} required>
                                        <Field name="surname" required validate={required}>
                                            {({input, meta}) => <Input {...input} {...meta}  autoComplete="off"/>}
                                        </Field>
                                    </Labeled>
                                    <Labeled label={intl.formatMessage({id: ETranslations.MIDDLE_NAME})}>
                                        <Field name="middle_name">
                                            {({input, meta}) => <Input {...input} {...meta}  autoComplete="off"/>}
                                        </Field>
                                    </Labeled>
                                    <Labeled label={intl.formatMessage({id: ETranslations.FIRST_NAME})} required>
                                        <Field name="name" required validate={required}>
                                            {({input, meta}) => <Input {...input} {...meta} autoComplete="off"/>}
                                        </Field>
                                    </Labeled>
                                </div>

                                <div className={styles.row}>
                                    <Labeled label={getIntlDateOf(ETranslations.OF_BIRTH)}
                                             as={'div'}>
                                        <Field name="birthday">
                                            {(props) => <DatePicker
                                              minYear={1920}
                                              onChange={props.input.onChange}
                                              selected={props.input.value}
                                            />}
                                        </Field>
                                    </Labeled>
                                    <Field name="sex">
                                        {({input}) => (
                                            <Labeled label={intl.formatMessage({id: ETranslations.GENDER})}>
                                                <SelectBasic
                                                    {...input}
                                                    options={buildDictionaryOptions(genderDictionary || [], isRussianLocale)}
                                                    changedMaxSize
                                                    isDisabled={input.disable}
                                                    onChange={input.onChange}
                                                    placeholder={intl.formatMessage({id: ETranslations.GENDER})}
                                                />
                                            </Labeled>
                                        )}
                                    </Field>
                                    <HideWhen condition={!config.vipSign}>
                                        <Field name="vip" type="checkbox">
                                            {({input}) => (
                                                <Checkbox
                                                    indent="confirmed"
                                                    text={intl.formatMessage({id: ETranslations.VIP})}
                                                    onChange={input.onChange}
                                                    value={input.checked}
                                                    useStopPropagation
                                                    className={styles.checkbox}
                                                />
                                            )}
                                        </Field>
                                    </HideWhen>
                                </div>

                                <div className={styles.row}>
                                    <Field
                                      name="phone"
                                      required
                                      validate={(value) => phoneValidInput(value)}
                                      autocomplete="off"
                                    >
                                        {({input, meta}) => <Input.Phone
                                            {...meta}
                                            value={input.value}
                                            onChange={input.onChange}
                                            label={intl.formatMessage({id: ETranslations.PHONE})}
                                            required
                                        />}
                                    </Field>
                                    <Field
                                      name="work_phone"
                                      validate={(value) => value && phoneValidInput(value)}
                                    >
                                        {({input, meta}) => (
                                          <Input.Phone
                                            value={input.value}
                                            onChange={input.onChange}
                                            label={intl.formatMessage({id: ETranslations.WORK_PHONE})}
                                            {...meta}
                                          />
                                        )}
                                    </Field>
                                </div>

                                <div className={styles.row}>
                                    <Field name="email">
                                        {({input}) =>
                                            <Input
                                                label={intl.formatMessage({id: ETranslations.EMAIL})}
                                                type="email" size={1}
                                                placeholder="@"
                                                value={input.value || ''}
                                                onChange={input.onChange}
                                            />
                                        }
                                    </Field>
                                    <Field name="alt_email">
                                        {({input}) =>
                                            <Input
                                                label={intl.formatMessage({id: ETranslations.ALT_EMAIL})}
                                                type="email" size={1}
                                                placeholder="@"
                                                value={input.value || ''}
                                                onChange={input.onChange}
                                            />
                                        }
                                    </Field>
                                </div>

                                <div className={styles.item}>
                                    <Field name="comment">
                                        {({input}) =>
                                            <Textarea
                                                label={intl.formatMessage({id: ETranslations.GUEST_NOTE})}
                                                maxLength={150}
                                                value={input.value}
                                                onChange={input.onChange}
                                            />
                                        }
                                    </Field>
                                </div>

                                <Field name="tags">
                                    {({input}) =>
                                        <Labeled label={intl.formatMessage({id: ETranslations.PLURAL_TAGS_NOM})}>
                                            <UserTags
                                                value={input.value}
                                                onChange={input.onChange}
                                                placeholder={getIntlChooseEntity(ETranslations.PLURAL_TAGS_NOM)}
                                            />
                                        </Labeled>
                                    }
                                </Field>
                            </div>
                        </form>
                    </Card.Content>
                </Card>
            )}
        </Form>
    );
};

export default GuestForm;
