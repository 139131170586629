import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import cn from "classnames";
import {ICONS} from "common/helpers";
import {FieldRenderProps, useField} from "react-final-form";
import {config} from "../../../config";
import {HideWhen} from "../../HideWhen";
import {Client as OldClient} from "../../../models/client.model";
import {isRealClient} from "../validators/validators";
import {isClientEditing, selectedGuestSelector} from '../../../features/BookingFormProxy/selectors';
import {useSelector} from "react-redux";
import {Client} from "types/client";

import {useIntlUtils} from "../../../hooks/useIntlUtils";
import {ETranslations} from "../../../types/translates";
import {useFromProxyActions} from "features/BookingFormProxy";
import {useUnmount} from "react-use";
import {Button, Input, Labeled} from 'ui-kit';
import styles from "./Client.module.scss";
import commonStyles from "./style.module.scss";
import {ClientInfoPopup} from '../../BookingInfoPopup';
import {useLocation} from 'react-router';

const ClientInfoRedux = ({value, onChange, invalid, disabled, canEditNames = false, valid, isCreateBooking}: any) => {
  const {intl, getIntlFullNameOfEntity, isRussianLocale, getIntlJoinedParts} = useIntlUtils();
  const clientFull: Client | OldClient | null = useSelector(selectedGuestSelector) || value;
  const {meta: {invalid: phoneInvalid, error}, input: {value: phone}} = useField('phone');
  const [inputValue, setInputValue] = useState('');
  const editClient = useSelector(isClientEditing);
  const {setEditClient} = useFromProxyActions();
  const goesEditClient = useCallback(() => { setEditClient(true); }, [setEditClient]);
  const {input: {value : clientId}} = useField('client_id')
  const location = useLocation();

  const isGlobalSearch = location.pathname.includes('search');


  useUnmount(() => {
    setEditClient(false);
  });


  const mapClientNames = (values: string[]) => {
    if (values.length === 1) {
      return {name: values[0], surname: '', middle_name: ''};
    }
    return {name: values[1] || '', surname: values[0] || '', middle_name: values[2] || ''};
  };
  const parseFioValues = (nextValue: string): string[] => {
    const regExp = /^[Ëёа-яa-z.]*$/i;

    return nextValue.replace(/(\s\s?)+/, ' ').split(' ')
      .filter((it) => it && regExp.test(it));
  };
  const parseClientNames = (clientValue: OldClient | Client): string[] => [
    clientValue?.surname,
    clientValue?.name,
    (clientValue as OldClient)?.middle_name,
  ].filter(Boolean);

  const hide = !!error || !phone;

  // эффект управляет ручным вводом данных
  useEffect(() => {
    const fioValues = parseFioValues(inputValue);
    const mergedClientObj = {
      ...value,
      ...mapClientNames(fioValues),
      editClient,
    };
    onChange(mergedClientObj);
  }, [inputValue]);

  // эффект управляет автозаполнение через контекст
  useEffect(() => {
    onChange(clientFull);
    if (isRealClient(clientFull)) {
      // only when client set and not edit mode
      if (clientFull && !editClient) {
        setInputValue(parseClientNames(clientFull).join(' '));
      }
    }
  }, [clientFull?.client_id]);

  // эффект управляет очищением inputValue если валид и стейт не пустой
  useEffect(() => {
    if (!phoneInvalid && !clientFull) {
      setInputValue('');
    }
  }, [phoneInvalid, clientFull]);

  // эффект отключает редактирование поля юзера при переключение карточек бронирования. И устанавливает новое имя в стейт.
  useEffect(() => {
    onChange(clientFull);
      setEditClient(false);
      if (clientFull) {
        setInputValue(parseClientNames(clientFull).join(' '));
      }
  }, [clientFull?.client_id]);

  const isDisable = disabled || !!clientFull?.client_id || false;
  const fullRow = !config.clientsHasEmail;

  const handleClientName = ({target: {value: nextValue}}: ChangeEvent<HTMLInputElement>) => {
    setInputValue(nextValue);
  };

  const handleEmailChange = ({target: {value: nextValue}}: ChangeEvent<HTMLInputElement>) => {
    onChange({...value, email: nextValue});
  };

  const getEmail = () => {
    if (clientFull && clientFull.email) return clientFull.email;
    return '';
  };

  return (
    <>
      <HideWhen condition={hide} noUnmount>
        <div
          className={cn('book titled-block required', {hasValue: !invalid})}
          style={{gridColumn: fullRow ? 'span 2' : undefined}}
        >
            <div className={commonStyles.guestInfo}>
              <Labeled label={getIntlJoinedParts([ETranslations.PLURAL_BOOKING, ETranslations.IN_THE_NAME])}>
                <Input
                  value={inputValue}
                  onChange={handleClientName}
                  size={1}
                  maxLength={160}
                  disabled={!editClient && isDisable}
                  placeholder={getIntlFullNameOfEntity(isRussianLocale ? ETranslations.PLURAL_GUESTS_ALT : ETranslations.PLURAL_GUEST)}
                  invalid={!valid}
                  inputClassName={commonStyles.greatBoldText}
                  suffix={(canEditNames && isRealClient(clientFull) && !editClient) && (
                    <button className={cn(styles.edit)} type="button" onClick={goesEditClient}>
                      <ICONS.Edit />
                    </button>
                  )}
                />
              </Labeled>
              {(isCreateBooking || isGlobalSearch) && clientId && (
                <ClientInfoPopup clientId={clientId} placement="right-start">
                  <Button
                    variant="phantom"
                    type="button"
                    className={commonStyles.clientIcon}
                  >
                    <ICONS.Question />
                  </Button>
                </ClientInfoPopup>
              )}
            </div>
        </div>
      </HideWhen>
      <HideWhen condition={hide || fullRow}>
        <div className="email titled-block add">
            <Labeled label={intl.formatMessage({id: ETranslations.EMAIL})}>
              <Input
                type="email"
                size={1}
                placeholder="@"
                value={getEmail()}
                disabled={isDisable}
                onChange={handleEmailChange}
                inputClassName={commonStyles.greatBoldText}
              />
            </Labeled>
        </div>
      </HideWhen>
    </>
  );
};

export const ChoiceClientInfoRedux: React.FC<FieldRenderProps<any>> = ({input, meta, ...rest}) => {
  return <ClientInfoRedux {...input} {...meta} {...rest} />;
};
