import {OptionAppointRole, OptionSettingsMode, SettingsModes} from "models/settings.model";
import {Booking} from "models/booking.model";
import {M, predata} from "common/helpers";
import {Role} from "services/auth.service";
import {AppointUser} from "models/user.model";
import {SelectModel} from "models/common";
import {ETranslations} from "../../types/translates";
import {config} from "config";

/* eslint-disable */
export const getOptionsSettingsMode = (): OptionSettingsMode[] => [
  {
    value: 6, label: ETranslations.SETTINGS_TITLE_SHIFTS, mode: SettingsModes.shifts,
    permissions: ['ADMINISTRATOR', 'ADMINISTRATOR_RESTAURANT'],
  },
  {
    value: 1, label: ETranslations.SETTINGS_TITLE_ROLES, mode: SettingsModes.appointRoles,
    permissions: ['ADMINISTRATOR'],
  },
  {
    value: 2, label: ETranslations.SETTINGS_TITLE_HOSTESS, mode: SettingsModes.appointHostess,
    permissions: ['ADMINISTRATOR', 'ADMINISTRATOR_RESTAURANT'],
  },
  // {value: 3, label: 'Источники', mode: SettingsModes.sources},
  // {value: 4, label: 'Заведение нового ресторана', mode: SettingsModes.newRest},
  {
    value: 5, label: ETranslations.SETTINGS_TITLE_BOOKING_HISTORY, mode: SettingsModes.changeBook,
    permissions: ['ADMINISTRATOR_RESTAURANT', 'ADMINISTRATOR', 'HOSTESS', 'RESTAURANT'],
  },
  config.HSE_ENABLED ? {
    value: 6, label: ETranslations.SETTINGS_TITLE_RESTAURANT_MANAGEMENT, mode: SettingsModes.restManagement,
    permissions: ['ADMINISTRATOR_RESTAURANT', 'ADMINISTRATOR'],
  } as OptionSettingsMode : undefined
].filter((v): v is OptionSettingsMode => !!v);

export const appointRolesList: OptionAppointRole[] = [
  {id: 1, label: ETranslations.SETTINGS_ROLE_SYSTEM_ADMIN, role: 'ADMINISTRATOR', declension: ETranslations.SETTINGS_ROLE_SYSTEM_ADMIN_DECL, data: []},
  {id: 2, label: ETranslations.SETTINGS_ROLE_RESTAURANT_ADMIN, role: 'ADMINISTRATOR_RESTAURANT', declension: ETranslations.SETTINGS_ROLE_RESTAURANT_ADMIN_DECL, data: []},
  {id: 3, label: ETranslations.SETTINGS_ROLE_HOSTESS, role: 'HOSTESS', declension: ETranslations.SETTINGS_ROLE_HOSTESS_DECL, data: []},
  {id: 4, label: ETranslations.SETTINGS_ROLE_CALL_CENTER, role: 'CALL_CENTER', declension: ETranslations.SETTINGS_ROLE_CALL_CENTER_DECL, data: []},
  {id: 5, label: ETranslations.SETTINGS_ROLE_RESTAURANTS, role: 'RESTAURANT', declension: ETranslations.SETTINGS_ROLE_RESTAURANTS_DECL, data: []},
];

export const optionsAppointRoles: SelectModel[] = [
  {value: 'ADMINISTRATOR', label: ETranslations.SETTINGS_ROLE_SYSTEM_ADMIN},
  {value: 'ADMINISTRATOR_RESTAURANT', label: ETranslations.SETTINGS_ROLE_RESTAURANT_ADMIN},
  {value: 'HOSTESS', label: ETranslations.SETTINGS_ROLE_HOSTESS},
  {value: 'CALL_CENTER', label: ETranslations.SETTINGS_ROLE_CALL_CENTER},
  {value: 'RESTAURANT', label: ETranslations.SETTINGS_ROLE_RESTAURANTS},
];
/* eslint-enable */

export const isBookingsDateEqual = (prevBook: Booking | undefined, {date}: Booking) => {
  if (!prevBook) {
    return false;
  }
  return M(prevBook.date).isSame(date, 'd');
};

export const getOptionAppointRole = (desiredRole?: Role): SelectModel | undefined => {
  const foundOption = optionsAppointRoles.find(({value}) => value === desiredRole);
  return foundOption;
};

export const getRestName = ({restaurant_id: id, role, name}: AppointUser) => {
  if (typeof id !== 'number' && role === 'ADMINISTRATOR') {
    return ETranslations.ALL_RESTAURANTS;
  }
  if (typeof id !== 'number') {
    return ETranslations.UNKNOWN_RESTAURANT;
  }
  if (role === 'RESTAURANT') {
    return name;
  }
  return predata.restsMapper[id]?.label;
};
