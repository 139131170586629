import {FC, useCallback, useState} from 'react';
import Select, {StylesConfig, Props, components as SelectComponents} from 'react-select';
import cn from 'classnames';
import styles from './Select.module.scss';
import {useIntl} from "react-intl";
import {ETranslations} from "../../types/translates";
import {useUnmount} from 'react-use';

export type OptionSelectType = {
  label: string;
  value: number | string;
};

const commonStyles: StylesConfig<OptionSelectType, false> = {
  control: (base, state) => {
    const {
      menuIsOpen,
      isDisabled,
      selectProps: {menuPlacement}
    } = state;
    const menuIsTop = menuPlacement === 'top';


    const borderColor = `var(--${menuIsOpen ? 'gl_dividerBorder_accent' : 'gl_dividerBorder_primary'})`;
    const boxShadow = `var(--${menuIsOpen ? 'shadow-lightest' : 'none'})`;
    const backgroundColor = `var(--${isDisabled ? 'select_disabled_background' : 'select_default_background_1'})`;
    const borderBottomColor = menuIsTop ? borderColor : 'var(--gl_dividerBorder_primary)';

    return ({
      ...base,
      border: `1px solid ${borderColor}`,
      boxShadow,
      borderRadius: 3,
      borderBottomColor,
      backgroundColor,
      minHeight: 31,
      boxSizing: 'border-box',

      ':hover': {
        border: `1px solid ${borderColor}`,
        boxShadow,
        borderBottomColor
      },
    });
  },
  placeholder: (base) => ({
    ...base,
    font: 'var(--font-12-r)',
    color: 'var(--gl_text_secondary_disabled)'
  }),
  singleValue: (base) => ({
    ...base,
    font: 'var(--font-12-r)',
    color: 'var(--gl_text_inverse)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none'
  }),
  menuList: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    borderTop: 'none',
    "::-webkit-scrollbar-thumb" : {
      backgroundColor: "var(--gl_text_secondary_disabled)"
    }
  }),
  menuPortal: (base) => ({
    ...base,
    border: 'none',
    zIndex: 1000,
  }),
  menu: (base, state) => {
    const {selectProps: {menuPlacement}} = state;
    const menuIsTop = menuPlacement === 'top';

    const marginTop = menuIsTop ? 0 : '-5px';
    const marginBottom = menuIsTop ? '-5px' : 0;
    const borderBottomColor = menuIsTop ? 'transparent' : 'var(--gl_dividerBorder_accent)';
    const borderTopColor = menuIsTop ? 'var(--gl_dividerBorder_accent)' : 'transparent';

    return ({
      ...base,
      marginTop,
      marginBottom,
      border: '1px solid var(--gl_dividerBorder_accent)',
      borderBottomColor,
      borderTopColor,
      boxShadow: 'none',
      backgroundColor: 'var(--select_default_background_1)',
      zIndex: 100,
    });

  },
  indicatorsContainer: (base) => ({
    ...base,
    position: 'relative',
    height: 28,
    color: 'var(--gl_icon_constant_secondary)',
    'div': {
      padding: 2,
      ':hover': {
        color: 'var(--gl_icon_constant_secondary)'
      }
    }
  }),
  valueContainer: (base) => ({
    ...base,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  option: (base, state) => ({
    ...base,
    font: 'var(--font-12-r)',
    color: 'var(--gl_text_inverse)',
    backgroundColor: state.isSelected ? 'var(--dtpkrListItem_active_background)' : 'var(--dtpkrListItem_default_background)',
    ':hover': {
      backgroundColor: 'var(--dtpkrListItem_hover_background)'
    }
  }),
  input: (base) => ({
    ...base,
    color: 'var(--gl_text_inverse)',
  }),
};

export interface SelectBasicProps<T> extends Props<T, boolean> {
  isValid?: boolean;
}

export const SelectBasic: FC<SelectBasicProps<OptionSelectType>> = (
  {
    className,
    styles: customStyles,
    components,
    isValid = true,
    options,
    ...props
  }
) => {
  const intl = useIntl();
  const intlOptions = options?.map(option => {
    if (typeof option.label === 'string' && option.label in ETranslations) {
      return {
        ...option,
        label: intl.formatMessage({id: option.label})
      }
    }
    return option
  })

  const Control = useCallback(({children, ...controlProps}) => {
    const {menuIsOpen} = controlProps;
    return (
      <SelectComponents.Control
        {...controlProps}
        className={cn(controlProps.className, !isValid && !menuIsOpen && styles.invalid)}
        children={children}
      />
    );
  }, [isValid])

  const [portal] = useState(() => {
    const el = document.createElement("div");
    if (props.name) {
      el.dataset.modal = props.name;
    }
    document.body.append(el);
    return el;
  })

  useUnmount(() => {
    try {
      document.body.removeChild(portal);
    } catch {
      //
    }
  })

  return (
    <div className={cn(styles.container, className)}>
      <Select
        // @ts-ignore
        styles={{
          ...commonStyles,
          ...customStyles
        }}
        menuPortalTarget={portal}
        components={{
          Control,
          ...components,
        }}
        menuShouldBlockScroll
        isSearchable={false}
        noOptionsMessage={() => intl.formatMessage({id: ETranslations.BASE_NO_VALUES})}
        options={intlOptions}
        {...props}
      />
    </div>
  );
};
