import React, {FC, useCallback} from 'react';
import styles from './ShortUserInfo.module.scss';
import {useFetchClientQuery} from '../../../features/api/guest-api';
import {Button, Card, ICONS, Spinner} from 'ui-kit';
import {useIntl} from 'react-intl';
import {ETranslations} from '../../../types/translates';
import GuestBadge from '../GuestBadge/GuestBadge';
import {CountRow} from './CountRow';
import {ShortInfo} from './ShortInfo';
import {useHistory} from 'react-router';
import {useGuestListActions} from '../../../features/GuestsList';

interface Props {
  userId: number;
}

export const ShortUserDetail: FC<Props> = ({userId}) => {
  const intl = useIntl();
  const {
    data: guest,
    isLoading
  } = useFetchClientQuery(userId);

  const history = useHistory();
  const {setSelectedClient} = useGuestListActions();

  const redirectToGuests = useCallback(() => {
    if (!guest) return;
    history.push("/guests");
    setSelectedClient(guest);
  }, [guest]);

  if (isLoading || !guest) return <div className={styles.info}> <Spinner/> </div>;

  return (
    <div className={styles.info}>
      <Card>
        <Card.Header title={intl.formatMessage({id: ETranslations.PROFILE})}/>
        <Card.Content noPadding>
          <div className={styles.detail} onClick={redirectToGuests}>
            <GuestBadge guest={guest} isShort isHideEditPhoto/>
            <Button variant='phantom' className={styles.button} >
              <ICONS.ArrowDown className={styles.icon}/>
            </Button>
          </div>
          <CountRow
            visits={guest.visit_count}
            canceled={guest.cancel_count}
            noShow={guest.not_come_count}
            avgSpend={guest.total_purchase}
          />
          <ShortInfo
            birthday={guest.birthday}
            email={guest.email}
            comment={guest.comment}
            gradeName={guest.grade_name}
          />
        </Card.Content>
      </Card>
    </div>
  );
};
