import { ReactNode } from "react";
import {ValueType} from "react-select";
import {BookingStatus} from 'types/booking';
import {TagServer} from "./booking.model";

export interface Filter {
  count: number;
  offset: number;
}

export type Param = string | boolean | number[] | string[] | {id: number, source_name: string} | TagServer[];

export interface ChangesParams {
  field: string;
  new: Param;
  old: Param;
}

export class SelectModel {
  constructor(
    public value: string | number,
    public label: string
  ) { }
}

export class TableSchema {
  constructor(
    public width: number,
    public height: number,
    public x: number,
    public y: number,
    public shape: 'square' | 'round'
  ) { }
}

/**
 * @deprecated используй простой объект
 */
export class Table {
  public id: number;
  constructor(
    public table_id: number,
    public number: number | string,
    public type: number,
    public schema?: TableSchema
  ) {this.id = table_id;}
}

export class TableRich {
  constructor(
    public table_id: number,
    public number: number,
    public type: number,
    public place_id: number,
    public scheme: object
  ) {
  }
}

export class TableSelect {
  constructor(
    public value: string | number,
    public label: string,
    public type: number
  ) {}
}



/**
 * @deprecated Нужно перейти на простые объекты и Option ниже
 */
export class SingleOption {
  constructor(
    public value: number,
    public label: string,
    public type?: number,
  ) {}

}

export interface Option<T = unknown> {
  value: T,
  label: string;
}

export type VisitTime = 'no-limit' | 'two-hours' | null;
export type DepositStatus = 'no-deposit' | 'deposit-made' | null;
export type SelectOption = ValueType<{value: number, label: string}, false>;
export type ProlongationTime = 'one-hour' | 'thirty' | number | null;
export type IsLoading = 'create' | 'wait-list';
export type ViewReg = 'reg' | 'create' | 'confirm' | 'select';
export type ViewGuest = 'view' | 'edit' | 'create';
export type PositionModal = 'root' | 'guest-card';

export type Nullable<T> = null | T;

export interface BookingSubmit {
  date: string,
  client_id: number,
  table_id: number[],
  tags: number[],
  visit_time: number,
  persons: number,
  deposit_status_default: boolean,
  deposit_status_reserv: boolean,
  deposit_sum: number,
  comment: string,
  user_id: number,
  source_id?: number | null,
  status: BookingStatus,
  id?: number,
  restaurant_id?: number,
  email?: string | null,
  phone?: string | null,
}

export interface ResponseWithData<T> {
  data: T
}

export interface IResponse<T> extends ResponseWithData<T> {
  error: { message: string } | null,
  status: 'OK' | 'ERROR',
}

export class Response<T> implements IResponse<T> {
  constructor(
    public data: T,
    public error: {message: string} | null,
    public status: 'OK' | 'ERROR'
  ) {}
}
