import React, {CSSProperties, useCallback, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {SingleOption} from "../../models/common";
import {Booking, Status, TUseBookingList} from '../../types/booking';
import {useBookingsList} from "../../features/api/bookings-api";
import {ICONS} from "../../common/helpers";
import cn from "classnames";
import {BookingStatus} from "types/booking";
import {useHistory} from "react-router";
import {Button, Card, Input, RadioButton, SelectCheckbox} from "ui-kit";
import {useLocalStorage, useUpdateEffect} from "react-use";

import {Link} from "react-router-dom";
import {BookingsList} from "components/BookingsList";

import {fromProxySelectors, useFromProxyActions} from "features/BookingFormProxy";

import styles from "./DashboardBookings.module.scss";
import {RestaurantBookingStats} from "components/RestaurantBookingStats";
import {useActiveStatuses, useCompleteStatuses} from "features/api/dictionaries-api";
import {useIntl} from "react-intl";
import {ETranslations} from "../../types/translates";
import {FILTERS_TITLES} from "../../constants";
import {ELocales} from '../../types/commons';
import {useTimelineActions} from '../../features/Timeline';
import {getBookingStartTime} from '../../utils';
import {useApplicationContextActions} from '../../features/AppContex';
import {HallMode, useHallSchemaActions} from '../../features/HallSchema';

type BookingModeType = "active" | "complete";

interface BookingProps {
  style?: CSSProperties;
}


function getOptions(statusList: Status[]) {
  return statusList.map((it) => new SingleOption(it.id, it.name));
}

export const DashboardBookings: React.FC<BookingProps> = ({
                                                            style,
                                                          }) => {
  const intl = useIntl();
  const {data: allActiveStatuses} = useActiveStatuses();
  const {data: completeStatus} = useCompleteStatuses();
  const {setOnlyBooking, setEditMode} = useFromProxyActions();
  const selectedBooking = useSelector(fromProxySelectors.selectBooking) as Booking;

  const [bookingMode, setBookingMode] = useState<BookingModeType>("active");
  const [searchQuery, setSearchQuery] = useState<string>("");

  const activeStatus = useMemo(() => allActiveStatuses.filter((s) => s.systemName !== BookingStatus.WAIT_LIST), [allActiveStatuses]);
  const statuses = bookingMode === "active" ? activeStatus : completeStatus;
  const defaultsStatusFilter = useMemo(() => getOptions(statuses.filter(((s) => s.systemName !== BookingStatus.IN_HALL))), [statuses]);
  // фильтры, стартуем со всех выбранных
  const [userLocale] = useLocalStorage<ELocales>('locale');
  const [statusFilter = defaultsStatusFilter, setStatusFilter]
    = useLocalStorage<SingleOption[]>(`dashboard:filter:statuses:${bookingMode}:${userLocale}`);
  const [toggleWaitList, setToggleWaitList] = useState<boolean>(false);

  const {location} = useHistory();
  const {setTime, resetTimeShift} = useTimelineActions()
  const {setPlaceFromBooking} = useApplicationContextActions();
  const {switchMode} = useHallSchemaActions();


  const includeStatuses = (): string[] => {
    const selectedStatusId = statusFilter && statusFilter.map((it) => it.value);
    return statuses.filter((it) => selectedStatusId?.includes(it.id))
      .map((it) => it.systemName);
  };

  const bookingPayload : TUseBookingList = {
    search: searchQuery, includeStatuses : includeStatuses()
  }

  const {isLoading, data} = useBookingsList(bookingPayload);
  const {data: waitingList} = useBookingsList({...bookingPayload, includeStatuses : [BookingStatus.WAIT_LIST], withOutPlaces: true});

  // effect setting up statusFilterValues
  useUpdateEffect(() => {
    const isLocalFilters = localStorage.getItem(`dashboard:filter:statuses:${bookingMode}:${userLocale}`);
    if (isLocalFilters) return;
    setStatusFilter(defaultsStatusFilter);
  }, [defaultsStatusFilter]);


  // При перебросе с истории клиента проверяет какой мод нужно установить
  useEffect(() => {
    if (location.search.includes("complete")) {
      setBookingMode("complete");
    }
    if (location.search.includes("waitList")) {
      setToggleWaitList(true);
    }
  }, []);

  const handleFilterSelect = (e: any) => {
    setStatusFilter(e);
  };

  const onSelectBook = useCallback((book: Booking) => {
    if (book.bookingId === selectedBooking.bookingId) {
      setOnlyBooking(undefined);
      resetTimeShift();
    } else {
      switchMode(HallMode.TABLES);
      setTime(getBookingStartTime(book));
      setPlaceFromBooking(book)
      setOnlyBooking(book);
    }
  }, [selectedBooking]);

  const onEditBook = useCallback((book: Booking) => {
    switchMode(HallMode.TABLES);
    setPlaceFromBooking(book)
    setOnlyBooking(book);
    setEditMode(true);
  }, [selectedBooking]);

  const title = (
    <>
      {intl.formatMessage({id: ETranslations.BASE_BOOKINGS})}
      <RestaurantBookingStats stats={data?.statistics} />
    </>
  );

  return (
    <Card id="booking" style={style}>
      <Card.Header
        title={title} controls={(
        <div className={styles.filterContainer}>
          <RadioButton value={bookingMode} onChange={setBookingMode}>
            <RadioButton.Button
              value="active">{intl.formatMessage({id: ETranslations.BASE_ACTIVE})}</RadioButton.Button>
            <RadioButton.Button
              value="complete">{intl.formatMessage({id: ETranslations.BASE_HISTORY})}</RadioButton.Button>
          </RadioButton>
          <div className={styles.statusFilter}>
            <SelectCheckbox
              isClearable={false}
              placeholder={intl.formatMessage({id: ETranslations.PLURAL_FILTER})}
              onChange={(e) => handleFilterSelect(e)}
              value={statusFilter}
              options={getOptions(statuses)}
              titles={FILTERS_TITLES}
              maxContentWidth
            />
          </div>
        </div>
      )}
      />
      <Card.Content noPadding>
        <div className={styles.search}>
          <Input.Search onSearch={setSearchQuery} isLoading={isLoading} className={styles.searchInput} />
          <Link to={{
            pathname: "./search",
            state: {
              searchQuery,
            },
          }}
          >
            <Button variant="thin" type="button"
                    className={styles.searchButton}>{intl.formatMessage({id: ETranslations.GLOBAL_SEARCH})}</Button>
          </Link>
        </div>
        <div style={{overflowY: "auto", display: toggleWaitList ? "none" : "block", flex: "1.0", padding: "15px 0"}}>
          <BookingsList
            className={cn("opened")}
            bookings={data?.bookings ?? []}
            onClick={onSelectBook}
            onEdit={onEditBook}
            activeBooking={selectedBooking}
            loading={isLoading}
            withActions
            compact
          />
        </div>
        {bookingMode === "active" && (
          <section className={cn(toggleWaitList ? "active" : "")}>
            <div className="badge" onClick={() => setToggleWaitList(!toggleWaitList)}>
              <h3>{intl.formatMessage({id: ETranslations.WAITING_LIST})}</h3>
              <div className="info">
                <div key="people">
                  <ICONS.People />
                  <span>
                        {(waitingList?.bookings ?? [])
                          .reduce((sum, current) => sum + current.persons, 0)}
                      </span>
                </div>
              </div>
              <figure className="collapse">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill="var(--ico-color)" d="M15.41 16.59L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.59Z" />
                </svg>
              </figure>
            </div>
            {toggleWaitList && (
              <BookingsList
                bookings={waitingList?.bookings ?? []} onClick={onSelectBook}
                onEdit={onEditBook}
                activeBooking={selectedBooking}
                loading={isLoading}
                compact
              />
            )}
          </section>
        )}
      </Card.Content>
    </Card>
  );
};
