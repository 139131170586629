import {HallSchema as Scheme} from "components/hall-scheme/redux/HallSchemaV2/hall-schema";
import {useBooleanState} from "hooks/useBooleanState";
import cn from "classnames";
import {useSelector} from "react-redux";
import {useUnmount, useUpdateEffect} from "react-use";

import {HallBookingsList} from "components/HallBookingsList";
import {EditBooking} from "components/hall-scheme/redux/TableBookingList/table-booking-list";
import {tableBookingsTableSelector} from "features/TableBooking/selectors";
import {fromProxySelectors, useFromProxyActions} from "features/BookingFormProxy";
import {moveBookingSelectors} from "features/MoveBooking";
import {Card} from "ui-kit";

import styles from "./HallSheme.module.scss";
import {hallModeSelector} from "features/HallSchema/selectors";
import {HallMode, useHallSchemaActions} from "features/HallSchema";
import React, {useCallback, useMemo, useRef} from "react";
import {useTableBookingListActions} from "features/TableBooking/slice";
import {SelectGuestFromList} from "../../features/GuestsList/components/select-guest-from-list";
import {Client} from "../../types/client";
import {useIntlUtils} from "../../hooks/useIntlUtils";
import {ETranslations} from "../../types/translates";
import {HideWhen} from "components/HideWhen";
import {useIsTablet} from "../../ui-kit/hooks";


export function HallScheme() {
  const isTablet = useIsTablet();
  const [isBookingListShown, show, _hide] = useBooleanState(false);
  const {getIntlEntityEdition, isRussianLocale} = useIntlUtils();
  const table = useSelector(tableBookingsTableSelector);
  const {reset: closeEdit, setClient} = useFromProxyActions();
  const booking = useSelector(fromProxySelectors.booking);
  const hallMode = useSelector(hallModeSelector);
  const {switchMode} = useHallSchemaActions();
  const {reset: resetTable} = useTableBookingListActions();
  const openedByTable = useRef(false);

  const dNdProviderOptions = useMemo(() => {
    const options: { enableMouseEvents?: boolean } = {};
    if (isTablet) {
      // options.enableMouseEvents = true;
    }
    return options;
  }, [isTablet]);

  const intlTitles = useMemo(() => ({
    bookingEdit: getIntlEntityEdition(isRussianLocale ? ETranslations.PLURAL_BOOKINGS_NOM : ETranslations.PLURAL_BOOKING),
  }), []);


  const isMoveBookingSelected = useSelector(moveBookingSelectors.isSourceSelected);

  const close = useCallback(() => {
    openedByTable.current = false;
    if (table) {
      resetTable();
    }
    _hide();
  }, [table, resetTable, _hide]);

  useUnmount(close);

  useUpdateEffect(() => {
    if (!isMoveBookingSelected) return;
    close();
  }, [isMoveBookingSelected, close]);

  useUpdateEffect(() => {
    if (table && !isBookingListShown && !isMoveBookingSelected) {
      openedByTable.current = true;
      show();
    }
  }, [table, isBookingListShown]);

  const closeScheme = useCallback(() => {
    switchMode(HallMode.TABLE_BOOKINGS_LIST);
  }, []);

  useUpdateEffect(() => {
    if (!isBookingListShown) {
      closeEdit();
      closeScheme();
    }
  }, [isBookingListShown, closeEdit]);

  const closeGuests = useCallback(() => switchMode(HallMode.TABLE_BOOKINGS_LIST), [switchMode]);


  const handleSetClient = useCallback((client: Client) => {
    setClient({client});
    closeGuests();
  }, [setClient, closeGuests]);

  const isEditHallOpen = hallMode === HallMode.EDIT_HALL;
  const isShowGuestsList = [HallMode.BOOKING_GUEST, HallMode.TABLE_BOOKINGS_EDIT_GUEST].includes(hallMode);

  return (
    <div className={cn(styles.hallScheme, {
      [styles.isBookingListShown]: isBookingListShown,
      [styles.isEditOpen]: !!booking,
    })}>
      {isBookingListShown && (
        <HallBookingsList onHide={close} className={styles.bookingsList} openedByTable={openedByTable.current} />
      )}
      {!booking && <Scheme onBookingListClick={isBookingListShown ? close : show} />}
      {booking && (
        <Card onClose={isEditHallOpen ? closeScheme : closeEdit} className={styles.editBooking}>
          {!isShowGuestsList && (<Card.Header title={intlTitles.bookingEdit} />)}
          <Card.Content noPadding>
            {isShowGuestsList && (
              <div style={{height: "100%"}}>
                <SelectGuestFromList
                  onClose={closeGuests}
                  onSelectGuest={handleSetClient}
                />
              </div>
            )}
            <EditBooking bookingId={booking.bookingId} />
            {isEditHallOpen && <></>}
            <HideWhen condition={!isEditHallOpen}>
              <div style={{height: "100%"}}>
                <Scheme />
              </div>
            </HideWhen>
          </Card.Content>
        </Card>
      )}
    </div>
  );
}
