import React, {useCallback, useMemo, useState} from "react";
import {Card, Spinner} from "ui-kit";
import {HideWhen} from "components/HideWhen";
import {useIsTablet} from "hooks/useIsTablet";
import {DashboardBookings} from "../../components/booking/DashboardBookings";
import {Booking} from "../../types/booking";
import {EditBooking} from "components/hall-scheme/redux/TableBookingList/table-booking-list";
import {fromProxySelectors, useFromProxyActions} from "features/BookingFormProxy";
import {useSelector} from "react-redux";

import styles from "./Dashboard.module.scss";
import {hallModeSelector} from "features/HallSchema/selectors";
import {HallSchema} from "components/hall-scheme/redux/HallSchemaV2/hall-schema";
import {HallMode, useHallSchemaActions} from "features/HallSchema";
import {CloseBookingContext} from "contexts/CloseBookingContext";
import {SelectGuestFromList} from "features/GuestsList/components/select-guest-from-list";
import {Client} from "types/client";
import {useUnmount, useUpdateEffect} from "react-use";
import {BookingHistory} from "components/BookingHistory";
import {appContextSelectors} from "features/AppContex";
import {useIntlUtils} from "../../hooks/useIntlUtils";
import {ETranslations} from "../../types/translates";

enum Tab{
  Edit = 'Edit',
  History = 'History'
}

export function Dashboard() {
  const {getIntlEntityViewingAction, intl, getIntlHistoryOf} = useIntlUtils();

  const emptyMessage = useMemo(() => (
    <div className={styles.emptyContainer}>
      <div className={styles.icon} />
      <p>{getIntlEntityViewingAction(ETranslations.OF_BOOKING, ETranslations.PLURAL_ALT_BOOKING)}</p>
    </div>
  ), [])

  const isTablet = useIsTablet();
  const {setOnlyBooking: setFormBooking, setClient, reset} = useFromProxyActions();
  const {switchMode} = useHallSchemaActions();
  const booking = useSelector(fromProxySelectors.booking);
  const rest = useSelector(appContextSelectors.restaurant);

  const hasBooking = useMemo(() => !!booking, [booking]);
  const mode = useSelector(hallModeSelector);
    const [tab, setTab] = useState<string>(Tab.Edit);

  const close = useCallback(() => setFormBooking(null), [setFormBooking]);

  const closeHall = useCallback(() => switchMode(HallMode.TABLES), [switchMode]);
  const closeGuests = useCallback(() => switchMode(HallMode.TABLE_BOOKINGS_EDIT), [switchMode]);


  const handleSetClient = useCallback((client: Client) => {
    setClient({client});
    closeGuests();
  }, [setClient, closeGuests]);

  useUnmount(() => switchMode(HallMode.TABLES));

  const isGuestMode = mode.includes('GUEST');

  useUpdateEffect(() => {
    reset();
  }, [rest]);

  return (
    <>
      <React.Suspense fallback={<Spinner />}>
        <HideWhen condition={isTablet && hasBooking} noUnmount>
          <DashboardBookings />
        </HideWhen>
      </React.Suspense>
      {isTablet && !hasBooking ? null : (
        <>
          <CloseBookingContext.Provider value={hasBooking ? close : null}>
            <HideWhen condition={mode.includes('HALL') || isGuestMode} noUnmount>
              <Card onClose={hasBooking ? close : undefined}>
                <Card.HeaderTabs activeTab={tab} onChange={setTab}>
                  <Card.HeaderTabs.Item title={intl.formatMessage({id: ETranslations.BOOKING_MANAGEMENT})} tabName={Tab.Edit} />
                  <Card.HeaderTabs.Item
                    title={getIntlHistoryOf(ETranslations.PLURAL_CHANGES)}
                    tabName={Tab.History}
                    disabled={!hasBooking}
                  />
                </Card.HeaderTabs>
                <HideWhen condition={tab !== Tab.Edit} noUnmount>
                  <Card.Content noPadding>
                    {(
                      hasBooking ? <EditBooking bookingId={(booking as Booking).bookingId} /> : emptyMessage)}
                  </Card.Content>
                </HideWhen>
                <HideWhen condition={tab !== Tab.History}>
                  {booking ? <BookingHistory booking={booking} isExpanded /> : emptyMessage}
                </HideWhen>
              </Card>
            </HideWhen>
          </CloseBookingContext.Provider>
          {tab === Tab.Edit && (
            <>
              <HideWhen condition={!mode.includes('HALL')}>
                <Card onClose={closeHall}>
                  <Card.Header title={intl.formatMessage({id: ETranslations.HALL_SCHEME})} />
                  <Card.Content>
                    <HallSchema />
                  </Card.Content>
                </Card>
              </HideWhen>
              <HideWhen condition={![HallMode.BOOKING_GUEST, HallMode.TABLE_BOOKINGS_EDIT_GUEST].includes(mode)}>
                <div>
                  <SelectGuestFromList
                    onClose={closeGuests}
                    onSelectGuest={handleSetClient}
                  />
                </div>
              </HideWhen>
            </>
            )}
        </>
      )}
    </>
  );
}
