import React, {useCallback, useState} from "react";

import {Card} from "ui-kit";
import {BookingFormReduxProxy} from "components/registration/forms/BookingFormReduxProxy";

import {useCreateBookingMutation, useRegisterBookingMutation} from "features/api/bookings-api";
import {ConfirmOverbookingModal} from "components/modals/ConfirmOverbookingModal";
import {FormBooking} from "features/BookingFormProxy/types";
import {Notification} from "services/notification";
import {useHistory} from "react-router";
import {useDispatch} from "react-redux";
import {invalidateHallSlots} from "features/api/hallschema-api";
import {useCreateGuestMutation} from "features/api/guest-api";
import {ClientsService} from "services/clients.service";
import {useBooleanState} from "hooks/useBooleanState";
import {useIntlUtils} from "../../../../hooks/useIntlUtils";
import {ETranslations} from "../../../../types/translates";


export function useCreateWithOverbooking() {
  const {getIntlActionFailed,  getIntlJoinedParts} = useIntlUtils()
  const [createBooking] = useCreateBookingMutation();
  const [createGuest] = useCreateGuestMutation();
  const [overbookingData, setOverbookingData] = useState<FormBooking | undefined>();
  const dispatch = useDispatch();
  const [isCreating, start, stop] = useBooleanState();

  const create = useCallback(async (formData) => {
    start();
    const data = formData;
    try {
      if (!data?.client?.client_id) {
        const {data: client} = await createGuest({...data.client, phone: data.phone}).unwrap();
        const fullClient = await ClientsService.getById(client.client_id)
        data.client_id = client.client_id;
        data.client = fullClient.data;
      }
      return await createBooking({data}).unwrap();
    } catch (e) {
      // @ts-ignore
      if (e?.data?.errorCode === 10100) {
        setOverbookingData(data);
      }
      throw e;
    } finally {
      stop();
    }
  }, [createBooking, setOverbookingData]);

  const clearData = useCallback(() => setOverbookingData(undefined), [setOverbookingData]);

  const createWithOverbooking = useCallback(async () => {
    if (!overbookingData) return undefined;
    start();
    try {
      const result = await createBooking({
        data: overbookingData,
        isOverbooking: true,
      }).unwrap();
      setOverbookingData(undefined);
      dispatch(invalidateHallSlots());
      return result;
    } catch (e) {
      Notification.error({
        title: getIntlActionFailed(getIntlJoinedParts([ETranslations.BASE_ENTITY_CREATION, ETranslations.PLURAL_BOOKING])),
      });
      throw e;
    } finally {
      stop();
    }
  }, [overbookingData, createBooking]);

  return {
    create,
    createWithOverbooking,
    isConfirmationRequired: !!overbookingData,
    clearData,
    isCreating,
  };
}

export function useRegisterWithOverbooking() {
  const [createBooking] = useRegisterBookingMutation();
  const [createGuest] = useCreateGuestMutation();
  const dispatch = useDispatch();
  const [overbookingData, setOverbookingData] = useState<FormBooking | undefined>();
  const [isCreating, start, stop] = useBooleanState();

  const create = useCallback(async (formData) => {
    const data = formData;
    start();
    try {
      if (!data?.client?.client_id) {
        const {data: client} = await createGuest({...data.client, phone: data.phone}).unwrap();
        data.client_id = client.client_id;
        data.client = client;
      }
      return await createBooking({data}).unwrap();
    } catch (e) {
      // @ts-ignore
      if (e?.data?.errorCode === 10100) {
        setOverbookingData(data);
      }
      throw e;
    } finally {
      stop();
    }
  }, [createBooking, setOverbookingData]);

  const clearData = useCallback(() => {
    setOverbookingData(undefined);
  }, [setOverbookingData]);

  const createWithOverbooking = useCallback(async () => {
    if (!overbookingData) return undefined;
    start();
    try {
      const result = await createBooking({
        data: overbookingData,
        force: true,
      }).unwrap();
      setOverbookingData(undefined);
      dispatch(invalidateHallSlots());
      return result;
    } finally {
      stop();
    }
  }, [overbookingData, createBooking]);

  return {
    create,
    createWithOverbooking,
    isConfirmationRequired: !!overbookingData,
    clearData,
    isCreating,
  };
}

export const CreateBooking = ({
  reset,
}: {
  reset: any
  }): React.ReactElement => {
  const {getIntlEntityCreation,isRussianLocale, getIntlAddOf} = useIntlUtils();
  const {create, createWithOverbooking, isConfirmationRequired, clearData, isCreating} = useCreateWithOverbooking();
  const history = useHistory<{ from: string } | null>();

  const close = useCallback(() => {
    const {from} = history.location.state || {};
    if (from !== history.location.pathname) {
      history.push(from ?? '/');
    } else {
      reset();
    }
  }, [history, reset]);


  const createBooking = useCallback(async (data) => {
    const {client: {editClient = false, client_id, name, surname = '', middle_name = ''}} = data;
    if (editClient) {
      const {data: userData} = await ClientsService.getById(client_id);
      const newUser = {...userData, surname, name, middle_name, type_authorization: 'AUTH'};
      await ClientsService.updateClientInfo(newUser);
    }
    await create(data);
    close();
  }, [create, reset, history]);

  const createWithOver = useCallback(async () => {
    await createWithOverbooking();
    close();
  }, [createWithOverbooking, reset]);

  return (
    <Card onClose={close}>
      <Card.Header
        title={
          isRussianLocale ? getIntlEntityCreation(ETranslations.BASE_BOOKINGS)
            : getIntlAddOf(ETranslations.PLURAL_BOOKING)
      }
      />
      <Card.Content noPadding>
        <BookingFormReduxProxy
          submitFn={createBooking}
          afterSubmit={reset}
        />
        <ConfirmOverbookingModal
          isOpen={isConfirmationRequired}
          onDecline={clearData}
          onConfirm={createWithOver}
          disabled={isCreating}
        />
      </Card.Content>
    </Card>
  );
};
