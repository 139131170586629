import {useMemo} from 'react';
import {SlotEvent} from '../../features/api/hallschema-api';
import {getTimeHoursAndMinutes, minsToString} from '../../common/helpers';
import _ from 'lodash';
import {HallTableStatus} from '../../models/hall-table';
import {ActionTypeSelector, HallMode} from '../../features/HallSchema';
import {TableColor} from '../hall-scheme/hall-helpers';
import moment, {Moment} from 'moment';
import {BookingStatus} from 'types/booking';
import {getBookingEndTime, getBookingStartTime} from 'utils';
import {Place} from '../../types/place';
import {ETranslations} from "../../types/translates";

export const ICON_SIZE = 80;

export interface SlotExtraOptions {
  slot: SlotEvent | undefined,
  tableColor: TableColor,
  stripPercent: number,
  timeWord: string,
  timeString: string,
  canBook?: boolean,
  tableStatus: HallTableStatus | undefined
}


const emptyExtraOptions: SlotExtraOptions = {
  tableColor: TableColor.no_color,
  stripPercent: -1,
  timeString: '',
  timeWord: '',
  canBook: true,
  slot: undefined,
  tableStatus: undefined,
};

export const isVisiting = (slot: SlotEvent, time: Moment) => {
  const start = getBookingStartTime(slot.booking);
  const end = getBookingEndTime(slot.booking);
  return time.isSameOrAfter(start) && time.isSameOrBefore(end);
};

const sortSlotsByTime = (a: SlotEvent, b: SlotEvent) => getBookingStartTime(a.booking).valueOf() - getBookingStartTime(b.booking).valueOf();

export function selectSlotByShift(slots: SlotEvent[], time: Moment, onlyCurrent = false): SlotEvent | undefined {
  if (!slots.length) return undefined;
  const [inHallBookings, restSlots] = _.partition(slots, (s) => s.booking.status.systemName === BookingStatus.IN_HALL);
  const sortedSlots = restSlots.sort(sortSlotsByTime);
  const [, currentOrFuture] = _.partition(sortedSlots, (slot) => getBookingStartTime(slot.booking).isBefore(time, 'minute')
    && getBookingEndTime(slot.booking).isBefore(time));

  const [minSlot] = sortedSlots;
  const [currentTimeSlots, futureSlots] = _.partition(currentOrFuture, (s) => isVisiting(s, time));

  const visiting = _.concat(inHallBookings.sort(sortSlotsByTime), currentTimeSlots.sort(sortSlotsByTime))
    .filter(Boolean);
  const [nextSlot] = futureSlots;

  if (onlyCurrent) {
    return visiting[0];
  }

  const otherSlots = [nextSlot || minSlot].filter(Boolean).sort(sortSlotsByTime);

  const [first, ...nextBookings] = _.uniqBy(_.concat(visiting, otherSlots).filter(Boolean), (s) => s.booking.bookingId);
  if (!first) return undefined;
  const isBookingEnd = getBookingEndTime(first.booking).subtract(1, 'minute').isBefore(time);
  if (!isBookingEnd) return first;

  const sliderNotMoved = time.isSameOrBefore(moment(), 'minute');

  // Если текущая бронь пересиживает и ползунок не двигали, возвращаем ee
  // Теперь если ползунок не двигали вперед =)
  if (sliderNotMoved && first.booking.status.systemName === BookingStatus.IN_HALL) {
    return first;
  }

  return nextBookings
    .find((b) => b.booking.status.systemName !== BookingStatus.IN_HALL
      && time.isBefore(getBookingEndTime(b.booking)));
}

export function getHallTableStatus(slot: SlotEvent, time: Moment): HallTableStatus | undefined {
  const now = moment();
  const {booking: {status: {systemName: statusSystemName = ''} = { }} = {}} = slot;
  const start = getBookingStartTime(slot.booking);
  const isChangedTime = moment().isBefore(time, 'minute');
  const isBookingEnd = getBookingEndTime(slot.booking).isBefore(time);
  if (statusSystemName === 'IN_HALL' && isBookingEnd && !isChangedTime) return HallTableStatus.delay_end;
  if (statusSystemName === 'IN_HALL' && !isBookingEnd) return HallTableStatus.in_hall;

  if (isBookingEnd) return undefined;

  if (['CONFIRMED', 'EXTERNAL', 'NEW'].includes(statusSystemName)) {
    if (time.isBefore(start, 'minute')) return HallTableStatus.booked;
    if (time.isSameOrAfter(start, 'minutes')) {
      if (now.isSameOrAfter(start, 'minute') && now.isSame(start, 'date')) {
        return HallTableStatus.delay_start;
      }
      return HallTableStatus.busy;
    }
  }
  return undefined;
}

export function getOptions(slot: SlotEvent, time: Moment, status?: HallTableStatus) {
  const defaultValues = {
    tableColor: TableColor.no_color,
    timeWord: '',
    timeString: '',
    stripPercent: -1,
  };

  const timeString = slot.bookingStartTime ? moment(slot.bookingStartTime, 'HH:mm:ss').format('HH:mm') : defaultValues.timeString;
  const minutesAfterStart = time.diff(getBookingStartTime(slot.booking), 'minutes');
  const minutesBeforeEnd = getBookingEndTime(slot.booking).diff(time, 'minutes');

  switch (status) {
    case HallTableStatus.booked: {
      const diff = moment.duration({minutes: Math.abs(minutesAfterStart)});
      const {minutes, hours} = getTimeHoursAndMinutes(diff.asMinutes())
      return {
        ...defaultValues,
        tableColor: TableColor.light_grey,
        timeWord: ETranslations.BOOKING_SOON,
        timeString: `${hours}:${minutes < 10 ? '0' + minutes : minutes}`,
      };
    }
    case HallTableStatus.delay_start:
      return {
        ...defaultValues,
        tableColor: TableColor.yellow,
        timeString: minsToString(minutesAfterStart),
        stripPercent: (minutesBeforeEnd * 100) / slot.booking.visitTime,
      };
    case HallTableStatus.in_hall:
    case HallTableStatus.busy:
      return {
        ...defaultValues,
        tableColor: TableColor.green,
        timeString: minsToString(minutesBeforeEnd),
        stripPercent: (minutesBeforeEnd * 100) / slot.booking.visitTime,
      };
    case HallTableStatus.delay_end:
      return {
        ...defaultValues,
        tableColor: TableColor.red,
        timeString: minsToString(Math.abs(minutesBeforeEnd)),
        stripPercent: 100,
      };
    case HallTableStatus.booked_not_conf:
      return {
        ...defaultValues,
        tableColor: TableColor.no_color,
        timeString,
      };
    default:
      return defaultValues;
  }
}

export function getSlotExtraOptions(slot: SlotEvent | undefined, time: Moment): SlotExtraOptions {
  if (!slot) return emptyExtraOptions;
  const tableStatus = getHallTableStatus(slot, time);
  return {...getOptions(slot, time, tableStatus), slot, tableStatus};
}


export const getType = (hallMode: HallMode, bookingId?: number | null) => useMemo((): ActionTypeSelector => {
  switch (hallMode) {
    case HallMode.TABLES:
      if (bookingId) return 'disable-reg';
      return 'full';
    case HallMode.MOVE_BOOKINGS_CAPTURED:
    case HallMode.TABLE_BOOKINGS_MOVE:
      return 'swap-captured';
    // case HallMode.MOVE_BOOKINGS:
    //   return 'only-swap';
    case HallMode.BOOKING_GUEST:
    case HallMode.BOOKING_HALL:
    case HallMode.REGISTRATION_GUESTS:
    case HallMode.REGISTRATION_HALL:
    case HallMode.TABLE_BOOKINGS_EDIT:
    case HallMode.EDIT_HALL:
      return 'select-table';
    default:
      return 'full';
  }
}, [hallMode, bookingId]);

export const getCenterCoords = (place: Place | undefined, schemeFactor = 1) => {
  if (!place) return null;
  const {schemaTable} = place;

  const centerX = (schemaTable.x * schemeFactor) + ((schemaTable.width * schemeFactor) / 2);
  const centerY = (schemaTable.y * schemeFactor) + ((schemaTable.height * schemeFactor) / 2);
  return {x: centerX, y: centerY};

};

export const sumOfSquares = (x : number, y : number) : number => x ** 2 + y ** 2;
