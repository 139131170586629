import {FunctionComponent} from 'react';
import cn from 'classnames';
import {Tag as ITag} from 'types/tag';

import styles from './Tag.module.scss';

interface Props {
  tag: ITag;
}

export const Tag: FunctionComponent<Props> = ({tag}) => (
  <div
    className={cn(styles.tag, styles[tag.status || ''], tag.status && styles.status)}
    style={{
      backgroundColor: tag.status ? '' : tag.color,
    }}
  >
    {tag.name}
  </div>
);
