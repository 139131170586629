import {DeepPartial} from '@reduxjs/toolkit';
import moment, {Moment} from 'moment';
import {Booking, BookingOrder, BookingStatus} from 'types/booking';

export function getBookingStartTime(booking: Booking | BookingOrder): Moment {
  return moment(`${booking.bookingDate} ${booking.bookingTime}`, 'YYYY-MM-DD HH:mm').startOf('minute');
}

export function getBookingEndTime(booking: Booking): Moment {
  return getBookingStartTime(booking).add({minutes: booking.visitTime});
}

export function isBookingOrder(booking: Booking | BookingOrder): booking is BookingOrder {
  return Boolean((booking as BookingOrder).orderId);
}

export function isBooking(booking: Booking | BookingOrder): booking is Booking {
  return Boolean((booking as Booking).bookingId);
}

export function getBookingId(booking: Booking | BookingOrder) {
  return isBooking(booking) ? booking.bookingId : booking.orderId;
}


export function isBookingCanBeCanceled(booking: DeepPartial<Booking>) {
  return ![
    BookingStatus.IN_HALL,
    BookingStatus.CANCELED,
    BookingStatus.CLOSED,
    BookingStatus.NOT_COME
  ].includes(booking.status?.systemName as BookingStatus)
}
