import React, {useEffect, useMemo} from 'react';
import {FieldRenderProps} from 'react-final-form';
import {SingleOption} from '../../../models/common';
import {rusDeclension} from '../../../common/helpers';
import _ from 'lodash';
import {RadioButton, SelectBasic} from 'ui-kit';
import {useIntl} from "react-intl";
import {HOURS_TITLES} from "../../../constants";
import {ETranslations} from "../../../types/translates";
import {defer} from 'lodash';
import {useFormVisitTimes} from '../hooks/useFormVisitTimes';
import {useMinutesVisitOptions} from '../hooks/useMinutesVisitOptions';
import {useDefaultVisitTime} from '../hooks/useDefaultVisitTime';
import {useIsCreateBooking} from '../hooks/useIsCreateBooking';
import styles from "./style.module.scss"


const ChoiceVisitTimeSelectors = ({value, onChange, onBlur, disabled, touched}: any) => {
  const {options} = useFormVisitTimes();
  const intl = useIntl();
  const getHourSingleOption = (val: number) => {
    const hourHumanValue = Math.trunc(val / 60);
    const intlEnding = intl.formatMessage({id: rusDeclension(hourHumanValue, HOURS_TITLES)})
    return new SingleOption(val, `${hourHumanValue} ${intlEnding}`);
  };

  const hourTrunc = (value) ? Math.trunc(value / 60) : 0;
  const minuteTrunc = (value) ? value % 60 : 0;

  const defaultVisitTime = useDefaultVisitTime();

  const isCreateBooking = useIsCreateBooking();

  useEffect(() => {
    if (touched || !defaultVisitTime || !isCreateBooking) return;

    onChange(defaultVisitTime);
   }, [touched, defaultVisitTime]);

  const hourQuarters = useMinutesVisitOptions();
  const getHours = (): SingleOption | undefined => (hourTrunc > 0 ?
    getHourSingleOption(hourTrunc * 60) : new SingleOption('' as unknown as number,
      `0 ${intl.formatMessage({id: ETranslations.PLURAL_HOURS})}`));

  const handleHour = (e: any) => {
    const newValue = (e as SingleOption).value + minuteTrunc;
    if (_.includes(options, newValue)) {
      onChange(newValue);
    } else {
      onChange(_.max(options));
    }
    onBlur();
  };

  const getHourOptions = () => {
    if (options) {
      return options
        .filter((it) => it % 60 === 0)
        .map((it) => getHourSingleOption(it));
    }
    return [];
  };

  const getMinutes = () : SingleOption | undefined => {
    if (value) {
      return hourQuarters.find((it) => it.value === minuteTrunc);
    }
    return undefined;
  };

  const handleMinute = (e: any) => {
    const newValue = (e as SingleOption).value + hourTrunc * 60;
    onChange(newValue);
    onBlur();
  };

  useEffect(() => {
    if (disabled) return undefined;
    if (!options.includes(value) && options.length) {
      const id = defer(() => {
        onChange(_.last(options));
      });
      return () => {
        clearTimeout(id);
      }
    }
    return undefined;
  }, [value, options, disabled]);

  const getMinuteOptions = () => {
    if (options) {
      if (_.includes(options, 60)) {
        // Если время меньше часа то бронь 30 или 45 минут
        if(hourTrunc === 0) return _.slice(hourQuarters, 2, 4)

        // Узнаем оставшиеся опции для минут в зависимости от выбранного значения часов
        const indexForSplice = options.findIndex(el => el === (hourTrunc * 60))
        const copyOptions = [...options]
        copyOptions.splice(0, indexForSplice)

        return _.slice(hourQuarters, 0, copyOptions.length)
      }
        const max = _.max(options) as number;
        return hourQuarters.filter((it) => it.value <= max && it.value > 15);

    }
    return [];
  };

  useEffect(() => {
    // Если время меньше часа, и количество минут меньше 15. Устанавливаем дефолтные 30 минут.
    if(hourTrunc === 0 && minuteTrunc <= 15 && options.length) {
      onChange(30);
    }
  },[hourTrunc, minuteTrunc, options])

  return (
    <div className="hours-mins">
      <SelectBasic
        value={getHours()}
        onChange={handleHour}
        options={getHourOptions()}
        placeholder={intl.formatMessage({id: ETranslations.PLURAL_HOURS_NOM})}
        changedMaxSize
        isDisabled={disabled}
      />
      <SelectBasic
        value={getMinutes()}
        changedMaxSize
        onChange={handleMinute}
        options={getMinuteOptions()}
        placeholder={intl.formatMessage({id: ETranslations.PLURAL_MINUTES_NOM})}
        isDisabled={disabled}
      />
    </div>
  );
};

const ChoiceVisitTimeButtons = ({value, onChange, disable}: any) => {
  const {options} = useFormVisitTimes();
  const intl = useIntl();
  const noLimit = useMemo(() => _.last(options), [options]);

  const defaultIsNotAvailable = useMemo(() => !options.includes(120), [options]);

  return (
    <RadioButton value={value} onChange={onChange} disabled={disable} className={styles.radioButtons}>
      <RadioButton.Button value={noLimit}>{intl.formatMessage({id: ETranslations.NO_LIMIT})}</RadioButton.Button>
      <RadioButton.Button value={120}>2 {intl.formatMessage({id: ETranslations.PLURAL_HOURS_ALT})}</RadioButton.Button>
    </RadioButton>
  );
};

export const ChoiceVisitTimeButtonsInput: React.FC<FieldRenderProps<string, any>> = ({input, meta, ...rest}) => (
  <ChoiceVisitTimeButtons {...input} {...meta} {...rest} />
);

export const ChoiceVisitTimeControls: React.FC<FieldRenderProps<any>> = ({input, meta, ...rest}) => (
  <ChoiceVisitTimeSelectors {...input} {... meta} {...rest} />
);
