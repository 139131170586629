import React, {FC, useEffect, useState} from 'react';
import {Client} from "models/client.model";
import GuestBadge from "./GuestBadge/GuestBadge";
import GuestInfoRow from "./GuestInfoRow/GuestInfoRow";
import GuestInfoBigBlock from "./GuestColumn/GuestInfoBigBlock";
import Control, {TButtons} from "./Control/Control";
import {TNullable} from "types/commons";
import {toBase64} from "utils";
import {useLazyFetchClientQuery, useUpdateGuestMutation} from "../../features/api/guest-api";

interface GuestCardProps {
    guest?: Client;
    buttons: TButtons[]
}

const GuestContent: FC<GuestCardProps> = ({guest, buttons}) => {
    if (!guest) return null
    const [selectedFile, setSelectedFile] = useState<TNullable<File>>(null);
    const [updateGuest] = useUpdateGuestMutation()
    const [getClient] = useLazyFetchClientQuery()

    useEffect( () => {
       ( async ()=>{
           if (selectedFile) {
               const clientPhoto = await toBase64(selectedFile)
               if (typeof clientPhoto === 'string') {
                   await updateGuest({...guest, client_photo: clientPhoto || guest?.client_photo})
                   if (guest?.client_id) {
                       await getClient(guest?.client_id)
                   }
               }
           }
        })()
    }, [selectedFile])

    return (
        <>
            <GuestBadge
              guest={guest}
              setSelectedFile={setSelectedFile}
            >
                <Control buttons={buttons}/>
            </GuestBadge>
            <GuestInfoRow guest={guest}/>
            <GuestInfoBigBlock guest={guest}/>
        </>
    );
};

export default GuestContent;
