import React, {useCallback, useEffect, useMemo, useState} from "react";
import cn from "classnames";

import {Spinner, Card, Input} from "ui-kit";
import {useTableBookingListActions} from "features/TableBooking/slice";
import {useDispatch, useSelector} from "react-redux";
import {tableBookingSearchSelector, tableBookingsTableSelector} from "features/TableBooking/selectors";
import {BookingFormReduxProxy} from "components/registration/forms/BookingFormReduxProxy";

import {hasBookingSelector, selectBooking} from "features/BookingFormProxy/selectors";
import {HallMode, useHallSchemaActions} from "features/HallSchema";
import styles from "./TableBookingList.module.scss";
import {useTablesBookings} from "./useTableBookings";
import {BookingCardDetailList} from "./BookingCardDetailList";
import {Booking, BookingStatus} from "types/booking";
import {BookingCard} from "components/BookingCard";
import {useFromProxyActions} from "features/BookingFormProxy";
import {
  useGetBookingQuery,
  useSetStatusMutation,
  useUpdateBookingFromFormHandler,
} from "features/api/bookings-api";
import {ConfirmOverbookingModal} from "components/modals/ConfirmOverbookingModal";
import {FormBooking} from "features/BookingFormProxy/types";
import {Notification} from "services/notification";
import {invalidateHallSlots} from "features/api/hallschema-api";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {ClientsService} from "services/clients.service";
import {CloseBookingContext} from "contexts/CloseBookingContext";
import {useCreateGuestMutation, useLazyFetchGuestDetailQuery, useUpdateGuestMutation} from 'features/api/guest-api';
import {useBooleanState} from "hooks/useBooleanState";
import {usePlacesTable} from "features/api/tables-api";
import {appContextSelectors} from "features/AppContex";
import {useUpdateEffect} from "react-use";
import {TableActions} from "./TableActions";
import {useIntlUtils} from "../../../../hooks/useIntlUtils";
import {ETranslations} from "../../../../types/translates";

interface EditBookingProps {
  bookingId: number;
  hideCard?: boolean;
}

export const EditBooking = ({bookingId, hideCard}: EditBookingProps) => {
  const {getIntlActionFailed, getIntlJoinedParts} = useIntlUtils();
  const {data: booking} = useGetBookingQuery(bookingId);
  const {updateBookingHandler} = useUpdateBookingFromFormHandler();
  const [setStatus] = useSetStatusMutation();
  const {setEditMode} = useFromProxyActions();
  const [createGuest] = useCreateGuestMutation();
  const [getUser] = useLazyFetchGuestDetailQuery();
  const [overbookingData, setOverbookingData] = useState<FormBooking | undefined>();
  const dispatch = useDispatch();
  const [isUpdating, start, stop] = useBooleanState();
  const [updateGuest] = useUpdateGuestMutation()

  const update = useCallback(async (data) => {
    start();
    let {client} = data;
    try {
      const {client_id, phone, email} = data;
      const {editClient = false, name, surname = "", middle_name = ""} = client;
      if (!client.client_id) {
        const {data: {client_id: newClientId}} = await createGuest({
          middle_name,
          name,
          phone,
          surname,
          email,
        }).unwrap();
        client = await getUser(newClientId).unwrap();
      }
      if (editClient) {
        const {data: userData} = await ClientsService.getById(client_id);
        const newUser = {...userData, surname, name, middle_name, type_authorization: "AUTH"};
        await updateGuest(newUser)
      }
      await updateBookingHandler({...data, client: {...client, client_id: client_id ?? client.client_id}}).unwrap();
      dispatch(invalidateHallSlots());
    } catch (e) {
      if (e?.data?.errorCode === 10100) {
        setOverbookingData(data);
      }
      throw e;
    } finally {
      stop();
    }
  }, [updateBookingHandler, setOverbookingData, dispatch]);

  const clearData = useCallback(() => setOverbookingData(undefined), [setOverbookingData]);

  const updateWithOverbooking = useCallback(async () => {
    if (!overbookingData) return;
    start();
    try {
      await updateBookingHandler({...overbookingData, force: true}).unwrap();
      dispatch(invalidateHallSlots());
      clearData();
    } catch {
      Notification.error({
        title: getIntlActionFailed(getIntlJoinedParts([ETranslations.BASE_ENTITY_CREATION, ETranslations.PLURAL_BOOKING])),
      });
    } finally {
      stop();
    }
  }, [overbookingData, setStatus, updateBookingHandler, dispatch]);

  useEffect(() => () => {
    setEditMode(false);
  }, [setEditMode]);

  return (
    <div className={styles.edit}>
      {!hideCard && (
        <div className={cn(styles.sectionContent, styles.editCard)}>
          {booking ? <BookingCard booking={booking} compact className={styles.bookingCard} /> : <Spinner />}
        </div>
      )}
      {booking && (
        <BookingFormReduxProxy
          submitFn={update}
        />
      )}
      <ConfirmOverbookingModal isOpen={!!overbookingData} onDecline={clearData} onConfirm={updateWithOverbooking}
                               disabled={isUpdating} />
    </div>
  );
};


export const TableBookingList: React.FC = () => {
  const {switchMode} = useHallSchemaActions();
  const {reset: resetTable, setSearchValue} = useTableBookingListActions();
  const {reset: resetBooking} = useFromProxyActions();
  const userSelectBooking = useSelector(hasBookingSelector);
  const searchValue = useSelector(tableBookingSearchSelector);
  const table = useSelector(tableBookingsTableSelector);
  const booking = useSelector(selectBooking);

  const {data, isLoading} = useTablesBookings(undefined,!table, searchValue);
  const {table: tableData} = usePlacesTable(table);

  const bookingList = useMemo(() => data?.bookings?.filter(({status}) => status.systemName !== BookingStatus.NOT_COME) || [], [data]);

  const closeWindowHandler = useCallback(() => {
    // switch mode
    switchMode(HallMode.TABLES);
    // reset table
    resetTable();
  }, [resetTable, switchMode]);
  // todo: calculate mode for view
  // todo: read selected booking, calc

  const handleClose = useMemo(() => (userSelectBooking ? () => {
      resetBooking();
      switchMode(HallMode.TABLE_BOOKINGS_LIST);
    } : closeWindowHandler),
    [userSelectBooking, resetBooking, closeWindowHandler]);

  const rest = useSelector(appContextSelectors.restaurant);
  useUpdateEffect(closeWindowHandler, [rest]);

  const isRegisterDisabled = useMemo(() => bookingList.some((b) => b.status.systemName === BookingStatus.IN_HALL), [bookingList]);

  const isEdit = userSelectBooking && booking;

  return (
    <CloseBookingContext.Provider value={closeWindowHandler}>
      <Card id="table-bookings" onClose={handleClose}>
        <Card.Header title={`Брони стола №${tableData?.number || ""}`}>
          <div className="right-block">
            <Input.Search
              value={searchValue}
              onSearch={(search) => setSearchValue({search})}
            />
          </div>
        </Card.Header>
        {isEdit ? (
          <Card.Content noPadding>
            {isLoading && <Spinner />}
            <EditBooking
              bookingId={(booking as Booking).bookingId}
            />
          </Card.Content>
        ) : (
          <Card.Content className={styles.listContainer} noPadding>
            <div className={styles.list}>
              {isLoading && <Spinner />}
              <BookingCardDetailList data={bookingList} />
            </div>
            {tableData && <TableActions isRegisterDisabled={isRegisterDisabled} table={tableData} />}
          </Card.Content>
        )}
      </Card>
    </CloseBookingContext.Provider>
  );
};
