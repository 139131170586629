import React, {useCallback} from 'react';
import './default-user.scss';
import cn from 'classnames';
import {FieldRenderProps, useField} from 'react-final-form';
import {useIsTablet} from '../../../hooks/useIsTablet';
import {useFromProxyActions} from '../../../features/BookingFormProxy';
import {HallMode, useHallSchemaActions} from '../../../features/HallSchema';
import {useSelector} from 'react-redux';
import {defaultUserSelector} from '../../../features/AppContex/selectors';

const TriggerButton = ({buttonTitle, value, onChange}: any) => {
  const {input: client} = useField('client', {subscription: {}});
  const isTablet = useIsTablet();
  const defaultClient = useSelector(defaultUserSelector);
  const {resetClient} = useFromProxyActions();
  const {switchMode} = useHallSchemaActions();

  const handleChange = useCallback(() => {
    if (value) {
        client.onChange(null);
        resetClient();
      if (!isTablet) {
        switchMode(HallMode.REGISTRATION_GUESTS);
      }
    } else {
      client.onChange(defaultClient);
      !isTablet && switchMode(HallMode.REGISTRATION_HALL);
    }
     onChange(!value);
  }, [value, onChange, resetClient, isTablet, switchMode, defaultClient]);

  return (
    <div
      className={cn('trigger-button', (value ? 'active' : ''))}
      onClick={handleChange}
    >
      {buttonTitle}
    </div>
  );
};

export const ChoiceDefaultUser: React.FC<FieldRenderProps<any>> = ({input, meta, ...rest}) => (
  <TriggerButton {...input} {...meta} {...rest} />
  );
