import React from 'react';
import {FieldRenderProps} from 'react-final-form';
import {SelectBasic} from 'ui-kit';
import {useIntl} from "react-intl";
import {ETranslations} from "../../../types/translates";

const ChoiceSource = ({value, onChange, disabled, options}: any) => {
  const intl = useIntl();

  return <SelectBasic
    className="content"
    value={value}
    openTo="top"
    changedMaxSize
    isClearable
    isTagsOrSource
    menuPlacement="top"
    isDisabled={disabled}
    onChange={onChange}
    options={options}
    maxMenuHeight={280}
    placeholder={intl.formatMessage({id: ETranslations.BASE_CHOOSE})}
  />;
};

export const ChoiceSourceInput: React.FC<FieldRenderProps<any, any>> = ({input, meta, ...rest}) => (
  <ChoiceSource {...input} {...meta} {...rest} />
);
