import {useFormState} from 'react-final-form';


import {useSelector} from 'react-redux';
import * as select from 'features/BookingFormProxy/selectors';
import {FormBooking} from 'features/BookingFormProxy/types';

import {useRecoveryBooking} from 'hooks/useRecoveryBooking';
import {Button} from 'ui-kit';
import {useIntl} from "react-intl";
import {ETranslations} from "../../../../types/translates";

const BUTTON_DISABLED = false;

export function Recovery() {
  const intl = useIntl();
  const {submitting} = useFormState<FormBooking>();
  const booking = useSelector(select.selectBooking);
  const {recovery, isRecovering} = useRecoveryBooking(booking);
  const isEdit = useSelector(select.isEdit);

  if (isEdit) return null;

  if (BUTTON_DISABLED) return <span />;

  return (
     <Button variant="secondary"  disabled={submitting || isRecovering} onClick={recovery}>
       {intl.formatMessage({id: ETranslations.BASE_RESTORE})}
     </Button>
  );
}
