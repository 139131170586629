import {middlePollingInterval} from './utils';
import {BookingOrder, BookingOrderFilter, OrderCountRequest} from '../../types/booking';
import {OrderType, Response} from '../../types/commons';
import {useSelector} from 'react-redux';
import {appContextSelectors} from '../AppContex';
import {FormBooking} from '../BookingFormProxy/types';
import {castFormToUpdateDTO} from '../BookingFormProxy/utils';
import {OrdersHistoryClientResponse} from 'types/order';
import {IPayloadClientHistory} from 'components/BookingClientHistory/BookingClientHistory';
import {api} from './api';
import {Notification} from 'services/notification';
import {ETranslations} from 'types/translates';
import moment from 'moment';

export const bookingOrderApi = api.enhanceEndpoints({
  addTagTypes: ['orders', 'count', 'TableOptions'],
}).injectEndpoints({
  endpoints: (build) => ({
    fetchCount: build.query<number, OrderCountRequest>({
      query: (request: OrderCountRequest) => ({
        url: '/v2/order/count',
        method: 'GET',
        params: request,
      }),
      transformResponse: (response: Response<number>) => response.data,
      providesTags: ['count'],
    }),
    fetchOrders: build.query<BookingOrder[], BookingOrderFilter>({
      query: (filter: BookingOrderFilter) => ({
        url: '/v2/order/search',
        params: filter,
        method: 'GET',
      }),
      providesTags: ['orders'],
      transformResponse: (response: Response<BookingOrder[]>) => response.data,
    }),
    createOrder: build.mutation<String, FormBooking>({
      query: (formData: FormBooking) => ({
        url: `/v2/order/create/${formData.bookingId}`,
        method: 'POST',
        body: castFormToUpdateDTO(formData),
      }),
      transformResponse: (response: Response<String>) => response.data,
      invalidatesTags: ['orders', 'count', 'TableOptions'],
      async onQueryStarted(id, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (err) {
          if (err?.error?.data?.errorCode !== 10100) {
            if(err?.error?.data?.errorCode === 10600){
              Notification.error({
                title: ETranslations.UNABLE_TO_CREATE_BOOKING_COVERAGE,
              });
            } else {
              Notification.error({
                title: ETranslations.UNABLE_TO_CREATE_BOOKING,
                message: err?.message,
              });
            }

          }
          throw err;
        }
      },
    }),
    closeOrder: build.mutation<String, number>({
      query: (orderId: number) => ({
        url: `/v2/order/close/${orderId}`,
        method: 'POST',
      }),
      transformResponse: (response: Response<String>) => response.data,
      invalidatesTags: ['orders', 'count'],
    }),
    fetchOrderClient: build.query<OrdersHistoryClientResponse, {clientId: number, payload: IPayloadClientHistory}>({
      query: ({clientId, payload}) => ({
        url: `/v2/order/client/${clientId}`,
        method: 'POST',
        body: payload,
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useCreateOrderMutation,
  useCloseOrderMutation,
  useFetchCountQuery,
  useFetchOrdersQuery,
  useFetchOrderClientQuery,
} = bookingOrderApi;

export const useOrderCount = () => {
  const restaurant = useSelector(appContextSelectors.restaurant);
  const startDate = useSelector(appContextSelectors.date);
  return useFetchCountQuery(
    {restaurantId: restaurant.restaurant_id, date: startDate.format('YYYY-MM-DD')},
    {pollingInterval: middlePollingInterval},
  );
};

export const useOrderList = (fetchMode: OrderType, keyword: string) => {
  const restaurant = useSelector(appContextSelectors.restaurant);
  const startDate = moment();
  const monthForward = () => startDate.clone().add(6, 'months').format('YYYY-MM-DD');
  const dates = fetchMode === 'active' ? {from: startDate.format('YYYY-MM-DD'), to: monthForward()} :
    {
      from: startDate.clone().subtract('1', 'month').format('YYYY-MM-DD'),
      to: startDate.clone().subtract(1, 'day').format('YYYY-MM-DD'),
    };
  const requestParams: BookingOrderFilter = {
    restaurantId: restaurant.restaurant_id,
    search_keyword: keyword,
    ...dates,
  };
  return useFetchOrdersQuery(requestParams, {pollingInterval: middlePollingInterval});
};
