import {useCallback} from 'react';
import {useFormState} from 'react-final-form';


import {useSelector} from 'react-redux';
import * as select from 'features/BookingFormProxy/selectors';
import {FormBooking} from 'features/BookingFormProxy/types';
import {useCancelBookingMutation, useGetBookingStatusQuery} from 'features/api/bookings-api';

import {Button} from 'ui-kit';
import {useIntl} from "react-intl";
import {ETranslations} from "../../../../types/translates";
import {isBookingCanBeCanceled} from 'utils';
import {skipToken} from '@reduxjs/toolkit/query';


export function CancelBooking() {
  const intl = useIntl();
  const {submitting} = useFormState<FormBooking>();
  const booking = useSelector(select.selectBooking);
  const isEdit = useSelector(select.isEdit);
  const {data} = useGetBookingStatusQuery(booking.bookingId ?? skipToken);

  const [cancel] = useCancelBookingMutation();


  const cancelBooking = useCallback(() => {
    const {bookingId} = booking;
    if (!bookingId || submitting) return;
    cancel(bookingId);
  }, [booking, submitting, close]);


  if (isEdit || (!data?.terminal && !data?.next) ) return null;
  if (!isBookingCanBeCanceled(booking)) return null;

  return (
    <Button variant="dangerous" onClick={cancelBooking} disabled={submitting}>
      {intl.formatMessage({id: ETranslations.BASE_CANCEL})}
    </Button>
  );
}
