import React from 'react';
import cn from 'classnames';
import {Table} from '../../features/api/hallschema-api';
import {ICON_SIZE} from './utils';
import {SCHEME_FACTOR} from '../hall-scheme/redux/HallSchemaV2/hall-schema';
import {SvgForeignObject} from './SvgForeignObject';

import styles from './TableIconNumber.module.scss';

export const TableIconNumber: React.FC<Table & { className?: string}> = ({schema, number, className}) => (
  <SvgForeignObject
    x={(SCHEME_FACTOR * schema.x) - ICON_SIZE / 2.5}
    y={(SCHEME_FACTOR * schema.y) - ICON_SIZE / 2.5}
    width={ICON_SIZE}
    height={ICON_SIZE}
  >
    <div className={cn(styles.tableNumber, className)}>{number}</div>
  </SvgForeignObject>
);
