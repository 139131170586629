import {useSelector} from 'react-redux';

import {SingleOption} from 'models/common';

import {appContextSelectors} from 'features/AppContex';
import {useApplicationContextActions} from 'features/AppContex/slice';
import {useCallback, useMemo} from 'react';
import {SelectBasic} from 'ui-kit';

interface PlaceSelectProps{
  disabled?: boolean;
}


export function PlaceSelect({disabled}: PlaceSelectProps) {
  const {setPlace} = useApplicationContextActions();

  const placesOptions = useSelector(appContextSelectors.placesOptions);
  const place = useSelector(appContextSelectors.place);

  const handleChange = useCallback((opt: SingleOption| null) => opt && setPlace(opt.value), [setPlace]);

  const value = useMemo(() => placesOptions.find((el) => el.value === place), [place]);

  return (
    <SelectBasic
      isSearchable={false}
      options={placesOptions}
      value={value}
      isDisabled={disabled}
      onChange={handleChange as any}
    />
  );
}
