import {skipToken} from '@reduxjs/toolkit/query/react';
import {BookingResponse} from '../../../models/booking.model';
import {BookingsFilter} from '../../../services/bookings.service';
import {Booking, BookingFilterParams, BookingRequestFilter, SearchResponse, TUseBookingList} from 'types/booking';
import {IResponse} from '../../../models/common';
import {handleResponseError} from '../utils';
import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {appContextSelectors} from 'features/AppContex';
import moment from 'moment';
import {Response} from 'types/commons';
import {FormBooking} from 'features/BookingFormProxy/types';
import {castFormToCreateDTO, castFormToUpdateDTO} from 'features/BookingFormProxy/utils';
import {Notification} from 'services/notification';
import {bookingFormSliceActions} from 'features/BookingFormProxy';
import {coreApi} from './core';
import {GlobalSearchParams} from 'types/globalSearch';
import {PageableResponse} from 'types/response';
import {useAllStatuses} from '../dictionaries-api';
import {ETranslations} from "../../../types/translates";
import {TBookingUpdateParams} from "../../../types/IBookingDTO";


export const bookingApi = coreApi.injectEndpoints({
  endpoints: (build) => ({
    fetchBookingSearch: build.query<BookingResponse[], BookingsFilter>({
      query: (filter: BookingsFilter) => ({
        url: 'reservation/booking/search',
        method: 'POST',
        body: filter,
      }),
      transformResponse: (response: IResponse<BookingResponse[]>) => response.data,
    }),
    fetchActiveBookings: build.query<SearchResponse, BookingRequestFilter>({
      query: (filter: BookingRequestFilter) => ({
        url: 'v2/booking/search',
        method: 'POST',
        body: filter,
      }),
      transformResponse: (response: Response<SearchResponse>) => response.data,
      providesTags: ['Bookings'],
    }),
    fetchTerminateBookings: build.query<SearchResponse, BookingRequestFilter>({
      query: (filter: BookingRequestFilter) => ({
        url: 'v2/booking/search/completed',
        method: 'POST',
        body: filter,
      }),
      transformResponse: (response: Response<SearchResponse>) => response.data,
      providesTags: ['Bookings'],
    }),
    getBookings: build.query<SearchResponse, BookingFilterParams>({
      query: (filter: BookingFilterParams) => ({
        url: 'v2/booking/filter',
        method: 'POST',
        body: filter,
      }),
      transformResponse: (response: Response<SearchResponse>) => response.data,
      providesTags: ['Bookings'],
      keepUnusedDataFor: 0
    }),
    registerBooking: build.mutation<FormBooking, any>({
      query: ({data, force = false}: { data: FormBooking, force?: boolean }) => ({
        url: 'v2/booking/register',
        params: {force},
        method: 'POST',
        body: castFormToCreateDTO(data),
      }),
      invalidatesTags: ['Bookings', 'TableOptions'],
      async onQueryStarted(id, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (err) {
          if (err?.error?.data?.errorCode !== 10100) {
            Notification.error({
              title: err?.error?.data?.errorMessage,
            });
          }
          throw err;
        }
      },
    }),
    getBooking: build.query<Booking, number>({
      query: (id: number) => ({
        url: `v2/booking/${id}`,
        method: 'get',
      }),
      transformResponse: (response: IResponse<Booking>) => response.data,
      providesTags: (booking) => [{type: 'Booking', id: booking?.bookingId}],
      keepUnusedDataFor: 0,
    }),
    createBooking: build.mutation({
      query: ({
                data, isOverbooking = false,
              }: {
        data: FormBooking, isOverbooking?: boolean
      }) => ({
        url: 'v2/booking/create',
        method: 'POST',
        params: {force: isOverbooking || false},
        body: castFormToCreateDTO(data),
      }),
      invalidatesTags: ['Bookings', 'TableOptions', 'GlobalSearchBookings'],
      async onQueryStarted(id, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (err) {
          if (err?.error?.data?.errorCode !== 10100) {
            if(err?.error?.data?.errorCode === 10600){
              Notification.error({
                title: ETranslations.UNABLE_TO_CREATE_BOOKING_COVERAGE,
              });
            } else {
              Notification.error({
                title: ETranslations.UNABLE_TO_CREATE_BOOKING,
                message: err?.message,
              });
            }

          }
          throw err;
        }
      },
    }),
    updateBooking: build.mutation({
      query: ({force, bookingId, ...body}: TBookingUpdateParams) => ({
        url: `v2/booking/${bookingId}`,
        method: 'PUT',
        body,
        params: {
          force,
        },
      }),
      invalidatesTags: (result, err, args) => ['Bookings', {type: 'Booking', id: args.bookingId}, 'TableOptions', 'GlobalSearchBookings'],
      async onQueryStarted(id, {dispatch, queryFulfilled}) {
        try {
          const {data: {data}} = await queryFulfilled;
          dispatch(bookingFormSliceActions.setBooking({booking: data, client: data.client}));
          Notification.success({
            title: ETranslations.BOOKING_UPDATE_SUCCESSFULLY,
          });
        } catch (err) {
          if (err?.error?.data?.errorCode !== 10100) {
            Notification.error({
              title: ETranslations.UNABLE_TO_UPDATE_BOOKING,
              message: err?.message,
            });
          }
          throw err;
        }
      },
    }),
    globalSearch: build.query<PageableResponse<Booking>, GlobalSearchParams>({
      query: (params: GlobalSearchParams) => ({
        url: '/v2/booking/search/full',
        params,
      }),
      providesTags: ['GlobalSearchBookings'],
      onQueryStarted: async (_args, api) => {
        await handleResponseError(api, ETranslations.SEARCH_REQUEST_FAILED);
      },
    }),
  }),
});

export const {
  useFetchBookingSearchQuery,
  useFetchActiveBookingsQuery,
  useFetchTerminateBookingsQuery,
  useUpdateBookingMutation,
  useCreateBookingMutation,
  useRegisterBookingMutation,
  useGetBookingQuery,
  useGlobalSearchQuery,
  useGetBookingsQuery
} = bookingApi;

export function useBookingsList(
   {fromDate, forTables, search, toDate, includeStatuses, isPooling = true, userid, isSkip = false, withOutPlaces} : TUseBookingList
) {
  const restaurant = useSelector(appContextSelectors.restaurant);
  const selectedPlaces = useSelector(appContextSelectors.selectedPlaces);
  const places = useSelector(appContextSelectors.allPlaces);
  const startDate = useSelector(appContextSelectors.date);
  const isBookingForToday = useSelector(appContextSelectors.isDateChanged);

  const includePlaces = useCallback(
    () => places.filter((it) => selectedPlaces.includes(it.id)).map((it) => it.id),
    [selectedPlaces, places],
  );

  const [currentDate, setCurrentDate] = useState(startDate.format('YYYY-MM-DD'));

  // update current date
  useEffect(() => {
    if (fromDate && toDate) return undefined;
    if (!isBookingForToday) {
      setCurrentDate(startDate.format('YYYY-MM-DD'));
      return undefined;
    }
    const interval = setInterval(() => {
      setCurrentDate(moment().format('YYYY-MM-DD'));
    }, 1e3);
    return () => {
      clearInterval(interval);
    };
  }, [isBookingForToday, startDate]);

  const requestParams: BookingFilterParams = {
    restaurant_id: restaurant.restaurant_id,
    from: fromDate ? moment(fromDate).format('YYYY-MM-DD') : currentDate,
    to: toDate ? moment(toDate).format('YYYY-MM-DD') : currentDate,
    only_tables: forTables,
    search_keyword: search,
    sort: [
      {
        "param": "bookingDate",
        "direction": "ASC"
      },
      {
        "param": "bookingTime",
        "direction": "ASC"
      }
    ],
    user_id: userid,
    statuses: includeStatuses,
    places: withOutPlaces ? [] : includePlaces()
  };

  return useGetBookingsQuery(isSkip ? skipToken : requestParams, {pollingInterval: isBookingForToday && isPooling ? 10e3 : undefined})

}

function useBaseBookingsParams(search: string | undefined): BookingFilterParams {
  const restaurant = useSelector(appContextSelectors.restaurant);
  const date = useSelector(appContextSelectors.date);
  const {data: statuses} = useAllStatuses();

  return  {
    restaurant_id: restaurant.restaurant_id,
    from: date.format('YYYY-MM-DD'),
    to: date.format('YYYY-MM-DD'),
    statuses: statuses.filter((status) => !status.isTerminal).map((status) => status.systemName),
    search_keyword: search,
    sort: [
      {
        "param": "bookingDate",
        "direction": "ASC"
      },
      {
        "param": "bookingTime",
        "direction": "ASC"
      }
    ],
  };
}

export const useUpdateBookingHandler = () => {
  const [updateRequest] = useUpdateBookingMutation()

  const updateBookingHandler = useCallback((body: TBookingUpdateParams) => {
    return updateRequest(body)
  }, [])

  return {updateBookingHandler}
}

export const useUpdateBookingFromFormHandler = () => {
  const [updateRequest] = useUpdateBookingMutation()

  const updateBookingHandler = useCallback((formData: FormBooking & Pick<TBookingUpdateParams, "force">) => {
    const data = castFormToUpdateDTO(formData)
    return updateRequest({...data, bookingId: formData.bookingId})
  }, [])

  return {updateBookingHandler}
}

export function useCurrentHallBookings(search: string | undefined, isTable: boolean) {
  const baseParams = useBaseBookingsParams(search);
  const place = useSelector(appContextSelectors.place);

  const params = {
    ...baseParams,
    places: [place],

  }
  return bookingApi.useGetBookingsQuery(isTable ? skipToken : params);
}

export function useCurrentRestBookings(search: string | undefined, isTable: boolean) {
  const baseParams = useBaseBookingsParams(search);

  return useGetBookingsQuery(isTable ? skipToken : baseParams);
}
