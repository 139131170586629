import React, {lazy, useContext} from 'react';
import {Spinner} from 'ui-kit';
import {ModalContext} from 'contexts/ModalContext';
import {usePageId} from 'contexts/LayoutContext';
import {TypeModal} from 'types/rootState';
import {useSelector} from 'react-redux';
import {appContextSelectors} from 'features/AppContex';
import {useAppUsers} from 'contexts/AppUsersContext';
import {useIntlUtils} from "../../hooks/useIntlUtils";

const SettingsComponent = lazy(() => import('components/settings/settings'));

export function Settings() {
  const intlUtils = useIntlUtils();
  const {
    settingsParamsChanges,
    settingsUserModal,
    activateModal,
    setConfirmDialogData,
    setSettingsHostessModal,
  } = useContext(ModalContext);

  usePageId('settings');

  const date = useSelector(appContextSelectors.date);
  const restaurant = useSelector(appContextSelectors.restaurant);
  const selectedPlaces = useSelector(appContextSelectors.selectedPlaces);
  const {userList} = useAppUsers();


  return (
    <React.Suspense fallback={<Spinner />}>
      <SettingsComponent
        intlUtils={intlUtils}
        rest={restaurant.restaurant_id}
        places={selectedPlaces}
        date={date}
        onCallModalParamsChange={(params) => {
            Object.assign(settingsParamsChanges, params);
            activateModal(TypeModal.changesParams, 'root');
          }}
        onCallModalHostess={(hostess, restId) => {
            setSettingsHostessModal({hostess, restId});
            activateModal(TypeModal.changeHostess, 'root');
          }}
        onCallModalConfirm={(id, mode) => {
            setConfirmDialogData({data: id, mode});
            activateModal(TypeModal.confirm, 'root');
          }}
        onCallModalUser={(user, role) => {
            Object.assign(settingsUserModal, {user: user ? {...user} : undefined, role});
            activateModal(TypeModal.changeUser, 'root');
        }}
        userList={userList}
      />
    </React.Suspense>
  );
}
