import {FC, useMemo} from "react";
import moment from "moment";
import {ICONS} from "ui-kit";
import {BookingOrder, BookingWithRest} from "types/booking";
import styles from "./styles.module.scss";
import {getTimeHoursAndMinutes, instanceOfHistory, matchingIconsAndTranslateToStatus} from "common/helpers";
import {BookingStatus} from "services/bookings.service";
import {useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {appContextSelectors, useApplicationContextActions} from "features/AppContex";
import {useFromProxyActions} from "../../features/BookingFormProxy";
import {useHistory} from "react-router";
import {TModeHistory} from "./BookingClientHistory";
import {useCompleteStatuses} from "features/api/dictionaries-api";
import {ETranslations} from "../../types/translates";
import {declOfNum} from "../../utils";
import {HOURS_TITLES, MINUTES_TITLES} from "../../constants";

const {Calendar, Clock, GuestsIcon, HallIcon, RestaurantIcon, SeatIcon} = ICONS;

interface Props {
  booking: BookingWithRest | BookingOrder,
  mode: TModeHistory,
}

export const BookingHistoryItem: FC<Props> = ({booking, mode}) => {
  const intl = useIntl();
  const restaurants = useSelector(appContextSelectors.restaurants);
  const {setOnlyBooking, setOnlyOrder} = useFromProxyActions();
  const {setDate} = useApplicationContextActions();
  const history = useHistory();
  const {data: completeStatus} = useCompleteStatuses();
  const {setPlaceFromBooking, setPlaceFromOrder, setRestaurant} = useApplicationContextActions();


  const {minutes, hours} = getTimeHoursAndMinutes(booking.visitTime)


  const isBooking = mode === "booking";


  if (!booking) return null;

  const restaurantName = useMemo(() => {
    if (!instanceOfHistory(booking)) {
      if (!booking.places?.place) return "";
      const {place: {restaurantId}} = booking.places;
      return restaurants.find((rest) => rest.restaurant_id === restaurantId)?.restaurant_name;
    }
    return "";
  }, [booking, restaurants]);

  const restaurant = useMemo(() => {
    if(instanceOfHistory(booking)){
      return restaurants.find(el => el.restaurant_id === booking.restaurant.id);
    } else {
      const restId = booking.places.place.restaurantId;
      return  restaurants.find(el => el.restaurant_id === restId)
    }
  }, [booking, restaurants]);

  const clickHandler = () => {
    let url = "";
    if(restaurant) setRestaurant(restaurant)
    if (instanceOfHistory(booking)) {
      url = "/dashboard";
      if (completeStatus.some((el) => el.id === booking.status.id)) {
        url += "?mode=complete";
      }
      if (booking.status.systemName === BookingStatus.WAIT_LIST) {
        url += "?mode=waitList";
      }
      setPlaceFromBooking(booking)
      setOnlyBooking(booking);
    } else {
      url = "requests";
      setPlaceFromOrder(booking)
      setOnlyOrder(booking);
    }
    setDate(new Date(booking.bookingDate));
    history.push(url);
  };

  return (
    <div className={styles.listItem} onClick={clickHandler}>
        <span>
          <RestaurantIcon width={22} height={22} />
          <strong>{isBooking && instanceOfHistory(booking) ? booking.restaurant.name : restaurantName}</strong>
        </span>
      <span>
          <Calendar />
        {moment(booking.bookingDate).format("DD.MM.YYYY")}
        </span>
      <span>
          <Clock />
        {moment(`${booking.bookingDate} ${booking.bookingTime}`).format("HH:mm")}
        </span>
      <span>
          <HallIcon width={20} height={20} />
        {instanceOfHistory(booking) ? booking.places[booking.places.length - 1]?.place.name : "-"}
        </span>
      <span>
          <GuestsIcon width={20} height={20} />
        {booking.persons}
        </span>
      <span>
          <SeatIcon />
        {instanceOfHistory(booking) ?
          booking.places[booking.places.length - 1]?.number :
          intl.formatMessage({id: ETranslations.WITHOUT_SEAT})}
        </span>
      <span>
          <Clock />
        {`${hours} ${intl.formatMessage({id: declOfNum(hours, HOURS_TITLES)})}
         ${minutes} ${intl.formatMessage({id: declOfNum(minutes, MINUTES_TITLES)})}`.toLowerCase()}
        </span>
      <span>
          {isBooking && instanceOfHistory(booking)
            ? (
              <>
                <img
                  src={matchingIconsAndTranslateToStatus[booking.status.systemName as keyof typeof BookingStatus].icon}
                  alt="status" />
                <span>{intl.formatMessage({
                  id: matchingIconsAndTranslateToStatus[booking.status.systemName as keyof typeof BookingStatus].text
                })}</span>
              </>
            )
            : intl.formatMessage({id: ETranslations.PLURAL_REQUEST})}
        </span>
    </div>
  );
};
