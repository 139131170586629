import {lazy, Suspense} from "react";
import {Redirect, Route, Switch} from "react-router";
import {DndProvider} from "react-dnd";
import {TouchBackend} from "react-dnd-touch-backend";
import {HTML5Backend} from "react-dnd-html5-backend";

import {Layout} from "components/Layout";
import {Dashboard} from "containers/Dashboard";
import {Settings} from "containers/Settings";
import {Guests} from "containers/Guests";
import {ScheduleLanding} from "containers/ScheduleLanding";
import {LayoutContextProvider} from "contexts/LayoutContext";
import {AppUsersProvider} from "../../contexts/AppUsersContext";
import {Spinner} from "ui-kit";
import {HallContainer} from "components/hall-scheme/redux/HallContainer";
import {HallScheme} from "containers/HallScheme";
import {BookingOrders} from "../../containers/BookingOrders";
import {Search} from "containers/Search";
import {RequestsGrid} from "containers/RequestsGrid";
import {config} from "../../config";
import {useIsTablet} from "../../ui-kit/hooks";
import {DndPreviewContainer} from "../DndPreviewLayouts";

const ApplicationContextWrapper = lazy(() => import("contexts/ModalContext"));


const Root = () => {
  const isTablet = useIsTablet();
  const dndBackend = isTablet ? TouchBackend : HTML5Backend;

  return (
    <Suspense fallback={<Spinner />}>
      <ApplicationContextWrapper>
        <LayoutContextProvider>
          <AppUsersProvider>
            <DndProvider backend={dndBackend}>
              <Switch><Redirect strict exact from="/" to="/dashboard" /></Switch>
              <Layout>
                <Route
                  exact path="/dashboard"
                  component={Dashboard}
                />
                <Route
                  exact path="/hall-scheme"
                  component={HallScheme}

                />
                <Route
                  exact path="/create-booking"
                  render={() => <HallContainer isCreateBookingOpened />}
                />
                <Route
                  exact path="/hall"
                  render={() => <HallContainer />}
                />
                <Route
                  exact path="/requests"
                  component={BookingOrders}
                />
                <Route
                  exact path="/settings"
                  component={Settings}
                />
                <Route
                  exact path="/guests"
                  component={Guests}
                />
                {config.requestGrid && <Route
                  exact path="/grid"
                  component={RequestsGrid}
                />}
                <Route
                  exact path="/schedule-landing"
                  component={ScheduleLanding}
                />
                <Route
                  exact path="/search"
                  component={Search}
                />
              </Layout>
              <DndPreviewContainer />
            </DndProvider>
          </AppUsersProvider>
        </LayoutContextProvider>
      </ApplicationContextWrapper>
    </Suspense>
  );
};

export default Root;
