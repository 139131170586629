import {apiINT} from "./api-data";
import {AuthService} from "./auth.service";
import {RequestWrapper} from "./request-wrapper";
import {Client, ClientResponse} from "models/client.model";
import {Response} from "models/common";

export interface ClientsFilter {
  term?: string | null,
  tags?: number[],
  phone?: string | null,
  count: number,
  offset: number
}

interface CreatedClient {
  name: string;
  surname: string;
  login: string;
  tags: number[];
  type_authorization: string;
  email: string | null;
  phone: string | null;
}

export interface NewClient {
  birthday: null | string | Date;
  comment: string;
  email: string;
  phone: string;
  tags: number[];
  full_name: string;
  name?: string;
  surname?: string;
  middle_name?: string;
}
// @deprecated
export interface NewClientRequest extends NewClient {
  login: string;
  type_authorization: 'AUTH';
  name?: string;
  surname?: string;
  middle_name?: string;
}
// @deprecated
interface ClientInfo {
  user_id: number;
  birthday: Date | string;
  comment: string;
  tags: number[];
  phone: string;
  email: string;
}
// @deprecated
export class ClientsService {
  // @deprecated
  static async getById(client_id: number) {
    return RequestWrapper.fetch(`${apiINT}/reservation/client?client_id=${client_id}`, {headers: {Authorization: AuthService.getToken()}}).then((res) => res.json());
  }
  // @deprecated
  static async createClient(data: NewClientRequest | CreatedClient): Promise<Response<ClientResponse>> {
    return RequestWrapper.fetch(`${apiINT}/reservation/client`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {'Content-Type': 'application/json', Authorization: AuthService.getToken()}
    }).then(function (response) {
      return response.json();
    })
  }
  // @deprecated
  static async updateClientInfo(dataClient: ClientInfo | CreatedClient) {
    return RequestWrapper.fetch(`${apiINT}/reservation/client`, {
      method: 'PUT',
      headers: {'Content-Type': 'application/json', Authorization: AuthService.getToken()},
      body: JSON.stringify(dataClient)
    }).then((r) => r.json())
  }
}
