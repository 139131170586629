import React, {useCallback, useMemo} from "react";
import {useDrag, useDrop} from "react-dnd";
import {HallSlotsQResponse} from "../../features/api/hallschema-api";
import {getSlotExtraOptions, getType, selectSlotByShift} from "./utils";
import {useSelector} from "react-redux";
import cn from "classnames";
import {SCHEME_FACTOR} from "../hall-scheme/redux/HallSchemaV2/hall-schema";
import {SvgForeignObject, SvgForeignObjectBody} from "./SvgForeignObject";
import {constant} from "lodash";
import {HallMode, useHallSchemaActions} from "../../features/HallSchema";
import {activeTablesSelector, hallModeSelector} from "../../features/HallSchema/selectors";
import {TableColor} from "../hall-scheme/hall-helpers";
import {useTableBookingListActions} from "../../features/TableBooking/slice";
import {moveBookingSelectors, useMoveBookingActions} from "features/MoveBooking";
import moment from "moment";
import {TableIconNumber} from "./TableIconNumber";
import {TableOverbooked} from "./TableOverbooked";

import styles from "./Table.module.scss";
import {useSliderDatetime} from "hooks/useSliderDatetime";
import {TableIconLock} from "./TableIconLock";
import {TableGroup} from "./TableGroup";
import {useIntl} from "react-intl";
import {EDraggableEntity} from "../../constants";
import {Booking} from "../../types/booking";
import {getBookingStartTime} from "../../utils";
import {useTimelineActions} from "../../features/Timeline";
import {config} from "../../config";
import {ICONS} from "../../ui-kit";

export const Table: React.FC<HallSlotsQResponse> = ({table, slots}) => {
  const intl = useIntl();
  const {setTime} = useTimelineActions();
  const {selectMoveSource, selectSourceTableNumber} = useMoveBookingActions();
  const {addTargetTables, removeTargetTable, selectTargetBooking} = useMoveBookingActions();

  const handleMoveClick = useCallback((booking: Booking) => {
    const tableNumber = booking.places.map((place) => place.number);
    setTime(getBookingStartTime(booking));
    booking.bookingId && selectMoveSource(booking.bookingId);
    selectSourceTableNumber(tableNumber);
    const payload = {
      tableId: table.table_id,
      bookingId: booking?.bookingId,
      tableNumber: table.number.toString(),
    };
    addTargetTables(payload);
  }, [table]);

  const [{isOver}, drop] = useDrop(() => ({
    accept: [EDraggableEntity.BOOKING_TABLE, EDraggableEntity.BOOKING_CARD],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      booking: monitor.getItem<Booking>(),
    }),
    drop: handleMoveClick,
  }), [table, slots]);

  const timeWithDate = useSliderDatetime();
  const hallMode = useSelector(hallModeSelector);
  const activeTables = useSelector(activeTablesSelector);
  const {selectTableInHall, switchMode} = useHallSchemaActions();
  const {type, table_id, schema: {x, y, width, height, shape}} = table;
  const slot = selectSlotByShift(slots, timeWithDate());
  const {
    tableColor, timeWord, timeString,
    tableStatus,
    slot: {booking} = {},
  } = getSlotExtraOptions(slot, timeWithDate());
  const {setTable} = useTableBookingListActions();


  const checkBookingTime = useMemo(() => {
    if (!booking) return false;
    const timeOnLine = timeWithDate();
    const timeBooking = moment(`${booking?.bookingDate} ${booking?.bookingTime}`).add(booking?.visitTime, "minute");
    return timeOnLine <= timeBooking;
  }, [timeWithDate, booking]);


  const moveSource = useSelector(moveBookingSelectors.sourceBookingId);
  const isTableSelected = useMemo(() => moveBookingSelectors.isTableSelectedFactory(table.table_id), [table]);
  const isSelected = useSelector(isTableSelected);
  const isBookingSelected = useMemo(() => (booking ?
    moveBookingSelectors.isBookingSelectedFactory(booking.bookingId) : constant(false)), [booking]);


  const isTableMoveTarget = useSelector(isTableSelected);
  const isBookingMoveTarget = useSelector(isBookingSelected);
  const isMoveTarget = useMemo(() => isTableMoveTarget || isBookingMoveTarget, [isTableMoveTarget, isBookingMoveTarget]);

  const moveBookingDisplayClass = () => {
    if (moveSource && moveSource === booking?.bookingId) return "moveSource";
    if (isMoveTarget) return "selectedForMove";
    return undefined;
  };
  const modalType = getType(hallMode, booking?.bookingId);
  const tableColorClass = modalType === "select-table" && !!activeTables.find((it) => it === table_id) ?
    cn(TableColor.light_grey, "selectedTable") :
    tableColor;
  // todo: calculate classes selectedForMove
  const handleTableClick = (e: React.MouseEvent) => {
    // dont push this event higher to dom
    e.stopPropagation();

    if (modalType === "swap-captured") {
      const payload = {
        tableId: table.table_id,
        bookingId: booking?.bookingId,
        tableNumber: table.number.toString(),
      };
      if (isSelected) {
        removeTargetTable(payload);
        return;
      }

      if (booking?.bookingId && checkBookingTime) {
        selectTargetBooking(payload);
      } else {
        addTargetTables(payload);
      }
      return;
    }

    if (modalType === "select-table") {
      selectTableInHall(table_id);
      return;
    }

    setTable(table_id);

    if (hallMode !== HallMode.TABLE_BOOKINGS_LIST) {
      switchMode(HallMode.TABLE_BOOKINGS_LIST);
      return;
    }
    // no handle if disable
    if (hallMode === HallMode.TABLE_BOOKINGS_LIST) {
      selectTableInHall(table_id);
    }
  };

  const surname = slot?.booking.client.surname || null;
  const name = slot?.booking.client.name || null;
  const fullname = useMemo(
    () => {
      if (!tableStatus) return null;
      return [surname, name].filter((el) => el).map((el, index) => (index === 0 ? el : `${el?.charAt(0).toUpperCase()}.`)).join(" ");
    },
    [slot, tableStatus],
  );

  const [, drag, dragPreview] = useDrag(() => ({
    type: EDraggableEntity.BOOKING_TABLE,
    canDrag: config.dragAndDrop && !!fullname,
    item: slot?.booking,
  }), [fullname, slot]);

  return (
    <>
      <TableGroup table={table} slots={slots} />
      <SvgForeignObject
        x={SCHEME_FACTOR * x}
        y={SCHEME_FACTOR * y}
        width={SCHEME_FACTOR * width}
        height={SCHEME_FACTOR * height}
        onClick={handleTableClick}
        className={cn(
          "table", shape, tableColorClass, moveBookingDisplayClass(),
        )}
      >
        <SvgForeignObjectBody className={cn(styles.table, {[styles.scaled]: isOver})} ref={drag}>
          <div
            ref={drop}
            className={cn("table-box", isOver && "scaled")}
            style={{
              width: SCHEME_FACTOR * width,
              height: SCHEME_FACTOR * height,
            }}
          >
            <div className="pics">
              <span>
                <svg
                  style={{
                    width: 80,
                    height: 60,
                    marginRight: 15,
                  }}
                  viewBox="10 8 20 30" width="50"
                  height="40"
                >
                  <use href="#myDot" />
                </svg>
                <span className="count">{tableStatus ? booking?.persons || type : type}</span>
              </span>
            </div>
            {fullname && (
              <>
                <span className={cn(styles.clientInfo, styles.guest)} ref={dragPreview}>{fullname}</span>
                {config.vipSign && slot?.booking.client.vip && <img className={styles.vipStar} src={ICONS.StarA} alt="vip" />}
              </>
            )}
            {Boolean(timeString) && (
              <span className={styles.timeTableInfo}>
                {timeString}
              </span>
            )}
            {Boolean(timeWord) && (
              <span className={styles.timeWordInfo}>
                {intl.formatMessage({id: timeWord})}
              </span>
            )}
          </div>
        </SvgForeignObjectBody>
      </SvgForeignObject>
      <TableIconNumber {...table} className={cn({[styles.tableNumberHovered]: isOver})} />
      {slots.some(({booking: b}) => b?.isOverbooking) && <TableOverbooked {...table} />}
      {Boolean(booking?.useDeposit)
        && <TableIconLock {...table} position={table.schema.shape === "round" ? "center" : "left"} />}
    </>
  );
};
