import {BaseQueryFn} from '@reduxjs/toolkit/dist/query';
import {MutationLifecycleApi, QueryLifecycleApi} from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {AuthService} from 'services/auth.service';
import {Notification} from 'services/notification';

export const apiORD = '/api';
export const apiINT = `${apiORD}/internal`;
export const apiEXT = `${apiORD}/external`;

export const shortPollingInterval = 1e3;// - 15 sec
export const middlePollingInterval = 15e3;// - ??
export const longPollingInterval = 60e3;// - ??

export const getDefaultHeaders = (headers: Headers): Headers => {
  headers.set('Content-Type', 'application/json');
  headers.set('Authorization', AuthService.getToken());
  return headers;
};

type Api<QueryArg, BaseQuery extends BaseQueryFn, ResultType, ReducerPath extends string> =
  QueryLifecycleApi<QueryArg, BaseQuery, ResultType, ReducerPath> | MutationLifecycleApi<QueryArg, BaseQuery, ResultType, ReducerPath>

export async function handleResponseError<
  QueryArg, ResultType = object, BaseQuery extends BaseQueryFn = BaseQueryFn,
  ReducerPath extends string = string>({queryFulfilled}: Api<QueryArg, BaseQuery, ResultType, ReducerPath>, message: string) {
    try {
      await queryFulfilled;
    } catch (e) {
      console.error(e);
      Notification.error({
        title: message,
        message: e.error?.data?.errorMessage,
      });
      throw e;
  }
}
