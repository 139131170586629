import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {FormProxyProps} from './types';
import moment from 'moment';
import {Form, FormSpy} from 'react-final-form';
import {useHallSchemaActions} from 'features/HallSchema';
import {useUnmount, useUpdateEffect} from 'react-use';
import {useFromProxyActions} from 'features/BookingFormProxy';
import {useDispatch, useSelector} from 'react-redux';
import {selectFormBooking} from 'features/BookingFormProxy/selectors';
import {appContextSelectors} from 'features/AppContex';
import {useGuestListActions} from 'features/GuestsList';
import {invalidateHallSlots} from 'features/api/hallschema-api';
import {useAppUsers} from 'contexts/AppUsersContext';
import {useQueryParam} from 'hooks/useQueryParam';
import {formatPhoneNumberIntl} from 'react-phone-number-input';
import {FormBooking} from 'features/BookingFormProxy/types';
import {BookingStatus} from 'types/booking';
import {BookingForm} from './BookingForm';
import {useActiveShift} from 'features/api/shifts';
import {getTimeOption} from './utils';
import {Client} from 'types/client';


export function BookingFormReduxProxy<P>({component: Component, isRegisterVisit, ...rest}: FormProxyProps<P>) {
  const restaurant = useSelector(appContextSelectors.restaurant);

  const dispatchRedux = useDispatch();
  const startDate = useSelector(appContextSelectors.date);
  const currentPlace = useSelector(appContextSelectors.place);

  // Store prev state
  const formValuesRef = useRef(undefined);
  const {setActiveTables} = useHallSchemaActions();
  const {reset: resetForm, resetClient, setClient} = useFromProxyActions();
  const {reset: resetGuestsList} = useGuestListActions();
  const {currentUser} = useAppUsers();
  const {submitFn} = rest;
  const formData = useSelector(selectFormBooking);

  const onSubmit = useCallback(async (values: any) => {
    const now = moment();
    const payload = {
      ...values,
      placeId: currentPlace,
      restaurant_id: restaurant.restaurant_id,
      status: startDate.isSame(now, 'day') ? BookingStatus.CONFIRMED : BookingStatus.NEW,
    }
    await submitFn(payload as FormBooking)?.then(() => {
      // После отправки формы обновляем начальные значения. Для корректной работы последующего редактирования
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      updateInitial(payload)
      dispatchRedux(invalidateHallSlots());
    });
  }, [submitFn, dispatchRedux, startDate, currentPlace]);


  const initialPhone = useQueryParam('phone') as string | undefined;

  const now = useMemo(moment, []);

  const shift = useActiveShift(now);

  const closestInterval = useMemo(() => {
    if (!shift) return null;
    return Object.keys(shift.times).find((val, idx, arr) => {
      if (val === now.format("HH:mm")) return true;
      if (idx < 1 ) return false
      const current = moment(val, "HH:mm");
      const prev = moment(arr[idx - 1], "HH:mm");
      return now.isSameOrAfter(prev, "second") && now.isSameOrBefore(current, "second");
    })
  } ,[shift, now])

  // eslint-disable-next-line no-underscore-dangle
  const _initial = useMemo(() => ({
    ...formData,
    phone: initialPhone ? formatPhoneNumberIntl(`+${initialPhone}`) || formData.phone : formData.phone,
    time: isRegisterVisit ? getTimeOption(closestInterval || formData?.booking_time) : getTimeOption(formData?.booking_time),
    hostess: currentUser,
    date: formData?.booking_date && formData.booking_time
      ? moment(`${formData?.booking_date} ${formData?.booking_time}`).toDate() : startDate.toDate(),
    deposit: formData?.deposit || {use_deposit: false, deposit_made: false, deposit_amount: 0},
    restaurant_id: formData.restaurant_id || restaurant.restaurant_id,
    visit_time: formData.visit_time ?? 120,
    tables: formData.tables ?? [],
  }), [formData.bookingId, formData.tables, formData.phone, initialPhone, currentUser, isRegisterVisit, closestInterval]);

  // ЭТО КОСТЫЛЬ ЧТОБЫ ПРЕДОТВРАТИТЬ РЕ-РЕНДЕР ФОРМЫ
  const [initial, updateInitial] = useState(_initial);

  useUpdateEffect(() => {
    updateInitial(_initial);
  }, [_initial.bookingId, _initial.tables.length, _initial.time?.label]);
  // КОНЕЦ КОСТЫЛЯ


  useEffect(() => {
    setClient({client: initial.client as Client});
  }, [initial.client]);


  useUnmount(() => {
    setActiveTables({activeTables: []});
    resetForm();
    resetGuestsList();
    resetClient();
  });


  const dispatchEventFn = (formValues: any) => {
    formValuesRef.current = formValues;
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initial}
      render={(props) => (
        <>
          {Component ? <Component {...props} {...rest} /> : <BookingForm isRegisterVisit={isRegisterVisit}/>}
          <FormSpy
            subscription={{values: true}}
            onChange={(form) => dispatchEventFn(form.values)}
          />
        </>
      )}
    />
  );
}
