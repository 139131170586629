// read only interface
import {ETranslations} from "./translates";

export interface Response<T> {
  data: T,
  timestamp: string,
  // optionals
  errorCode?: number,
  errorMessage?: string,
}

export type SortDirection = 'ASC' | 'DESC'
export type OrderType = 'active' | 'history';

export interface Pagination<T>{
  content: Array<T>,
  empty: boolean,
  first: boolean,
  last: boolean,
  number: number,
  numberOfElements: number,
  pageable: {sort: {sorted: boolean, unsorted: boolean, empty: boolean}, pageNumber: number, pageSize: number, offset: number}
  size: number
  sort: {sorted: boolean, unsorted: boolean, empty: boolean}
  totalElements: number
  totalPages: number
}

export type TPluralsTitles = [ETranslations, ETranslations, ETranslations, ETranslations]
export type TNullable<T> = T | null | undefined;
export enum ELocales {
  ru_RU = 'ru_RU',
  en_EN = 'en_EN'
}

export enum EDaysOfWeek {
  MONDAY = 1,
  TUESDAY ,
  WEDNESDAY ,
  THURSDAY,
  FRIDAY ,
  SATURDAY ,
  SUNDAY ,
}
