import React, {ChangeEvent, useEffect, useLayoutEffect, useRef, useState} from 'react';
import Checkbox from 'common/components/checkbox';
import {FieldRenderProps} from 'react-final-form';
import {Deposit} from '../../../features/BookingFormProxy/types';
import {Input} from 'ui-kit';
import styles from './Deposit.module.scss'
import {useIntl} from "react-intl";
import {ETranslations} from "../../../types/translates";

const ChoiceDeposit = ({value, onChange, disabled}: any) => {
  const intl = useIntl();
  const {use_deposit, deposit_made, deposit_amount} = value;
  const [checkboxText, setCheckboxText] = useState<string>(intl.formatMessage({id: ETranslations.DEPOSIT_MADE}));
  const widthControlRef = useRef(null);
  const handleUseDeposit = (useDeposit: boolean) => onChange({...value, use_deposit: useDeposit});
  const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => onChange({...value, deposit_amount: e.target.value});
  const handleDepositMade = (depositMade: boolean) => onChange({...value, deposit_made: depositMade});
  useEffect(() => {
    if (!use_deposit) {
      onChange({...value, deposit_amount: 0, deposit_made: false});
    }
  }, [use_deposit]);

  useLayoutEffect(() => {
    if (widthControlRef.current) {
      // @ts-ignore
      const {offsetWidth} = widthControlRef.current;
      if (offsetWidth < 300) {
        setCheckboxText('');
      }
    }
  }, [widthControlRef]);
  return (
    <div className="control-group" ref={widthControlRef}>
      <div className="left">
        <button
          className={`${use_deposit ? 'active' : ''}`}
          disabled={disabled}
          onClick={() => handleUseDeposit(!use_deposit)}
          type="button"
        >
          {use_deposit ? intl.formatMessage({id: ETranslations.WITH_DEPOSIT}) : intl.formatMessage({id: ETranslations.NO_DEPOSIT})}
        </button>
      </div>
      <div className={styles.deposit}>
        <Input
          size={1}
          pattern="[0-9*]"
          maxLength={6}
          value={deposit_amount}
          onChange={handleAmountChange}
          type="text"
          placeholder={intl.formatMessage({id: ETranslations.BASE_SUM})}
          disabled={disabled || !use_deposit}
          className={styles.input}
          suffix={
            <span className="suffix">₽</span>
          }
        />
        <Checkbox
          indent="deposit"
          text={checkboxText}
          onChange={handleDepositMade}
          value={deposit_made}
          disabled={disabled || !use_deposit}
        />
      </div>
    </div>
  );
};

export const ChoiceDepositInput: React.FC<FieldRenderProps<Deposit, any>> = ({input, meta, ...rest}) => (
  <ChoiceDeposit {...input} {...meta} {...rest} />
);
