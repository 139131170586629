import React from "react";
import {colourStyles} from "common/helpers";
import {FieldRenderProps} from "react-final-form";
import {Tag} from "../../../models/booking.model";
import {SingleOption} from "models/common";
import {useTagsOptions} from "features/api/tags";
import {SelectTag} from "ui-kit";
import {useIntlUtils} from "../../../hooks/useIntlUtils";
import {ETranslations} from "../../../types/translates";

type Props = FieldRenderProps<Tag[], any>;

const ChoiceTags = ({value = [], onChange, disabled}: any) => {
  const {getIntlChooseEntity} = useIntlUtils();

  const tagOptions = useTagsOptions();
  const selectedOptions = tagOptions.filter((o: SingleOption) => value && value.some((v: SingleOption) => v.value === o.value));
  return (
  <SelectTag
    className="multiple-tags"
    openTo="top"
    isTagsOrSource
    menuPlacement="top"
    isMulti
    isSearchable
    changedMaxSize
    closeMenuOnSelect={false}
    blurInputOnSelect={false}
    styles={colourStyles}
    options={tagOptions}
    value={selectedOptions}
    onChange={onChange}
    isDisabled={disabled}
    placeholder={getIntlChooseEntity(ETranslations.PLURAL_TAGS_NOM)}
  />
  );
};

export const ChoiceTagsInput: React.FC<Props> = ({input, meta, ...rest}) => (
  <ChoiceTags {...input} {...meta} {...rest} />
);
