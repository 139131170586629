import React, {useCallback, useMemo} from "react";
import {useHistory} from "react-router";

import {Card} from "ui-kit";
import {BookingFormReduxProxy} from "components/registration/forms/BookingFormReduxProxy";
import {ConfirmOverbookingModal} from "components/modals/ConfirmOverbookingModal";
import {useRegisterWithOverbooking} from "./CreateBooking";
import {useIntlUtils} from "hooks/useIntlUtils";
import {ETranslations} from "types/translates";
import {useCreateGuestMutation, useLazyFetchGuestDetailQuery, useUpdateGuestMutation} from 'features/api/guest-api';
import {ClientsService} from 'services/clients.service';

export const RegisterVisit = ({
  reset,
}: {
  reset: any
}): React.ReactElement => {
  const {getIntlRegistrationOf, isRussianLocale} = useIntlUtils();
  const registrationTitle = useMemo(() => {
    return getIntlRegistrationOf(isRussianLocale ? ETranslations.PLURAL_VISITS_ALT : ETranslations.PLURAL_VISIT)
  }, [])
  const {create, createWithOverbooking, clearData, isConfirmationRequired, isCreating} = useRegisterWithOverbooking();
  const history = useHistory<{ from: string }>();
  const [createGuest] = useCreateGuestMutation();
  const [getUser] = useLazyFetchGuestDetailQuery();
  const [updateGuest] = useUpdateGuestMutation()


  const close = useCallback(() => {
    reset()
    const {from} = history.location.state || {};
    if (from !== history.location.pathname) {
      history.push(from ?? '/');
    }
  }, [history, reset]);

  const registerVisit = useCallback(async (data) => {
    let {client} = data
    const {client_id, phone, email} = data;
    const {editClient = false, name, surname = "", middle_name = ""} = client;
    if (!client.client_id) {
      const {data: {client_id: newClientId}} = await createGuest({
        middle_name,
        name,
        phone,
        surname,
        email,
      }).unwrap();
      client = await getUser(newClientId).unwrap();
    }
    if (editClient) {
      const {data: userData} = await ClientsService.getById(client_id);
      const newUser = {...userData, surname, name, middle_name, type_authorization: "AUTH"};
      await updateGuest(newUser)
    }
    await create({...data, client});
    close();
  }, [create, close]);

  const registerOverbooking = useCallback(async () => {
    await createWithOverbooking();
    close();
  }, [createWithOverbooking, close]);

  return (
    <Card onClose={close}>
      <Card.Header
        title={registrationTitle}
      />
      <Card.Content noPadding>
        <BookingFormReduxProxy
          isRegisterVisit
          submitFn={registerVisit}
        />
         <ConfirmOverbookingModal
           isOpen={isConfirmationRequired}
           onDecline={clearData}
           onConfirm={registerOverbooking}
           disabled={isCreating}
         />
      </Card.Content>
    </Card>
  );
};
