import {BookingCard, BookingCardProps, BookingCardViewProps} from 'components/BookingCard';
import cn from 'classnames';
import {memo} from 'react';
import {Booking, BookingOrder} from 'types/booking';

import styles from './BookingList.module.scss';
import {Spinner} from 'ui-kit';
import {BookingActions} from 'components/BookingActions';
import {getBookingId, isBooking} from 'utils';
import {OrderActions} from 'components/BookingActions/OrderActions';
import {useIntl} from "react-intl";
import {ETranslations} from "../../types/translates";

export interface BookingsListProps<T extends Booking | BookingOrder> extends Pick<BookingCardProps<T>, 'onClick'>, BookingCardViewProps {
  bookings: T[];
  activeBooking?: T;
  className?: string;
  emptyMessage?: string;
  loading?: boolean;
  withActions?: boolean;
  allowMove?: boolean;
  onEdit?: (booking: T) => void;
}

const List = <T extends Booking | BookingOrder>({
  bookings,
  className,
  activeBooking,
  onClick,
  emptyMessage,
  loading,
  withActions,
  allowMove,
  onEdit,
  ...props
}: BookingsListProps<T>) => {
  const intl = useIntl()

  if (!bookings.length) {
    return (
      <div className={cn(styles.list, className)}>
        <div className={styles.blank}>
          {emptyMessage || intl.formatMessage({id: ETranslations.BOOKING_LIST_EMPTY})}
          {loading && <Spinner className={styles.spinner} />}
        </div>
      </div>
      );
  }

  return (
    <div className={cn(styles.list, className)}>
      {bookings.map((b) => (
        <BookingCard
          key={getBookingId(b)}
          booking={b}
          onClick={onClick}
          {...props}
          className={styles.card}
          active={activeBooking && getBookingId(activeBooking) === getBookingId(b)}
          actions={withActions ? (
            isBooking(b) ?
              <BookingActions booking={b} onEdit={onEdit as any} allowMove={allowMove} />
              : <OrderActions order={b} onEdit={onEdit as any} />
          ) : undefined}
        />
      ))}
      {loading && <Spinner className={styles.spinner} />}
    </div>
  );
}
export const BookingsList = memo(List) as typeof List;
