import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {timelineReducer, timelineSliceName} from 'features/Timeline';
import {guestListReducer, guestListSliceName} from './features/GuestsList';
// import {applicationContextReducer, applicationContextSliceName} from "./features/AppContex";
import {hallSchemaSliceName, hallSchemaSliceReducer} from './features/HallSchema';
import {formProxySliceName, formProxySliceReducer} from './features/BookingFormProxy';
import {moveBookingReducers, moveBookingSliceName} from './features/MoveBooking';
import {applicationContextReducer, applicationContextSliceName} from './features/AppContex';
import {api} from './features/api';
import {tableBookingListReducer, tableBookingListSliceName} from './features/TableBooking/slice';
import {globalSearchSliceName, globalSearchReducer} from './features/GlobalSearch';
import {relativePathApi} from "./features/api/api";

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [relativePathApi.reducerPath]: relativePathApi.reducer,
  [guestListSliceName]: guestListReducer,
  [timelineSliceName]: timelineReducer,
  [hallSchemaSliceName]: hallSchemaSliceReducer,
  [formProxySliceName]: formProxySliceReducer,
  [tableBookingListSliceName]: tableBookingListReducer,
  [moveBookingSliceName]: moveBookingReducers,
  [applicationContextSliceName]: applicationContextReducer,
  [globalSearchSliceName]: globalSearchReducer,
  [globalSearchSliceName]: globalSearchReducer,
});

const setupStore = () => configureStore({
  reducer: rootReducer,
    devTools: {
      actionsBlacklist: ["coreApi"],
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
      .concat(
        api.middleware,
        relativePathApi.middleware
      ),
  });

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']


export const store = setupStore();
