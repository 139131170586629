import {TagOption} from "types/tag";
import {useTagsOptions} from "features/api/tags";
import {useCallback, useMemo} from "react";
import {SelectTag} from "ui-kit";
import {useIntlUtils} from "../../hooks/useIntlUtils";
import {ETranslations} from "../../types/translates";
import {useIsTablet} from "ui-kit/hooks";


interface UserTagsProps{
  value: number[],
  onChange(value: number[]): void;
  placeholder?: string
}

export function UserTags({value, onChange, placeholder}: UserTagsProps) {
  const {getIntlSelectEntity} = useIntlUtils();
  const tagsOptions = useTagsOptions();

  const selected = useMemo(() => tagsOptions.filter((t) => value.includes(t.value)), [value]);

  const handleChange = useCallback((tags: TagOption[]) => {
    onChange(tags.map((t) => t.value));
  }, [onChange]);

  const isTablet = useIsTablet();

  return (
    <SelectTag
      menuPlacement={isTablet ? "top" : "bottom"}
      isTagsOrSource
      isSearchable
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
      options={tagsOptions}
      value={selected}
      // @ts-ignore
      onChange={handleChange}
      placeholder={placeholder || getIntlSelectEntity(ETranslations.AVAILABLE_TAGS)}
    />
  );
}
