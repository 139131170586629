import {useBookingsList} from 'features/api/bookings-api';
import {useSelector} from 'react-redux';
import {appContextSelectors} from 'features/AppContex';
import {useActiveStatuses, useAllStatuses} from 'features/api/dictionaries-api';
import {TUseBookingList} from 'types/booking';


export function useTablesBookings(tableId: number | undefined, isSkip: boolean ,search = '', isActiveStatuses = false) {
  const currentDate = useSelector(appContextSelectors.date);
  const {data: allStatuses} = useAllStatuses()
  const {data: activeStatuses} = useActiveStatuses();
  const allStatusesSystemName = allStatuses.map((status) => status.systemName);
  const activeStatusesSystemName = activeStatuses.map((status) => status.systemName);
  const formatDate = currentDate.format('YYYY-MM-DD')

  const bookingPayload : TUseBookingList = {
    search,
    forTables: tableId ? [tableId] : [],
    fromDate: formatDate,
    toDate: formatDate,
    includeStatuses:isActiveStatuses ? activeStatusesSystemName : allStatusesSystemName,
    isSkip
  }

  return useBookingsList(bookingPayload);
}
