import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {constant} from 'lodash';
import {Booking} from 'types/booking';
import {useDispatchActions} from '../../hooks/useDispatchActions';
import {Table} from '../../models/common';

export const MAX_ZOOM_RATIO = 1.8;
export const MIN_ZOOM_RATIO = 0.5;

export enum HallMode {
  TABLES = 'TABLES',
  MOVE_BOOKINGS_CAPTURED = 'MOVE_BOOKINGS_CAPTURED',
  REGISTRATION_TABLET = 'REGISTRATION_TABLET',
  REGISTRATION_GUESTS = 'REGISTRATION_GUESTS',
  REGISTRATION_HALL = 'REGISTRATION_HALL',
  BOOKING_TABLET = 'BOOKING_TABLET',
  BOOKING_GUEST = 'BOOKING_GUEST',
  BOOKING_HALL = 'BOOKING_HALL',
  EDIT_HALL = 'EDIT_HALL',
  TABLE_BOOKINGS_LIST = 'TABLE_BOOKINGS_LIST',
  TABLE_BOOKINGS_EDIT = 'TABLE_BOOKINGS_EDIT',
  TABLE_BOOKINGS_MOVE = 'TABLE_BOOKINGS_MOVE',
  TABLE_BOOKINGS_EDIT_GUEST = 'TABLE_BOOKINGS_EDIT_GUEST'
}

export type ActionTypeSelector = 'full' | 'disable-reg' | 'only-swap' | 'swap-captured' | 'select-table';

export type ModalActionType = {
  type: ActionTypeSelector,
  posModal: 'above' | 'below' | 'left' | 'right' | 'center',
  left: number,
  top: number,
  booking: Booking | undefined,
  table: Table;
}

export interface HallSchemaSlice {
  mode: HallMode,
  activeTables: number[],
  bookingCaptureId?: number,
  modalActionType: ModalActionType | null;
}

export const initialState: HallSchemaSlice = {
  mode: HallMode.TABLES,
  activeTables: [],
  modalActionType: null,
};

const slice = createSlice({
  name: 'hallSchemaSlice',
  initialState,
  reducers: {
    switchMode(state, action: PayloadAction<HallMode>) {
      state.mode = action.payload;
    },
    openModalAction(state, action: PayloadAction<ModalActionType>) {
      const {posModal, top, type, left, booking, table} = action.payload;
      state.modalActionType = {
        type,
        posModal,
        top: Math.ceil(top),
        left: Math.ceil(left),
        booking,
        table,
      };
    },
    closeModalAction(state) {
      state.modalActionType = null;
    },

    // deprecated
    selectTableInHall(state, action: PayloadAction<number>) {
      const table = action.payload;
      if (state.activeTables.some((t) => t === table)) {
        state.activeTables = state.activeTables.filter((it) => it !== table);
      } else {
        state.activeTables.push(table);
      }
    },
    setActiveTables(state, action: PayloadAction<Pick<HallSchemaSlice, 'activeTables'>>) {
      const {activeTables} = action.payload;
      state.activeTables = activeTables;
    },
    reset: {
      prepare: constant({payload: undefined}),
      reducer(state) {
      state.mode = HallMode.TABLES;
      state.activeTables = [];
    },
  },
  },
});

export const {name: hallSchemaSliceName, reducer: hallSchemaSliceReducer} = slice;
export const useHallSchemaActions = () => useDispatchActions(slice.actions);
