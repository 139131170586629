import {sortBy} from 'lodash';

import {IResponse, SelectModel} from '../../models/common';
import {PlaceTables} from 'models/restaurant.model';
import {useSelector} from 'react-redux';
import {appContextSelectors} from 'features/AppContex';
import {useMemo} from 'react';
import {api} from './api';

type TablesInfo = { tableId: number, tableNumber: string, busy: boolean }


export const tablesApi = api.enhanceEndpoints({
  addTagTypes: ['TableOptions'],
}).injectEndpoints({
  endpoints: (build) => ({
    getPlacesTables: build.query<PlaceTables[], number[] >({
      query: (places) => ({
        url: 'tables',
        method: 'GET',
        params: {places},
      }),
      transformResponse: (response: IResponse<PlaceTables[]>) => response.data,
    }),
    tablesOptions: build.query<TablesInfo[], {
      placeId: number,
      restaurantId: number,
      bookingDate: string,
      bookingTime: string,
      visitTime: number
    }>({
      query: ({placeId, ...body}) => ({
        url: `/v2/hall-schema/places/${placeId}`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: IResponse<TablesInfo[]>) => response.data,
      providesTags: ['TableOptions'],
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {useGetPlacesTablesQuery, useTablesOptionsQuery} = tablesApi;

const BLANK_TABLES: PlaceTables[] = [];

export function usePlacesTables() {
  const places = useSelector(appContextSelectors.selectedPlaces);
  const {data = BLANK_TABLES, currentData, ...rest} = useGetPlacesTablesQuery(places);

  const tables = useMemo(() => data
        .flatMap((t) => t.tables)
        .filter((t) => t), [data]);

  return {
    ...rest,
    tables,
  };
}


export function usePlacesTable(tableId: number | null | undefined) {
  const {tables, ...rest} = usePlacesTables();

  const table = useMemo(() => tables
        .find((t) => t.table_id === tableId), [tables, tableId]);

  return {
    ...rest,
    table,
  };
}

export function usePlacesTableTypes() {
  const {tables, ...rest} = usePlacesTables();
  const tableTypes = useMemo(() => {
    const uniqueTypes = Array.from(new Set(tables.map((t) => t.type)));
    return sortBy(uniqueTypes.map((t) => new SelectModel(t, t.toString())), 'value');
  }, [tables]);

  return {
    ...rest,
    tableTypes,
  };
}
