import {useCallback, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {groupBy, uniqBy} from "lodash";
import cn from 'classnames';
import {config} from "config";

import {Card, Collapse} from "ui-kit";

import {Booking} from "types/booking";
import {useAllStatuses} from "features/api/dictionaries-api";
import {BookingsList} from "components/BookingsList";
import {tableBookingsTableSelector} from "features/TableBooking/selectors";
import {usePlacesTable} from "features/api/tables-api";
import {useTableBookingListActions} from "features/TableBooking/slice";
import {Input} from "ui-kit";
import {useCurrentRestBookings} from "features/api/bookings-api";
import {RestaurantBookingStats} from "components/RestaurantBookingStats";

import {TableActions} from "components/hall-scheme/redux/TableBookingList/TableActions";
import styles from './HallBookingsList.module.scss'
import {useFromProxyActions} from "features/BookingFormProxy";
import {useIntl} from "react-intl";
import {ETranslations} from "../../types/translates";
import {useSlots} from '../../features/api/hallschema-api';
import {useTimelineActions} from '../../features/Timeline';
import {getBookingStartTime} from '../../utils';
import dayjs from 'dayjs';


interface HallBookingsListProps {
  onHide?: () => void;
  className?: string;
  openedByTable?: boolean;
}

export function HallBookingsList({onHide, className, openedByTable}: HallBookingsListProps) {
  const intl = useIntl();
  const {setOnlyBooking: setFormBooking, setEditMode} = useFromProxyActions();
  const {data: statuses} = useAllStatuses();
  const [search, setSearch] = useState<string | undefined>();
  const table = useSelector(tableBookingsTableSelector);
  const {table: tableData, isLoading: isTableLoading} = usePlacesTable(table);
  const {data} = useCurrentRestBookings(search || undefined, Boolean(!config.hasStatusFiltersInHallScheme && tableData?.table_id));
  const {bookings, statistics} = data || {};
  const {data: tableSlotsData} = useSlots();
  const {setTime, resetTimeShift} = useTimelineActions()

  const {reset: resetTable} = useTableBookingListActions();

  const bookingsTable = useMemo(() => {
    const bookingsData = tableSlotsData?.find(el => el.table.table_id === table)?.slots.map((el) => el.booking).
    sort((a, b) => {
      const dateA = dayjs(`${a.bookingDate} ${a.bookingTime}`).valueOf()
      const dateB = dayjs(`${b.bookingDate} ${b.bookingTime}`).valueOf()
      return dateA - dateB
    });
    return bookingsData || [];
  }, [table, tableSlotsData]);


  const bookingsByStatus = useMemo(() => {
    if (!bookings || isTableLoading) return {};
    const uniqBookings = uniqBy(data?.bookings || [], (b) => b.bookingId);

    const filteredBookings = tableData ? uniqBookings.filter(b => b.places.some(p => p.id === tableData.table_id)) : uniqBookings;

    return groupBy(filteredBookings, (b) => b.status.systemName);

  }, [bookings, tableData, isTableLoading]);

  const handleClose = useCallback(() => {
    onHide?.();
    resetTable();
    resetTimeShift();
  }, [onHide, resetTable]);

  const handleEdit = useCallback((booking: Booking) => {
    setFormBooking(booking);
    setEditMode(true);
    setTime(getBookingStartTime(booking));
   }, []);

  const onSelectBook = useCallback((book: Booking) => {
    setTime(getBookingStartTime(book));
    setFormBooking(book);
  }, [])

  const WithOutStatusFilters = useMemo(() => {
    return(
      <BookingsList
        bookings={bookingsTable}
        className={styles.bookingsList}
        withActions
        onEdit={handleEdit}
        onClick={onSelectBook}
        compact
        allowMove
        showPhone
        showStatus
      />
    )
  }, [bookingsTable,handleEdit,setFormBooking])

  const WithStatusFilters = useMemo(() => {
    return statuses.map((status) => {
      const statusBookings = bookingsByStatus[status.systemName];
      if (!statusBookings) return null;
      return (
        <Collapse
          headerClassName={styles.statusCollapse}
          initialOpen
          header={
            <span>
              {status.name}&nbsp;
              <span className={styles.bookingsCount}>{statusBookings.length}</span>
            </span>
          }
          key={status.id}
        >
          <div>
            <BookingsList
              bookings={statusBookings}
              className={styles.bookingsList}
              withActions
              onEdit={handleEdit}
              onClick={setFormBooking}
              compact
              allowMove
              showPhone
              showStatus={false}
            />
          </div>
        </Collapse>
      );
    }).filter((el) => el)
  }, [statuses, handleEdit, setFormBooking, bookingsByStatus])

  return (
    <Card onClose={tableData && !openedByTable ? resetTable : handleClose} className={cn(className, styles.bookingsCard)}>
      <Card.Header title={
        tableData?.number ? intl.formatMessage({id: ETranslations.TABLE_BOOKINGS}, {number: tableData.number}) :
          <>
            {intl.formatMessage({id: ETranslations.PLURAL_BOOKINGS_NOM})}
            <RestaurantBookingStats stats={statistics} />
          </>
        }
        />
      <Card.Content noPadding>
        <div className={styles.searchContainer}>
          <Input.Search onSearch={setSearch} />
        </div>
        <div className={styles.list}>
          {config.hasStatusFiltersInHallScheme ?
            WithStatusFilters :
            tableData?.number ? WithOutStatusFilters : WithStatusFilters
          }
        </div>
        {tableData && <TableActions table={tableData} className={styles.actions} />}
      </Card.Content>
    </Card>
  )
}
