import {minsToString} from 'common/helpers';

import moment from 'moment';
import React, {memo, ReactNode, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {Booking, BookingOrder, BookingStatus} from 'types/booking';
import {getBookingStartTime, isBooking} from 'utils';

interface VisitContextValue{
  isExpired: boolean;
  diff: number;
  diffText: string;
  isLate: boolean;
  visitPercents: number;
  inHall: boolean;
  visitTime: number,
  statusClassName: string,
}

const VisitContext = React.createContext<VisitContextValue | null>(null);

interface VisitProviderProps {
  booking: Booking | BookingOrder;
  children: ReactNode;
}

export const VisitProvider = memo(({booking, children}: VisitProviderProps) => {
  const getDiff = useCallback(() => Math.max(moment()
    .startOf('minute')
    .diff(getBookingStartTime(booking), 'minute'), 0), [booking]);

  const [diff, setDiff] = useState(getDiff);

  useEffect(() => {
    const interval = setInterval(() => {
      setDiff(getDiff());
    }, 1e3);

    return () => clearInterval(interval);
  }, [getDiff]);

  useUpdateEffect(() => {
    setDiff(getDiff());
  }, [booking, getDiff]);

  const inHall = useMemo(() => (isBooking(booking) ? booking.status.systemName === BookingStatus.IN_HALL : false), [booking]);

  const isExpired = useMemo(() => {
    if (!inHall) return false;
    return diff - booking.visitTime >= 0;
  }, [booking, diff, inHall]);


  const visitPercents = useMemo(() => {
    const {visitTime} = booking;
    if (diff <= 0) { return 0; }
    if (diff > 0 && diff < visitTime) { return (diff * 100) / visitTime; }
    if (diff >= visitTime) { return 100; }
    return 0;
  }, [booking, diff]);

  const isLate = useMemo(() => {
    if (!isBooking(booking)) return false;
    return [
      BookingStatus.CONFIRMED,
      BookingStatus.NEW,
    ].includes(booking.status.systemName) && diff > 0;
  }, [booking, diff]);

  const statusClassName = useMemo(() => {
    if (!isBooking(booking)) return '';
    return booking.status.systemName.toLowerCase();
  }, [booking])

  const diffText = useMemo(() => {
    if (isLate) {
      return minsToString(diff);
    }
    const newDiff = diff - booking.visitTime;
    return minsToString(Math.abs(newDiff));
  }, [diff, booking, isLate]);

  const visitTime = useMemo(() => {
    if (isExpired) return (diff - booking.visitTime) * -1;
    if (isLate) return diff;
    if (inHall) return booking.visitTime - diff;
    return booking.visitTime;
   }, [booking, isLate, isExpired, inHall, diff]);

  const value = useMemo(() => ({
    isExpired,
    diff,
    diffText,
    isLate,
    visitPercents,
    inHall,
    visitTime,
    statusClassName
  }), [isExpired,
    diff,
    diffText,
    isLate,
    visitPercents,
    inHall,
    visitTime,
    statusClassName
  ]);

  return <VisitContext.Provider value={value}>{children}</VisitContext.Provider>;

});

export function useVisitContext() {
  const value = useContext(VisitContext);
  if (!value) {
    throw new Error('Component is not wrapped in VisitProvider');
  }
  return value;
}
