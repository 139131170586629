import React, {useState, FC} from 'react';
import {useIntl} from 'react-intl';

import {Card, Tabs} from "../../ui-kit";
import GuestContent from "../GuestContent/GuestContent";
import GuestHistory from "../GuestHistory/GuestHistory";
import {Client} from "models/client.model";
import {TButtons} from "../GuestContent/Control/Control";
import {ETranslations} from '../../types/translates';

enum Tab {
    CARD = 'Guest card',
    HISTORY = 'History'
}

interface TProps {
    guest?: Client;
    buttons: TButtons[];
}

const GuestInfo: FC<TProps> = ({buttons, guest}) => {
    const [tab, setTab] = useState<string>(Tab.CARD);
    const intl = useIntl()

    return (
        <>
            <Card.HeaderTabs activeTab={tab} onChange={setTab}>
                <Tabs.Item title={intl.formatMessage({id: ETranslations.GUEST_CARD})} tabName={Tab.CARD}/>
                <Tabs.Item title={intl.formatMessage({id: ETranslations.BASE_HISTORY})} tabName={Tab.HISTORY}/>
            </Card.HeaderTabs>
            <Card.Content noPadding>
                    {tab === Tab.CARD && <GuestContent guest={guest} buttons={buttons}/>}
                    {guest?.client_id && tab === Tab.HISTORY && <GuestHistory client_id={guest?.client_id}/>}
            </Card.Content>
        </>
    );
};

export default GuestInfo;
