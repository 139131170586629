export const getUserLocale = () => {
  let locale = (navigator.languages || [])[0] || navigator.language || "en";
  if (locale.includes("-")) {
    locale = locale.split("-")[0];
  }
  return locale;
};

export const capitalizeString = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const declOfNum = (num: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    num % 100 > 4 && num % 100 < 20 ? 2 : cases[num % 10 < 5 ? num % 10 : 5]
    ];
};

export const toBase64 = (file: File) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});
