import {SingleOption} from 'models/common';
import moment, {Moment} from 'moment';

export function buildTimeOptions({
  bookingIntervalInMinutes,
  minTime,
  workDurationInMinutes,
  globalCurrentDate,
  selectedDate,
  skipDateCheck = false,
}: {
    bookingIntervalInMinutes: number,
    minTime: string,
    globalCurrentDate: Moment,
    selectedDate: Moment,
    workDurationInMinutes: number,
    skipDateCheck?: boolean
  }) {
  // Время начала работы ресторана
  const hallStartTime = moment(minTime, 'HH:mm:ss');
  let startScheduleOfDay = globalCurrentDate.clone()
    .set({hour: hallStartTime.hour(), minute: hallStartTime.minute(), second: hallStartTime.second()});
  // вычисляем время окончания работы ресторана
  const end = startScheduleOfDay.clone()
    .add(workDurationInMinutes, 'minutes')
    .subtract(bookingIntervalInMinutes, 'minutes');
  if (!skipDateCheck && moment().isSame(selectedDate, 'day') && moment().isAfter(startScheduleOfDay, 'minute')) {
    // Если дата сегодняшняя и время уже после того как открыт ресторан находим следущий слот в 15 минут
    // и берем его за точку отсчета
    // В противном случае используем время начала
    startScheduleOfDay = moment().isAfter(startScheduleOfDay) ? moment() : startScheduleOfDay;
    const minutes = startScheduleOfDay.minutes();
    const after15Min = minutes % 15;
    startScheduleOfDay.subtract({minutes: after15Min}).add({minutes: 15}).startOf('minute');
  }

  const options: SingleOption[] = [];

  while (startScheduleOfDay.isSameOrBefore(end)) {
    const label = startScheduleOfDay.format('HH:mm');
    options.push({label, value: label as unknown as number});
    startScheduleOfDay.add({minutes: 15});
  }
  return options;
}
