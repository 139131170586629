import React, {useEffect, useMemo} from 'react';
import {OpenScheme} from "./OpenScheme";
import {Option} from "../../../common/components/MultiSelect/multi-select";
import {FieldRenderProps, useField} from "react-final-form";
import {isArray} from "lodash";
import {HallMode, useHallSchemaActions} from "../../../features/HallSchema";
import {useSelector} from "react-redux";
import {activeTablesSelector, hallModeSelector} from "../../../features/HallSchema/selectors";
import {isBookingView, isRegView} from "../../hall-scheme/redux/HallSchemaV2/hall-helpersFn";
import {usePrevious, useUpdateEffect} from "react-use";
import {appContextSelectors} from "features/AppContex";
import {TableOption, useTablesOptions} from "hooks/useTablesOptions";
import {Button, Input, SelectCheckbox} from "ui-kit";
import {useIntl} from "react-intl";
import {ETranslations} from "../../../types/translates";
import {TABLES_TITLES} from "../../../constants";
import {useIsTablet} from '../../../hooks/useIsTablet';
import styles from './style.module.scss';
import * as select from '../../../features/BookingFormProxy/selectors';

const ChoicePerson = (props: any) => {
  const intl = useIntl();

  return <Input
    size={1}
    type="number"
    step="1"
    min="1"
    placeholder={intl.formatMessage({id: ETranslations.QUANTITY})}
    inputClassName={styles.boldText}
    {...props}
  />;
};

const sortTables = (a: TableOption, b: TableOption) => a.number.toString().localeCompare(b.number.toString(), undefined, {numeric: true});

const ChoiceTablesRedux = ({value = [], onChange, disabled, isShowTableScheme}: any) => {
  const intl = useIntl();
  const isTablet = useIsTablet();
  const {tables: options} = useTablesOptions();
  const {input: {value: bookingId}} = useField('bookingId', {subscription: {value: true}});
  const {input: {value: placeForm}} = useField('placeId', {subscription: {value: true}});

  const {switchMode, setActiveTables} = useHallSchemaActions();
  const hallMode = useSelector(hallModeSelector);
  const tables = useSelector(activeTablesSelector);
  const place = useSelector(appContextSelectors.place);
  const isEditMode = useSelector(select.isEdit);

  useUpdateEffect(() => {
    if(isEditMode && place !== placeForm){
      onChange([]);
      setActiveTables({activeTables: []});
    }
  }, [place, onChange, placeForm]);

  const showSchema = () => {
    if (isRegView(hallMode)) {
      switchMode(HallMode.REGISTRATION_HALL);
      return;
    }
    if (isBookingView(hallMode)) {
      switchMode(HallMode.BOOKING_HALL);
      return;
    }
    switchMode(HallMode.EDIT_HALL);
  };

  const handleOnChange = (e: Option<number>[] = []) => {
    onChange(e.map((t) => t.value));
    setActiveTables({activeTables: e.map((t) => t.value)});
  };
  const prevBookingId = usePrevious(bookingId);

  useEffect(() => {
    if (bookingId === prevBookingId) return;
    const activeTables = isArray(value) ? value : [];
    setActiveTables({activeTables});
  }, [value, bookingId, prevBookingId]);

  useUpdateEffect(() => {
    if (!options.length) return;
    const selected = options.filter((o) => tables.includes(o.value));
    onChange(selected.map((t) => t.value));
  }, [tables, options]);

  const selected = useMemo(() => {
    return options.filter((o) => value.includes(o.value));
  }, [value, options]);


  return (
    <>
      <SelectCheckbox
        value={selected as any}
        isDisabled={disabled}
        //@ts-ignore
        onChange={handleOnChange}
        placeholder={intl.formatMessage({id: ETranslations.BASE_NUMBER})}
        maxMenuHeight={isTablet ? 180: 220}
        titles={TABLES_TITLES}
        isClearable
        sortable
        sortingFn={sortTables}
        options={options as any}
        isValid={value?.length !== 0}
      />
      {((!disabled && isShowTableScheme) || (isTablet && !disabled)) && <OpenScheme onClick={showSchema} />}
    </>
  );
};

export const ChoiceAutoTableInputRedux = ({disabled}: { disabled?: boolean }) => {
  const intl = useIntl();
  const {tables: options} = useTablesOptions();
  const {setActiveTables} = useHallSchemaActions();
  const {
    input: {value: personValue},
  } = useField('persons');
  const {
    input: {onChange: changeTables, value},
  } = useField('tables');

  const hasTableValue = useMemo(() => (Array.isArray(value) ? !!value.length : !!value), [value]);

  const isDisabled = useMemo(() => disabled || !options?.length || !personValue || hasTableValue, [
    personValue, options, hasTableValue, disabled
  ]);

  const handleOnChange = () => {
    if (isDisabled) return;
    const [autoTable] = options.filter((it) => (it?.type || NaN) >= personValue);
    if (autoTable) {
      setActiveTables({activeTables: autoTable.value ? [autoTable.value] : []});
      changeTables([autoTable.value]);
    }
  };


  return (
    <Button
      variant='phantom'
      className="autoChoice"
      onClick={handleOnChange}
      disabled={isDisabled}
    >
      {intl.formatMessage({id: ETranslations.AUTO_CHOICE})}
    </Button>
  );
};

export const ChoicePersonsInput: React.FC<FieldRenderProps<any>> = ({input, meta, ...rest}) => (
  <ChoicePerson {...input} {...meta} {...rest} />
);

export const ChoiceTablesInputRedux: React.FC<FieldRenderProps<any>> = ({input, meta, ...rest}) => (
  <ChoiceTablesRedux {...input} {...meta} {...rest} />
);
