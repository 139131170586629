import {ReactNode} from "react";
import {capitalizeString, declOfNum} from "../../utils";

export const getTitle = (
  values: Array<any>,
  optionsLength: number,
  placeholder: ReactNode,
  entities: Array<string>,
  allTitle: string
): ReactNode => {
  const selectedCount = values.length;
  const copyEntities = [...entities];
  const entityNom = copyEntities.pop();

  if (selectedCount === 0) {
    return placeholder;
  }

  if (selectedCount === 1) {
    return values[0]?.label || placeholder;
  }
  if (selectedCount === optionsLength) {
    return capitalizeString(`${allTitle} ${entityNom}`.toLowerCase());
  }
  return `${selectedCount} ${declOfNum(selectedCount, copyEntities)}`;
};
