import {useLayoutEffect} from 'react';
import {HallSchema} from './HallSchemaV2/hall-schema';
import {HallMode, useHallSchemaActions} from '../../../features/HallSchema';
import {Card} from 'ui-kit';
import {SelectGuestFromList} from '../../../features/GuestsList/components/select-guest-from-list';
import {useFromProxyActions} from '../../../features/BookingFormProxy';
import {Client} from '../../../models/client.model';
import {isBookingView, isRegView} from './HallSchemaV2/hall-helpersFn';
import {useHWLContext} from 'common/components/HorizontalWindowLayout/hwl-context';
import {useIntl} from "react-intl";
import {ETranslations} from "../../../types/translates";

interface PopUpByModeProps{
  hallMode: HallMode;
}

export const TabletHallPopup = ({hallMode}: PopUpByModeProps) => {
  const intl = useIntl();
  const {switchMode} = useHallSchemaActions();
  const {setClient} = useFromProxyActions();
  const guestCloseHandler = () => {
    if (hallMode.startsWith('REGISTRATION')) switchMode(HallMode.REGISTRATION_TABLET);
    if (hallMode.startsWith('BOOKING')) switchMode(HallMode.BOOKING_TABLET);
    if (hallMode.startsWith('EDIT')) switchMode(HallMode.TABLE_BOOKINGS_EDIT);
    if (hallMode.startsWith('TABLE_BOOKINGS_EDIT')) switchMode(HallMode.TABLE_BOOKINGS_EDIT);
  };

  const selectGuestHandler = (guest: Client) => {
    setClient({client: guest});
    if (isRegView(hallMode)) switchMode(HallMode.REGISTRATION_TABLET);
    if (isBookingView(hallMode)) switchMode(HallMode.BOOKING_TABLET);
  };

  const {findByWindowName, popupWindow, closeWindow} = useHWLContext();

  useLayoutEffect(() => {
    const window = findByWindowName('form');
    if (![
        HallMode.REGISTRATION_GUESTS,
        HallMode.BOOKING_GUEST,
        HallMode.BOOKING_HALL,
        HallMode.REGISTRATION_HALL,
        HallMode.EDIT_HALL,
        HallMode.TABLE_BOOKINGS_EDIT_GUEST,
      ].includes(hallMode)) {
      !window.active && popupWindow(window);
    } else {
      window.active && closeWindow(window);
    }
  });

  switch (hallMode) {
    case HallMode.REGISTRATION_GUESTS:
    case HallMode.BOOKING_GUEST:
    case HallMode.TABLE_BOOKINGS_EDIT_GUEST:
      return (
        <SelectGuestFromList
          onClose={guestCloseHandler}
          onSelectGuest={selectGuestHandler}
        />
      );
    case HallMode.BOOKING_HALL:
    case HallMode.REGISTRATION_HALL:
    case HallMode.EDIT_HALL:
        return (
          <Card onClose={guestCloseHandler}>
            <Card.Header
              title={intl.formatMessage({id: ETranslations.HALL_SCHEME})}
            />
            <Card.Content noPadding>
              <HallSchema />
            </Card.Content>
          </Card>
      );
    default: {
      return null;
    }
  }
};
