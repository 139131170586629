import {useMemo} from 'react';
import moment, {Moment} from 'moment';
import {useSelector} from 'react-redux';
import {api} from './api';
import {IResponse} from 'models/common';
import {
  EditShiftStatusPayload, FetchShiftResponse, FetchShiftsListResponse,
  Shift,
  ShiftsCalendarWeekPayload,
  ShiftsRulesPayload,
  WeekShiftsResponse
} from 'types/shift';
import {appContextSelectors} from 'features/AppContex';
import {ShiftRule, VisitTime} from 'types/shifts';
import {Notification} from '../../services/notification';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import {preparedBooking} from '../BookingFormProxy/selectors';

dayjs.extend(isSameOrAfter)


export const shiftsApi = api.enhanceEndpoints({
  addTagTypes: ['shifts', 'shift', 'rules'],
})
  .injectEndpoints({
    endpoints: build => ({
      fetchShifts: build.query<WeekShiftsResponse, ShiftsCalendarWeekPayload>({
        query: (params) => ({
          url: '/shifts/calendar/week',
          method: 'GET',
          params
        }),
        transformResponse: (response: IResponse<WeekShiftsResponse>) => response.data,
        providesTags: ['shifts'],
      }),
      fetchShiftsRules:  build.query<ShiftRule[], ShiftsRulesPayload>({
        query: (params) => ({
          url: '/shifts/rules',
          params
        }),
        transformResponse: (response: IResponse<ShiftRule[]>) => response.data,
        providesTags: ['rules'],
        async onQueryStarted(id, {queryFulfilled}) {
          try {
            await queryFulfilled;
          } catch (err) {
            if (err?.error?.data?.errorCode) {
              Notification.error({
                title: err?.error?.data?.errorMessage,
              });
            }
            throw err;
          }
        },
        keepUnusedDataFor: 0
      }),
      fetchShift: build.query<Shift, number>({
        query: (id) => ({
          url: `/shifts/${id}`,
          method: 'GET',
        }),
        providesTags: ['shift'],
        transformResponse: (response: FetchShiftResponse) => response.data,
      }),
      createShift: build.mutation<FetchShiftResponse, Partial<Shift>>({
        query: (payload: Shift) => ({
          url: '/shifts/create',
          method: 'POST',
          body: payload
        }),
        invalidatesTags: ['shifts', 'Timeline', 'rules'],
        async onQueryStarted(id, {queryFulfilled}) {
          try {
            await queryFulfilled;
          } catch (err) {
            if (err?.error?.data?.errorCode) {
              Notification.error({
                title: err?.error?.data?.errorMessage,
              });
            }
            throw err;
          }
        },
      }),
      editShift: build.mutation({
        query: (payload:  {id: number, data: Partial<Shift>}) => ({
          url: `/shifts/${payload.id}`,
          method: 'PUT',
          body: payload.data
        }),
        invalidatesTags: ['shifts', 'shift', 'Timeline', 'rules'],
        async onQueryStarted(id, {queryFulfilled}) {
          try {
            await queryFulfilled;
          } catch (err) {
            if (err?.error?.data?.errorCode) {
              Notification.error({
                title: err?.error?.data?.errorMessage,
              });
            }
            throw err;
          }
        },
      }),
      deleteShift: build.mutation({
        query: (id: number) => ({
          url: `/shifts/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['shifts']
      }),
      editShiftStatus: build.mutation({
        query: (payload: EditShiftStatusPayload) => ({
          url: `/shifts/active/${payload.id}`,
          method: 'PUT',
          params: {
            active: payload.active
          }
        })
      }),
      fetchShiftsList : build.query<FetchShiftsListResponse, number>({
        query: (restaurantId: number) => ({
          url: `/shifts/list/${restaurantId}`,
          method: 'GET',
        }),
        providesTags: ['shifts'],
      })
    })
  });

export const {
  useFetchShiftsQuery,
  useFetchShiftQuery,
  useLazyFetchShiftQuery,
  useFetchShiftsRulesQuery,
  useCreateShiftMutation,
  useEditShiftMutation,
  useDeleteShiftMutation,
  useEditShiftStatusMutation,
  useFetchShiftsListQuery,
} = shiftsApi;


const DEFAULT_RULES: ShiftRule[] = [];

export function useShiftRules() {
  const place_id = useSelector(appContextSelectors.place);
  const date = useSelector(appContextSelectors.date);
  const {restaurant_id} = useSelector(appContextSelectors.restaurant);

  const {data = DEFAULT_RULES, ...rest} = useFetchShiftsRulesQuery({
    place_id, restaurant_id, date: date.format("YYYY-MM-DD")
  });
  return {
    ...rest,
    rules: data,
  }
}

export function useShiftTimes() {
  const {rules, isFetching} = useShiftRules();

  const visitTimes = useMemo(() => {
    let times: VisitTime = {};
    rules.forEach(rule => {
      const copy = {...rule.absolute_times};
      const keys = Object.keys(copy).splice(-2);
      //Убираем последние два слота. Так как там время 0 и 15минут. Минимальное время брони 30.
      keys.forEach(key => delete copy[key])
      times = Object.assign(times, copy)
    })
    return times;
  }, [rules])

  return {visitTimes, isFetching}
}


export function useShiftTimesOptions(allowValueFromOutsideRange = false ) {
  const now = useMemo(dayjs, []);

  const {visitTimes, isFetching} = useShiftTimes();

  let timeOptions = Object.keys(visitTimes).map(Number);
  // const lastTimestamp = timeOptions[timeOptions.length - 1];
  // const timeSeparator = dayjs(lastTimestamp).set('hour', -23);
  //
  // timeOptions = timeOptions.filter((time) => {
  //   return dayjs(time).isAfter(timeSeparator)
  // })

  if(!allowValueFromOutsideRange){
    timeOptions = timeOptions.filter((time) => dayjs(time).isSameOrAfter(now, 'minute'))
  }

  const options =  timeOptions.map((time) => ({value: time, label: dayjs(time).format('HH:mm')}));

  return {options, isFetching}
}


export function useActiveShift(time: Moment) {
  const {rules} = useShiftRules();
  return rules.find((rule) => {
    const start = moment(rule.low_bounder, "HH:mm:ss");
    const end = moment(rule.high_bounder, "HH:mm:ss");

    const ruleStart = time.clone().set({
      hour: start.hour(),
      minute: start.minute(),
      second: start.second()
    })

    const ruleEnd = time.clone().set({
      hour: end.hour(),
      minute: end.minute(),
      second: end.second()
    }).add(end.isSameOrBefore(start) ? 1 : 0, 'days');

    return time.isSameOrAfter(ruleStart) && time.isSameOrBefore(ruleEnd);
  })
}
