import React from 'react';
import {Spinner} from 'ui-kit';
import {ScheduleLanding as ScheduleLandingComponent} from 'components/schedule-landing/schedule-landing';
import {usePageId} from 'contexts/LayoutContext';

export function ScheduleLanding() {

  usePageId('scheduler-landing');



  return (
    <React.Suspense fallback={<Spinner />}>
      <ScheduleLandingComponent />
    </React.Suspense>
  );
}
