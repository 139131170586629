import {FunctionComponent, useCallback} from 'react';
import {BookingCard} from '../BookingCard';
import {Booking} from 'types/booking';
import BookingHistoryLog from './BookingHistoryLog';
import './bookingHistory.scss';


interface BookingHistoryProps {
  booking: Booking;
  isExpanded?: boolean
  onClick?: (bookingId: Booking) => void;
}

export const BookingHistory: FunctionComponent<BookingHistoryProps> = ({booking, isExpanded, onClick}) => {
  const bookingClickHandler = useCallback(() => onClick?.(booking), [booking, onClick]);
  return (
    <div className="bookingHistory">
      <div className="bookingWrapper">
        <BookingCard booking={booking} onClick={bookingClickHandler} compact />
      </div>
      {isExpanded ? (<BookingHistoryLog id={booking.bookingId} />) : null}
    </div>
  );
};
