import {
  Children,
  cloneElement,
  isValidElement,
  memo,
  ReactElement,
  useCallback,
  useMemo,
} from "react";
import cn from "classnames";
import {Button} from "ui-kit";
import {useDrag} from "react-dnd";

import {ICONS} from "common/helpers";
import {Booking, BookingOrder} from "types/booking";
import styles from "./BookingCard.module.scss";
import {Tags} from "components/Tags";
import {BookingClientInfo} from "./BookingClientInfo";
import {BookingStatus as Status} from "./BookingStatus";
import {formatPhone, isBooking, isBookingOrder, stopPropagation} from "utils";
import {BookingVisitInfo} from "./BookingVisitInfo";
import {useVisitContext, VisitProvider} from "./VisitContext";
import {ClientInfoPopup} from "components/BookingInfoPopup";
import {EDraggableEntity} from "../../constants";
import {config} from "../../config";

export interface BookingCardViewProps {
  compact?: boolean;
  withDate?: boolean;
  showPhone?: boolean;
  showStatus?: boolean;
}

export interface BookingCardProps<T extends Booking | BookingOrder> extends BookingCardViewProps {
  booking: T;
  active?: boolean;
  onClick?: (booking: T) => void;
  className?: string;
  actions?: ReactElement<{ className?: string }> | Array<ReactElement<{ className?: string }>>;
}

const Card = <T extends Booking | BookingOrder>({
                                                  booking,
                                                  active,
                                                  onClick,
                                                  actions,
                                                  className,
                                                  compact,
                                                  withDate,
                                                  showPhone = true,
                                                  showStatus = true,
                                                }: BookingCardProps<T>) => {
  const [, drag, dragPreview] = useDrag(() => ({
    type: EDraggableEntity.BOOKING_CARD,
    item: booking,
    canDrag: config.dragAndDrop,
  }), [booking]);

  const handleClick = useCallback(() => onClick?.(booking), [onClick, booking]);

  const {isExpired, isLate, statusClassName} = useVisitContext();

  const label = useMemo(() => (isBookingOrder(booking) ?
    ["order", booking.orderId] : ["booking", booking.bookingId]).join(" "), [booking]);

  const phone = useMemo(() => formatPhone(booking.client.phone), [booking]);

  return (
    <>
      <div
        ref={drag}
        aria-label={label}
        onClick={handleClick}
        className={cn(styles.bookingCard, styles[statusClassName], className, {
          [styles.active]: active,
          [styles.interactive]: !!onClick,
          [styles.expired]: isExpired,
          [styles.late]: isLate,
          [styles.noStatusBorder]: !showStatus,
        })}
      >
        <div className={styles.content}>
          {isBooking(booking) && !compact && (
            <div className={cn(styles.section, styles.statusGroup)}>
              {showStatus && <Status status={booking.status} />}
              {booking.useDeposit && <ICONS.DepositLock />}
            </div>
          )}
          <div className={styles.section}>
            <BookingClientInfo client={booking.client} ref={dragPreview} />
            {isBooking(booking) && compact && (
              <div className={styles.compactStatusGroup}>
                {booking.useDeposit && <ICONS.DepositLock />}
                {showStatus && <Status status={booking.status} />}
              </div>
            )}
          </div>
          {Boolean(showPhone && phone || (isBooking(booking) && booking.tags.length) || booking.source) && (
            <div className={styles.section}>
              <div className={styles.subSection}>
                <span className={styles.phone}>{phone}</span>
                {isBooking(booking) && Boolean(booking.tags.length) && <Tags tags={booking.tags} />}
              </div>
              {Boolean(booking.source) && (
                <div className={styles.subSection}>
                  <ICONS.Source />
                  <span className={styles.source_name}>{booking.source?.source_name}</span>
                </div>
              )}
            </div>
          )}
          <BookingVisitInfo
            bookingTime={booking.bookingTime}
            places={booking.places}
            persons={booking.persons}
            isOverbooking={isBooking(booking) ? booking.isOverbooking : false}
            comment={booking.comment}
            compact={compact}
            date={withDate ? booking.bookingDate : undefined}
          />
        </div>
        <div className={styles.actions} onClick={stopPropagation}>
          {Children.map(actions, (el) => {
            if (isValidElement(el)) {
              return cloneElement(el, {className: cn(el.props.className, styles.action)});
            }
            return null;
          })}
          <ClientInfoPopup clientId={booking.client.client_id} placement="auto">
            <Button
              variant="phantom"
              type="button"
              className={styles.userInfo}
            >
              <ICONS.Question />
            </Button>
          </ClientInfoPopup>
        </div>
      </div>
    </>
  );
};

export const BookingCard = memo((props: BookingCardProps<Booking | BookingOrder>) => (
  <VisitProvider booking={props.booking}>
    <Card {...props} />
  </VisitProvider>
)) as typeof Card;
