import {memo, useMemo} from 'react';
import {Status} from 'types/booking';
import {Tag} from "ui-kit";
import {useVisitContext} from './VisitContext';

import styles from './BookingStatus.module.scss';
import {useIntl} from "react-intl";
import {ETranslations} from "../../types/translates";

interface BookingStatusProps {
  status: Status
}

export const BookingStatus = memo(({status}: BookingStatusProps) => {
  const intl = useIntl()
  const {isExpired, isLate} = useVisitContext();

  const label = useMemo(() => {
    if (isExpired) return  intl.formatMessage({id: ETranslations.OUTSTAY});
    if (isLate) return intl.formatMessage({id: ETranslations.LATE});
    return status.name;
  }, [isExpired, isLate, status.name]);

  return <Tag label={label} className={styles.bookingStatus} />;
});
