import {skipToken} from "@reduxjs/toolkit/dist/query";
import {Button, Spinner} from "ui-kit";
import {ICONS} from "common/helpers";
import {Menu} from "components/Menu/Menu";
import {Popup, PopupRef, PopupProps} from "components/Popup";

import {useChangeBookingStatus, useGetBookingStatusQuery, usePrintBookingInfo} from "features/api/bookings-api";
import {useMoveBookingActions} from "features/MoveBooking";
import {useTimelineActions} from "features/Timeline";
import {useBooleanState} from "hooks/useBooleanState";
import {useRecoveryBooking} from "hooks/useRecoveryBooking";
import {useCallback, useMemo, useRef} from "react";
import {Booking} from "types/booking";
import {getBookingStartTime, isBookingCanBeCanceled} from "utils";
import {config} from "config";

import styles from "./BookingActions.module.scss";
import {CancelActions} from "./CancelActions";
import {useIntlUtils} from "../../hooks/useIntlUtils";
import {ETranslations} from "../../types/translates";
import {ConfirmBusyTable} from "components/ConfirmBusyTable";

const RESET_PADDING = {top: true, bottom: true};

type BookingActionsProps = {
  className?: string;
  booking: Booking;
  onEdit?: (booking: Booking) => void;
  allowMove?: boolean;
} & Pick<PopupProps, "placement">

export function BookingActions({
                                 className,
                                 booking,
                                 onEdit,
                                 allowMove,
                                 placement = "auto-start",
                               }: BookingActionsProps) {
  const {intl} = useIntlUtils();
  const {recovery} = useRecoveryBooking(booking);

  const [print] = usePrintBookingInfo(booking.bookingId);

  const [wasOpen, setWasOpen] = useBooleanState(false);
  const popup = useRef<PopupRef>(null);

  const {data: status, isLoading} = useGetBookingStatusQuery(wasOpen ? booking.bookingId ?? skipToken : skipToken);
  const {force, nextStatus: goNextStatus, terminalStatus, resetForce} = useChangeBookingStatus(booking.bookingId);
  const handleEdit = useCallback(() => {
    onEdit?.(booking);
    popup.current?.close();
  }, [booking, onEdit]);

  const nextStatus = useMemo(() => status?.next || status?.terminal, [status]);

  const handleStatusChange = useCallback(async () => {
    try {
      popup.current?.close();
      // eslint-disable-next-line no-unused-expressions
      status?.next ? goNextStatus() : terminalStatus();
    } catch {
      //
    }
  }, [status]);

  const {selectMoveSource, selectSourceTableNumber} = useMoveBookingActions();
  const tableNumber = booking.places.map((place) => place.number);
  const {setTime} = useTimelineActions();
  const handleMoveClick = useCallback(() => {
    setTime(getBookingStartTime(booking));
    booking.bookingId && selectMoveSource(booking.bookingId);
    selectSourceTableNumber(tableNumber);
  }, [booking]);

  const [isCancel, show, hide] = useBooleanState(false);

  const handlePrint = useCallback(() => {
    print();
    popup.current?.close();
  }, [print]);

  const popupContent = useMemo(() => {
    if (isLoading) return <Spinner />;
    if (isCancel) return <CancelActions back={hide} booking={booking} close={popup.current?.close} />;
    return (
      <Menu className={styles.menu}>
        {onEdit && (
          <Menu.Item icon={ICONS.Edit} onClick={handleEdit}>
            {intl.formatMessage({id: ETranslations.BASE_EDIT})}
          </Menu.Item>
        )}
        {allowMove && (
          <Menu.Item icon={ICONS.Move} onClick={e => {
            e.stopPropagation();
            handleMoveClick();
          }}>
            {intl.formatMessage({id: ETranslations.BASE_RESEAT})}
          </Menu.Item>
        )}
        {nextStatus && (
          <Menu.Item icon={ICONS.Check} onClick={e => {
            e.stopPropagation();
            handleStatusChange();
          }}>
            {nextStatus.name}
          </Menu.Item>
        )}
        <Menu.Item onClick={e => {
          e.stopPropagation();
          recovery();
        }} icon={ICONS.Recovery}>
          {intl.formatMessage({id: ETranslations.BASE_RESTORE})}
        </Menu.Item>
        {config.isPrintAvailable && (
          <Menu.Item onClick={e => {
            e.stopPropagation();
            handlePrint();
          }} icon={ICONS.Printer}>
            {intl.formatMessage({id: ETranslations.BASE_PRINT})}
          </Menu.Item>
        )}
        {isBookingCanBeCanceled(booking) && (
          <Menu.Item
            onClick={e => {
              e.stopPropagation();
              show();
            }}
            icon={ICONS.Cancel}
            className={styles.cancel}
          >
          <span className={styles.cancelContent}>
            {intl.formatMessage({id: ETranslations.BASE_CANCEL})}
            <ICONS.NextIcon />
          </span>
          </Menu.Item>
        )}
      </Menu>
    );
  }, [isLoading, isCancel, hide, booking, onEdit, handleEdit, allowMove, handleMoveClick, nextStatus, handleStatusChange, recovery, show]);

  return (
    <>
      <Popup placement={placement} ref={popup} onClose={hide} onOpen={setWasOpen}>
        <Popup.Trigger>
          <Button variant="phantom" className={className}>
            <ICONS.ActionsMenu />
          </Button>
        </Popup.Trigger>
        <Popup.Content noPadding={RESET_PADDING}>
          {popupContent}
        </Popup.Content>
      </Popup>
      <ConfirmBusyTable isOpen={force} confirm={goNextStatus} decline={resetForce} />
    </>
  );
}
