import React, {useMemo} from "react";
import {OrderControls} from "./FormControls/OrderControls";
import {useSelector} from "react-redux";
import {disableFormFieldSelector, orderTrustSelector} from "../../../features/BookingFormProxy/selectors";
import {useAppUsers} from "../../../contexts/AppUsersContext";
import {sourcesSelector} from "../../../features/AppContex/selectors";
import cn from "classnames";
import {BottomBar, Labeled} from "ui-kit";
import {requiredField} from '../validators/validators';
import {Field} from "react-final-form";
import {ChoiceDateInput, ChoiceTimeInput} from "../sub-renders/date-time";
import {ChoiceClientPhoneInputRedux} from "../sub-renders/phone";
import {ChoiceClientInfoRedux} from "../sub-renders/client";
import {ChoiceVisitTimeControls} from "../sub-renders/visit-time";
import {
  ChoiceAutoTableInputRedux,
  ChoicePersonsInput,
  ChoiceTablesInputRedux,
} from "../sub-renders/location";
import {ChoiceNotesInput} from "../sub-renders/note";
import {ChoiceTagsInput} from "../sub-renders/tags";
import {ChoiceSourceInput} from "../sub-renders/source";
import {ChoiceDepositInput} from "../sub-renders/deposit";
import {ChoiceHostessInput} from "../sub-renders/hostess";
import {Edit} from "./FormControls/Edit";
import {BookingOrder} from "../../../types/booking";
import {PlaceSelect} from "components/PlaceSelect";
import {useIntlUtils} from "../../../hooks/useIntlUtils";
import {ETranslations} from "../../../types/translates";


import styles from "./Form.module.scss";
import {capitalizeString} from '../../../utils';

export const CreateFromOrder: React.FC<any> = ({values, handleSubmit, valid, rest}) => {
  const {
    intl,
    getIntlJoinedParts,
    getIntlNumberOf,
    isRussianLocale,
    getIntlBaseErrorNotSelected,
    getIntlTimeOf,
  } = useIntlUtils();

  const intlTitles = useMemo(() => ({
    tableNumber: getIntlNumberOf(isRussianLocale ? ETranslations.PLURAL_TABLES_ALT : ETranslations.PLURAL_TABLE),
  }), []);

  const order = useSelector(orderTrustSelector);
  const disable = useSelector(disableFormFieldSelector);

  const {userList} = useAppUsers();
  const sources = useSelector(sourcesSelector);
  // use memo for values, to return same references of values, this fix infinity update loop
  return (
    <>
        <form className={cn(styles.form, "custom-scroll")}>
            <Labeled
              label={getIntlJoinedParts([ETranslations.BASE_DATE, ETranslations.AND, ETranslations.BASE_TIME])}
              required
              as="div"
            >
              <div className={styles.dateTime}>
                <Field
                  name="date"
                  component={ChoiceDateInput}
                  validate={(value) => requiredField(value, "Дата не указана")}
                  disabled={disable}
                />
                <ChoiceTimeInput
                  disabled={disable}
                  allowValueFromOutsideRange
                />
              </div>
            </Labeled>
            <Field
              name="phone"
              component={ChoiceClientPhoneInputRedux}
              autoFocus
              required
              label={intl.formatMessage({id: ETranslations.PHONE})}
              className={cn("phone book add required", {hasValue: true})}
              disabled={disable}
              validate={(value) => !value}
            />
            <Field
              name="client"
              component={ChoiceClientInfoRedux}
              disable
              isCreateBooking
            />
            <Labeled
              className={styles.fullRow}
              label={getIntlTimeOf(isRussianLocale ? ETranslations.PLURAL_VISITS_ALT : ETranslations.PLURAL_VISIT)}
              as='div'
            >
              <div className={styles.visitTime}>
                <Field
                  name="visit_time"
                  component={ChoiceVisitTimeControls}
                  disabled={disable}
                />
              </div>
            </Labeled>
            <div className={cn(styles.fullRow, styles.location)}>
              <Field
                name="persons"
                component={ChoicePersonsInput}
                label={capitalizeString(intl.formatMessage({id: ETranslations.PLURAL_GUESTS_NOM}))}
                validate={(value) => requiredField(value, intl.formatMessage({id: ETranslations.ERROR_REQUIRED_FIELD}))}
                required
                className={cn("persons", {hasValue: !requiredField(values.persons)})}
                disabled={disable}
              />
              <Labeled
                required
                label={getIntlNumberOf(isRussianLocale ? ETranslations.PLURAL_HALLS_ALT : ETranslations.PLURAL_HALL)}
              >
                <PlaceSelect disabled={disable} />
              </Labeled>
              <Labeled required label={intlTitles.tableNumber}>
                <div className="openSchemeContainer">
                  <Field
                    name="tables"
                    component={ChoiceTablesInputRedux}
                    validate={(value) => requiredField(value, getIntlBaseErrorNotSelected(intlTitles.tableNumber))}
                    disabled={disable}
                    isShowTableScheme
                  />
                </div>
              </Labeled>
              <ChoiceAutoTableInputRedux disabled={disable} />
            </div>
            <Field
              name="comment"
              component={ChoiceNotesInput}
              label={getIntlJoinedParts([ETranslations.COMMENT, ETranslations.FOR_BOOKING])}
              className={styles.fullRow}
              disabled={disable}
            />
            <Labeled label={intl.formatMessage({id: ETranslations.RESERVATION_TAGS})}>
              <Field
                name="tags"
                component={ChoiceTagsInput}
                disabled={disable}
              />
            </Labeled>
            <Labeled label={intl.formatMessage({id: ETranslations.SOURCE})}>
              <Field
                name="source"
                component={ChoiceSourceInput}
                options={sources}
                disabled={disable}
              />
            </Labeled>
            <Labeled label={intl.formatMessage({id: ETranslations.DEPOSIT})}>

              <Field
                name="deposit"
                component={ChoiceDepositInput}
                disabled={disable}
              />
            </Labeled>
            <Labeled required label={intl.formatMessage({id: ETranslations.HOSTESS})}>
              <Field
                name="hostess"
                component={ChoiceHostessInput}
                options={userList}
                disabled={disable}
              />
            </Labeled>
            <Field name="rest" initialValue={rest}>
              {({input}) => (
                <input type="hidden" {...input} />
              )}
            </Field>
        </form>
        <BottomBar>
          <BottomBar.Part placement="left">
            <OrderControls order={order as BookingOrder} submit={handleSubmit} valid={valid} />
          </BottomBar.Part>
          <BottomBar.Part placement="right">
            <Edit showSave={false} />
          </BottomBar.Part>
        </BottomBar>
    </>
  );
};
