import {ReactNode, useMemo, useRef} from 'react';
import {appContextSelectors} from 'features/AppContex';
import {useSelector} from 'react-redux';
import {useFormState} from 'react-final-form';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {usePlacesTables, useTablesOptionsQuery} from 'features/api/tables-api';

export interface TableOption {
  value: number;
  number: string | number;
  type: number;
  label: ReactNode;
}


export function useTablesOptions(): { isLoading: boolean, tables: TableOption[]} {
  const errorRef = useRef(false);
  const rest = useSelector(appContextSelectors.restaurant);
  const date = useSelector(appContextSelectors.date);
  const placeId = useSelector(appContextSelectors.place);
  const {values} = useFormState({subscription: {values: true}});


  const bookingTime = values.time?.label;
  const  {visit_time: visitTime} = values;

  const {data: tablesData, isFetching, isError} = useTablesOptionsQuery(!errorRef.current && placeId && bookingTime && visitTime ? {
    placeId,
    bookingDate: date.format('YYYY-MM-DD'),
    bookingTime,
    visitTime,
    restaurantId: rest.restaurant_id,
  } : skipToken);

  // Ошибка происходит когда нет шифта на дату. Если она случилась, при монтировании дальнейшие запросы не отправляются.
  // В противном случае ртк зацикливается и шлет запросы.
  // Проблема https://github.com/reduxjs/redux-toolkit/issues/1526. Решается обновлением ртк. Либо таким фиксом.
  if (isError){
    errorRef.current = isError
  }

  const {tables} = usePlacesTables();

  const tablesTypes = useMemo(() => tables.reduce<Record<number, number>>((acc, t) => {
    acc[t.table_id] = t.type;
    return acc;
  }, {}), [tables]);


  const options = useMemo(() => {
    let data;

    if (!tablesData || isError){
      // @ts-ignore
      data = tables.filter((el) => el.placeId === placeId).map(({table_id, number, type}) => ({
        value: table_id,
        number: number,
        type: type,
        label: <span>{number}</span>,
      }));
    } else {
      data = tablesData.map(({busy, tableId, tableNumber}) => ({
        value: tableId,
        number: tableNumber,
        type: tablesTypes[tableId],
        label: <span style={{color: busy ? '#CC5962' : undefined}}>{tableNumber}</span>,
      }))
    }

    return data.sort((a,b) => Number(a.number) - Number(b.number))
  }, [tablesData, tables, isError, placeId]);

  return {tables: options, isLoading: isFetching};
}
