import React from "react";
import {FieldRenderProps} from "react-final-form";
import {Textarea} from "ui-kit";
import {useIntlUtils} from "../../../hooks/useIntlUtils";
import {ETranslations} from "../../../types/translates";

export const ChoiceNotesInput: React.FC<FieldRenderProps<string, any>> = ({input, meta, ...rest}) => {
  const {getIntlEnterEntity} = useIntlUtils();

  return (
    <Textarea
      maxLength={250}
      {...input}
      {...rest}
      placeholder={getIntlEnterEntity(ETranslations.TEXT)}
    />
  );
};
