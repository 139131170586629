import {memo, useMemo} from 'react';
import moment from 'moment';
import {useIntl} from "react-intl";
import cn from 'classnames';
import {ICONS, minsToString} from 'common/helpers';
import {ProgressRing} from 'components/ProgressRing';
import {Place} from 'types/place';

import {useVisitContext} from './VisitContext';
import {ETranslations} from "../../types/translates";
import styles from './BookingVisitInfo.module.scss';

export interface BookingVisitInfoProps{
  bookingTime: string;
  date?: string;
  persons: number;
  places: Place[] | Place;
  isOverbooking: boolean | undefined;
  comment?: string;
  compact?: boolean;
}

export const BookingVisitInfo = memo(({
  date, bookingTime, places: rawPlaces, persons, isOverbooking, comment, compact,
}: BookingVisitInfoProps) => {
  const intl = useIntl()
  const places = useMemo(() => (Array.isArray(rawPlaces) ? rawPlaces : [rawPlaces]), [rawPlaces]);
  const hallTitle = intl.formatMessage({id: ETranslations.PLURAL_HALL})

  const hallName = useMemo(() => Array.from(new Set(
    places.map(({place: {name}}) => (name.toLowerCase()=== hallTitle ? name : name.toLowerCase().replace(hallTitle, ''))),
)).join(', '),
    [places]);

  const {isExpired, isLate, inHall, visitPercents, visitTime} = useVisitContext();

  const showProgress = isExpired || isLate || inHall;

  return (
    <div className={styles.container}>
      <div className={cn(styles.bookingVisitInfo, {[styles.withOverbooking]: isOverbooking})}>
        <div className={styles.info}>
          {date && (
            <div className={styles.blockWithIcon}>
              <ICONS.Calendar />
              <span>{moment(date).format('L')}</span>
            </div>
          )}
          <div className={styles.time}>
            {showProgress ? <ProgressRing percent={visitPercents} /> : <ICONS.Clock />}
            <span>
              {bookingTime}
              &nbsp;|&nbsp;
              {minsToString(visitTime)}
            </span>
          </div>
          <div className={styles.blockWithIcon}>
            <ICONS.People />
            <span>{persons}</span>
          </div>
          <div className={styles.blockWithIcon}>
            <ICONS.Seat />
            <div className="cont">
              {places.map((p) => p.number).sort((a, b) => +a - +b).join(', ')}
            </div>
          </div>
          <div className={cn(styles.blockWithIcon, styles.transparent)}>
            <ICONS.Hall />
            <span>{hallName}</span>
          </div>
          {compact && Boolean(comment) && (
            <div className={cn(styles.blockWithIcon, styles.transparent, styles.commentShortContainer)}>
              <ICONS.Comment />
              <span className={styles.commentShort}>{comment}</span>
            </div>
          )}
        </div>
        {!compact && Boolean(comment) && (
          <div className={cn(styles.comment, styles.transparent)}>{comment}</div>
        )}
      </div>
      {isOverbooking && (
        <div className={styles.overbookingHint}>
          <ICONS.Info />
          {intl.formatMessage({id: ETranslations.BOOKING_OVERLAP})}
        </div>
      )}
    </div>
  );
});
