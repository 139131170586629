import React, {useEffect, useState} from 'react';
import {useForm, useFormState} from 'react-final-form';


import {BookingStatus} from 'services/bookings.service';
import * as select from 'features/BookingFormProxy/selectors';
import {useSelector} from 'react-redux';
import {ICONS} from 'common/helpers';

import styles from './Prolongate.module.scss';
import {RadioButton} from 'ui-kit';
import {useIntl} from "react-intl";
import {ETranslations} from "../../../../types/translates";
import {declOfNum} from "../../../../utils";
import {HOURS_TITLES, MINUTES_TITLES} from "../../../../constants";

enum ProlongateTime {
  Half = 30,
  Hour = 60
}

export function Prolongate() {
  const intl = useIntl();
  const {submitting, invalid} = useFormState();
  const isEdit = useSelector(select.isEdit);
  const booking = useSelector(select.selectBooking);
  const {change, submit} = useForm();
  const [prolongationTime, setProlongationTime] = useState<ProlongateTime>();

  useEffect(() => {
    const {visitTime} = booking;
    if (!prolongationTime || !visitTime) return;
      change('visit_time', visitTime + prolongationTime);
  }, [prolongationTime, booking]);


  if (booking.status?.systemName !== BookingStatus.IN_HALL || isEdit) return null;

  return (
    <div className={styles.container}>
      <span>
        {intl.formatMessage({id: ETranslations.PROLONG})}:
      </span>
        <div className={styles.prolongationBlock}>
          <RadioButton value={prolongationTime} onChange={setProlongationTime}>
            <RadioButton.Button
              value={ProlongateTime.Half}>
              30 {intl.formatMessage({id: declOfNum(30, MINUTES_TITLES)})}
            </RadioButton.Button>
            <RadioButton.Button value={ProlongateTime.Hour}>
              1 {intl.formatMessage({id: declOfNum(1, HOURS_TITLES)})}
            </RadioButton.Button>
          </RadioButton>
          <button
            className={styles.checkProlongation}
            disabled={
              !prolongationTime
              || invalid
              || submitting
            }
            type="submit"
            onClick={submit}
          >
            <img src={ICONS.checkTick} alt="" />
          </button>
        </div>
    </div>
);
}
