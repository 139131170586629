import cn from 'classnames';

import HallLegendModal from 'components/modals/HallLegendModal';
import {Button} from 'ui-kit';


import {useBooleanState} from 'hooks/useBooleanState';
import {ICONS} from '../../../../common/helpers';

import styles from './Hall.module.scss';

export const HallLegend = () => {
  const [isModalOpen, open, close] = useBooleanState();
  return (
    <>
      <Button variant="phantom" className={cn(styles.legend, {[styles.legend_active]: isModalOpen})} onClick={open}>
        <ICONS.LegendIcon />
      </Button>
      <HallLegendModal isOpen={isModalOpen} onClose={close} />
    </>
);
  };
