import React, {useEffect} from "react";
import {useMount, useUpdateEffect} from 'react-use';
import {FieldRenderProps, useField} from "react-final-form";
import {Option} from "../../../models/common";
import {useHWLContext} from "common/components/HorizontalWindowLayout/hwl-context";
import {useSelector} from "react-redux";
import {timelineSelectors, useTimelineActions} from "features/Timeline";
import {useUnmount} from "react-use";
import {DatePicker, SelectBasic} from "ui-kit";
import {useIntl} from "react-intl";
import {ETranslations} from "../../../types/translates";
import styles from "./style.module.scss";
import {useShiftTimesOptions} from "features/api/shifts";
import {appContextSelectors, useApplicationContextActions} from 'features/AppContex';
import dayjs from 'dayjs';

type DateProps = FieldRenderProps<Date, any>;


export const ChoiceTimeInput = ({
                                  disabled,
                                  allowValueFromOutsideRange,
                                }: { disabled?: boolean, allowValueFromOutsideRange?: boolean }) => {
  const {input} = useField<Option<string> | null>("time", {allowNull: true});
  const {input : {value: dateValue}} = useField<string| null>("date", {allowNull: true});
  const {value, onChange} = input;
  const intl = useIntl();
  const {options, isFetching} = useShiftTimesOptions(allowValueFromOutsideRange);
  const startHour = useSelector(timelineSelectors.startHour);
  const {setIsActualTime, resetTimeShift, setTimestamp} = useTimelineActions();
  const {findByWindowName} = useHWLContext() || {};

  // Хук для обратной совместимости
  // Изначально с бека приходит время в формате HH:mm строкой
  // В доступных опциях по шифту приходит абсолютное время.
  // При первом рендере меняем строку на таймстамп.
  useEffect(() => {
    if(typeof value?.value === 'string' && dateValue){
      const date = dayjs(dateValue)
      const time = dayjs(value.value, 'HH:mm');
      const hour = time.hour();
      const minute = time.minute();
      const timestamp = date.set('hour', hour).set('minute', minute).valueOf();
      onChange({...value, value: timestamp})
    }
  }, [value])


  useMount(() => {
    try {
      const window = findByWindowName("reg");
      if (!window.active) return;
    } catch {
      //
    }
  });

  useUnmount(resetTimeShift);

  useEffect(() => {
    if (value?.value) {
      const {value: timestampString} = value;
      const timestamp = Number(timestampString);
      setIsActualTime(false);
      setTimestamp(timestamp)
    }
  }, [value?.value, startHour]);

  useEffect(() => {
    if (disabled || allowValueFromOutsideRange || isFetching) return;
    const hasValue = options.some((o) => Number(o.value) === Number(value?.value));

    if (!value?.value || !hasValue) {
      const nextValue = options[0];
      onChange(nextValue || null);
    }
  }, [options, disabled, allowValueFromOutsideRange, isFetching]);

  return (
    <SelectBasic
      value={value}
      changedMaxSize
      options={options}
      onChange={onChange}
      isDisabled={disabled}
      isValid={!!value?.value}
      placeholder={intl.formatMessage({id: ETranslations.BASE_TIME})}
    />
  );
};

const ChoiceDate = ({value, onChange, disabled, minDate = new Date()}: any) => {
  const date = useSelector(appContextSelectors.date);
  const {input : {onChange: changeTime, value : timeValue}} = useField<Option<string> | null>("time", {allowNull: true});
  const {setIsActualTime, setTimestamp} = useTimelineActions()
  const {setDate} = useApplicationContextActions();


  // Хук для синхронизации глобальной даты с датой формы
  useUpdateEffect(() => {
    if(disabled) return
    const valueDate = dayjs(value).startOf('day');
    const selectedDate = dayjs(date.toDate())
    const diffDays = selectedDate.diff(valueDate, 'day')

    // Устанавливаем актуальное время для следующего дня. Иначе установится первое доступное на новый день.
    if(typeof timeValue?.value === 'number'){
      const time = dayjs(timeValue.value);
      const timestamp = time.add(diffDays, 'day').valueOf();
      changeTime({...timeValue, value : timestamp})
    }

    if(valueDate.isSame(selectedDate)) return;
    onChange(date.toDate())

  }, [date])

  const datePickerHandler = (newDate: Date) => {
    const now = dayjs(value)
    const selectedDate = dayjs(newDate);
    const diffDays = selectedDate.diff(now, 'days')

    const newTimestamp = dayjs(timeValue?.value).add(diffDays, 'day')

    setDate(selectedDate.toDate())
    setTimestamp(newTimestamp.valueOf())
    setIsActualTime(false)
  }


  return (
    <DatePicker
      selected={value}
      minDate={minDate}
      disabled={disabled}
      onChange={datePickerHandler}
      customInputClassName={styles.boldText}
    />
  )
};

export const ChoiceDateInput: React.FC<DateProps> = ({input, meta, ...rest}) => (
  <ChoiceDate {...input} {...meta} {...rest} />
);
