import React, {useCallback, useContext, useEffect, useMemo} from "react";
import {HideWhen} from "../../components/HideWhen";
import {Card, Spinner} from "ui-kit";
import {useIsTablet} from "../../hooks/useIsTablet";
import {BookingOrder} from "../../types/booking";
import {BookingOrdersList} from "./BookingOrdersList";
import styles from "../Dashboard/Dashboard.module.scss";
import {useFromProxyActions} from "../../features/BookingFormProxy";
import {useSelector} from "react-redux";
import {selectOrder} from "../../features/BookingFormProxy/selectors";
import {isEmpty} from "lodash";
import {BookingFormReduxProxy} from "../../components/registration/forms/BookingFormReduxProxy";
import {CreateFromOrder} from "../../components/registration/forms/create-from-order";
import {useCreateOrderMutation} from "../../features/api/booking-order-api";
import {CloseBookingContext} from "contexts/CloseBookingContext";
import {useIntlUtils} from "../../hooks/useIntlUtils";
import {ETranslations} from "../../types/translates";
import {HallSchema} from '../../components/hall-scheme/redux/HallSchemaV2/hall-schema';
import {hallModeSelector} from '../../features/HallSchema/selectors';
import {HallMode, useHallSchemaActions} from '../../features/HallSchema';
import {useIntl} from 'react-intl';
import {useTimelineActions} from '../../features/Timeline';
import {getBookingStartTime} from '../../utils';
import {useApplicationContextActions} from '../../features/AppContex';
import moment from 'moment';
import {useUnmount} from 'react-use';
import {SelectGuestFromList} from '../../features/GuestsList/components/select-guest-from-list';
import {Client} from '../../types/client';

const EditOrder: React.FC = () => {
  const [createOrder] = useCreateOrderMutation();
  const {setEditMode} = useFromProxyActions();
  const closeCard = useContext(CloseBookingContext);
  const update = useCallback(async (data) => {
    await createOrder(data).unwrap();
    closeCard?.();

  }, [createOrder]);

  // onUnmount
  useEffect(() => () => {
    setEditMode(false);
  }, [setEditMode]);

  return (
      <BookingFormReduxProxy
        component={CreateFromOrder}
        submitFn={update}
      />
  );
};

export const BookingOrders: React.FC<any> = () => {
  const {getIntlEntityViewingAction} = useIntlUtils();
  const mode = useSelector(hallModeSelector);
  const {setOnlyOrder, reset, setClient} = useFromProxyActions();
  const orderSelected = useSelector(selectOrder);
  const isTablet = useIsTablet();
  const {switchMode} = useHallSchemaActions();
  const intl = useIntl();
  const {setTime, resetTimeShift} = useTimelineActions()
  const {setPlaceFromOrder, setDate} = useApplicationContextActions();


  const hasOrder = useMemo(() => !isEmpty(orderSelected) && !!orderSelected.orderId, [orderSelected]);
  const close = useCallback(() => {
    setOnlyOrder(undefined);
    resetTimeShift()
    setDate(moment())
  }, [setOnlyOrder]);

  const onSelectOrder = (order: BookingOrder) => {
    reset();
    setPlaceFromOrder(order)
    setOnlyOrder(order);
    setTime(getBookingStartTime(order));
    setDate(moment(order.bookingDate))
  };

  const handleClose = useMemo(() => {
    if (!hasOrder) return undefined;
    return close;
  }, [hasOrder]);

  const closeHall = useCallback(() => switchMode(HallMode.TABLES), [switchMode]);

  const closeGuests = useCallback(() => switchMode(HallMode.TABLE_BOOKINGS_EDIT), [switchMode]);


  const handleSetClient = useCallback((client: Client) => {
    setClient({client});
    closeGuests();
  }, [setClient, closeGuests]);

  useUnmount(() => setDate(moment()))

  return (
    <>
      <HideWhen condition={isTablet && hasOrder}>
        <React.Suspense fallback={<Spinner />}>
          <BookingOrdersList onSelectOrder={onSelectOrder} />
        </React.Suspense>
      </HideWhen>
      <CloseBookingContext.Provider value={handleClose}>
        <HideWhen
          condition={
            isTablet
            && !hasOrder || mode.includes('HALL')
            || [HallMode.BOOKING_GUEST, HallMode.TABLE_BOOKINGS_EDIT_GUEST].includes(mode)
          }
          noUnmount
        >
          <Card onClose={handleClose} >
            <Card.Header
              title="Подтверждение"
            />
            <Card.Content noPadding>
              {hasOrder ? <EditOrder /> : (
                <div className={styles.emptyContainer}>
                  <div className={styles.icon} />
                  <p>{getIntlEntityViewingAction(ETranslations.OF_REQUEST, ETranslations.PLURAL_REQUESTS_THEY)}</p>
                </div>
              )}
            </Card.Content>
          </Card>
        </HideWhen>
        <HideWhen condition={![HallMode.BOOKING_GUEST, HallMode.TABLE_BOOKINGS_EDIT_GUEST].includes(mode)}>
            <SelectGuestFromList
              onClose={closeGuests}
              onSelectGuest={handleSetClient}
            />
        </HideWhen>
      </CloseBookingContext.Provider>
      <HideWhen condition={!mode.includes('HALL')}>
        <Card onClose={closeHall}>
          <Card.Header title={intl.formatMessage({id: ETranslations.HALL_SCHEME})} />
          <Card.Content>
            <HallSchema />
          </Card.Content>
        </Card>
      </HideWhen>
    </>
  );
};
