import React, {FC} from 'react';
import {Menu} from '../../Menu/Menu';
import {ICONS} from 'common/helpers';

import styles from './FileInput.module.scss'
import {useIntl} from 'react-intl';
import {ETranslations} from '../../../types/translates';

interface Props {
  handleButtonClick: () => void;
  removeProfileImage: () => void;
}

export const UploadActions: FC<Props> = ({handleButtonClick, removeProfileImage}) => {
  const intl = useIntl()

  return (
    <Menu className={styles.menu}>
      <Menu.Item icon={ICONS.Edit} onClick={handleButtonClick}>
        {intl.formatMessage({id: ETranslations.BASE_EDIT})}
      </Menu.Item>
      <Menu.Item icon={ICONS.Minus} onClick={removeProfileImage} className={styles.remove}>
        {intl.formatMessage({id: ETranslations.BASE_DELETE})}
      </Menu.Item>
    </Menu>
  );
};
