import {usePrintBookingInfo} from "features/api/bookings-api";
import {useIntlUtils} from "hooks/useIntlUtils";
import {useField} from "react-final-form";
import {ETranslations} from "types/translates";
import {Button, ICONS} from "ui-kit";

import styles from './index.module.scss';

export function PrintBooking() {
  const {intl} = useIntlUtils()
  const {input: {value: bookingId}} = useField<number>("bookingId", {subscription: {value: true}});
  const [print] = usePrintBookingInfo(bookingId || NaN);

  if (!bookingId) return null;

  return (
    <Button variant="phantom" onClick={print} type="button" className={styles.print}>
      <ICONS.Printer />
      {intl.formatMessage({id: ETranslations.BASE_PRINT})}
    </Button>
  );

}
