import cn from 'classnames';

import {Button as OriginalButton, ButtonProps as OriginalButtonProps} from '../Button';

import styles from './RadioButton.module.scss';

export interface ButtonProps<T> extends Omit<OriginalButtonProps, 'variant' | 'ref' | 'value'> {
  value: T
}

export type InternalButtonProps<T> = ButtonProps<T> & Pick<OriginalButtonProps, 'variant'>;

export function Button<T>({value, ...props}: ButtonProps<T>) {
  const {variant} = props as InternalButtonProps<T>;
  return <OriginalButton {...props} variant={variant ?? 'thin'} className={cn(props.className, styles.button)} type="button" />
}
