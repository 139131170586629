import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {useDispatchActions} from '../../hooks/useDispatchActions';

export interface State {
  search: string,
  table: number | null,
}

export const initialState: State = {
  search: '',
  table: null,
};

const slice = createSlice({
  name: 'tableBookingListSlice',
  initialState,
  reducers: {
    setTable(state, action: PayloadAction<number>) {
      state.table = action.payload;
    },
    setSearchValue(state, action: PayloadAction<Pick<State, 'search'>>) {
      const {search} = action.payload;
      state.search = search;
    },
    reset(state) {
      state.table = null;
      state.search = '';
    },
  },
});

export const {name: tableBookingListSliceName, reducer: tableBookingListReducer} = slice;
export const useTableBookingListActions = () => useDispatchActions(slice.actions);
