import {useCallback, useMemo, useState} from "react";
import {Link, NavLink} from "react-router-dom";
import _ from "lodash";
import {useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router";

import {
  isPageDashboard,
  isPageGuests,
  isPageHall,
  isPageRequests,
  isPageSchedule,
  isPageSettings,
} from "common/helpers";
import {AuthService} from "services/auth.service";
import {SingleOption} from "models/common";

import {config} from "config";
import {Telephony} from "../../telephony/telephony";

import {appContextSelectors} from "features/AppContex";
import {useApplicationContextActions} from "features/AppContex/slice";
import {hallModeSelector} from "features/HallSchema/selectors";
import {BookingsReport} from "components/modals/BookingsReport";
import {ReportPlacement} from "components/modals/PlacementReport";

import {SearchHeader} from "./SearchHeader";
import {RestaurantSelect} from "components/RestaurantSelect";
import {PlaceSelect} from "components/PlaceSelect";
import {fromProxySelectors} from "features/BookingFormProxy";
import {Button, Header, ICONS, SelectCheckbox} from "ui-kit";

import {DateControls} from "./DateControls";
import {ETranslations} from "../../../types/translates";
import styles from "./Header.module.scss";
import {HALLS_TITLES} from "../../../constants";
import {useIntlUtils} from "../../../hooks/useIntlUtils";
import {HideWhen} from '../../HideWhen';

type HeaderProps = {
  onToggleMenu(): void;
}

enum ReportModal {
  Booking = 'Booking',
  Placement = 'Placement',
}

export default function AppHeader(props: HeaderProps) {
  const {getIntlChooseEntity, intl} = useIntlUtils()
  const {onToggleMenu} = props;

  const {setRestaurant, setSelectedPlaces} = useApplicationContextActions();

  const [reportModal, setReportModal] = useState<ReportModal | undefined>();
  const closeModal = useCallback(() => setReportModal(undefined), []);
  const placesOptions = useSelector(appContextSelectors.placesOptions);
  const selectedPlaces = useSelector(appContextSelectors.selectedPlaces);
  const isShowPrint = !!useSelector(hallModeSelector);
  const isEditBooking = !!useSelector(fromProxySelectors.booking);
  const location = useLocation();

  const isMultiplePlaces = () => (isPageDashboard() || isPageSettings()) && !isEditBooking;

  const getIcon = () => {
    if (isPageDashboard() || isPageSchedule()) {
      return <img src={reportModal ? ICONS.reportActive : ICONS.reportDeactive} alt='reportModalIcon' />;
    }
    return <ICONS.Print />;
  };

  const mapPlacesById = (nextPlaces: any[] = []) => {
    const idArray = _.uniq(nextPlaces).map((it) => it.value);
    setSelectedPlaces(idArray);
  };

  const selectedPlacesOptions = useMemo(() => selectedPlaces
      .map((it) => placesOptions.find((p) => p.value === it) || new SingleOption(NaN, ''))
      .filter((it) => !Number.isNaN(it.value)) || [], [placesOptions, selectedPlaces]);

  const restaurant = useSelector(appContextSelectors.restaurant);

  const history = useHistory();

  const isSearchPage = useMemo(() => location.pathname === '/search', [location]);

  const content = useMemo(() => {
    if (isSearchPage) return <SearchHeader />
    return (
      <>
        <Header.Part placement="left" className={styles.restaurantControls}>
          <RestaurantSelect value={restaurant.restaurant_id} onRestaurantChange={setRestaurant} />
          {!isPageSchedule() && (
            isMultiplePlaces() ? (
              <SelectCheckbox
                isClearable={false}
                placeholder={getIntlChooseEntity(ETranslations.PLURAL_HALLS_NOM)}
                options={placesOptions}
                //@ts-ignore
                onChange={mapPlacesById}
                value={selectedPlacesOptions}
                titles={HALLS_TITLES}
              />
            ) : <PlaceSelect />
          )}
        </Header.Part>
        <Header.Part placement="right">
          {!isPageRequests() && !isPageGuests() && !isPageSettings() && (
            <DateControls />
          )}
          <Telephony />
          {Boolean(isPageDashboard() && AuthService.getUserRole() !== 'ADMINISTRATOR' || isPageSchedule() || (isPageHall() && isShowPrint))
            && (
              <Button
                variant="phantom"
                square
                className={styles.printBtn}
                onClick={(e) => {
                  e.stopPropagation();
                  if (isPageHall()) {
                    const currentTheme = document.documentElement.dataset.mode;
                    document.documentElement.dataset.mode = 'light';
                    window.print();
                    document.documentElement.dataset.mode = currentTheme;

                  } else if (isPageDashboard()) {
                    setReportModal(ReportModal.Booking);
                  } else {
                    setReportModal(ReportModal.Placement);
                  }
                }}
              >
                {/* {getIcon()} */}
                <ICONS.Print width={31} height={31} />
                <div className="tooltip">
                  {intl.formatMessage({id: isPageHall() ? ETranslations.PRINT : ETranslations.UPLOAD})}
                </div>
              </Button>
            )}
            <NavLink to={{
              pathname: '/create-booking',
              state: {
                from: history.location.pathname,
              },
            }}
            >
              <Button square variant="primary">
                <img
                  src={ICONS.plusPure} alt=""
                />
              </Button>
            </NavLink>
        </Header.Part>
      </>
    );
   }, [
    isSearchPage, restaurant,
    setRestaurant, placesOptions, selectedPlacesOptions,
    isShowPrint,
    setReportModal, getIcon, history
  ]);

  return (
    <>
      <Header
        menuControl={<Header.OpenMenu onClick={onToggleMenu} />}
        logo={
          <HideWhen condition={!config.hasLogo}>
            <Link to="/">
              <img src={ICONS[`${config.BRAND}_LOGO` as keyof typeof ICONS] as string || ICONS.WRF_LOGO} alt="" />
            </Link>
          </HideWhen>
        }
      >
        {content}
      </Header>
      <BookingsReport isOpen={reportModal === ReportModal.Booking} closeModal={closeModal} />
      <ReportPlacement isOpen={reportModal === ReportModal.Placement} closeModal={closeModal} />
    </>
  );
}
