import React, {CSSProperties, ReactElement, useEffect} from "react";
import {Client} from "../../../models/client.model";
import {useGuestsList} from "../../api/guest-api";
import {emptyStringIfNull, getMaskPhone, ICONS} from "../../../common/helpers";
import cn from "classnames";
import {Card, Input, Spinner, Collapse, SelectCheckbox, Button} from 'ui-kit';
import {useSelector} from "react-redux";
import {selectedTags} from "../selectors";
import {SingleOption} from "../../../models/common";
import _ from "lodash";
import {GuestDetail} from "components/GuestDetail";
import {selectedGuestSelector} from "features/BookingFormProxy/selectors";
import {useTagsOptions} from "features/api/tags";


import {useIntlUtils} from "../../../hooks/useIntlUtils";
import {ETranslations} from "../../../types/translates";
import {TAGS_TITLES} from "../../../constants";
import styles from "./select-guest-from-list.module.scss";
import {config} from "../../../config";
import {TagsById} from "../../../components/Tags";
import {ClientInfoPopup} from '../../../components/BookingInfoPopup';

type SelectGuestFromListProps = {
  style?: CSSProperties,
  onClose?: () => void,
  onSelectGuest: (guest: Client) => void;
  isExpandable?: boolean;
  controls?: ReactElement;
  onOneGuestLeft?: (client: Client) => void;
}


export const SelectGuestFromList: React.FC<SelectGuestFromListProps> = ({
                                                                          style,
                                                                          onClose,
                                                                          onSelectGuest,
                                                                          isExpandable = true,
                                                                          controls,
                                                                          onOneGuestLeft,
                                                                        }) => {
  const {getIntlChooseEntity, intl} = useIntlUtils();
  const userSelectedTags = useSelector(selectedTags);
  const selectedGuest = useSelector(selectedGuestSelector);
  const tagsOptions = useTagsOptions();
  const {guests, isLoading, handleScroll, updateFilter, filter} = useGuestsList();

  useEffect(() => {
    if (guests.length === 0 && filter.phone?.match(/\d/g)?.length === 11) {
      onClose?.();
    }
  }, [guests, onClose, filter]);

  useEffect(() => {
    if (guests.length === 1) {
      onOneGuestLeft?.(guests[0]);
      selectedGuest && onClose?.();
    }
  }, [guests, onOneGuestLeft, selectedGuest]);

  const handleOnInputTerm = (value: string) => {
    updateFilter({term: value});
  };
  const handleTagsChange = (tags: SingleOption[] = []) => {
    const tagsIdArray = tags.map((it) => it.value);
    if (tagsIdArray) {
      updateFilter({tags: tagsIdArray});
    }
  };

  const getFromFilter = () => tagsOptions.filter((it) => _.includes(userSelectedTags, it.value));

  return (
    <>
      {isLoading ? (<Spinner />) : (
        <Card style={style} onClose={onClose}>
          <Card.Header title={intl.formatMessage({id: ETranslations.GUEST_LIST})} controls={controls}>
            <SelectCheckbox
              className={styles.filter}
              placeholder={getIntlChooseEntity(ETranslations.PLURAL_TAGS_NOM)}
              value={getFromFilter()}
              // @ts-ignore
              onChange={handleTagsChange}
              options={tagsOptions}
              titles={TAGS_TITLES}
            />

            <Input.Search onSearch={handleOnInputTerm} className={styles.filter} />
          </Card.Header>
          <Card.Content onScroll={handleScroll} style={{overflowY: "auto"}}>
            <div>
              {guests?.map((g: Client) => (
                <Collapse
                  headerClassName={styles.guest}
                  onClick={() => onSelectGuest(g)}
                  key={g.client_id} header={
                  <section
                    className={cn("guest-card", "view", "variant-B")}
                    style={{width: "100%", border: "none"}}
                  >
                    <div className={styles.wrapper}>
                      <div className={cn("badge")}>
                        <div className="data">
                          <div className="loyalty-bar null" />
                          <svg
                            width="24" height="24"
                            viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="call"
                          >
                            <path
                              fillRule="evenodd" clipRule="evenodd"
                              d="M6.62 10.79C8.06 13.62 10.38 15.93 13.21 17.38L15.41 15.18C15.68
                              14.91 16.08 14.82 16.43 14.94C17.55 15.31 18.76
                              15.51 20 15.51C20.55 15.51 21 15.96 21 16.51V20C21 20.55
                              20.55 21 20 21C10.61 21 3 13.39 3 4C3 3.45 3.45 3 4
                              3H7.5C8.05 3 8.5 3.45 8.5 4C8.5 5.25 8.7 6.45 9.07
                              7.57C9.18 7.92 9.1 8.31 8.82 8.59L6.62 10.79Z"
                              fill="var(--icon-color, #BEC5D1)"
                            />
                          </svg>
                          <h3 className="name">{emptyStringIfNull([g.surname, g.name])}</h3>
                          <span
                            className="phone"
                          >
                            {getMaskPhone(g.phone)}
                          </span>
                          {config.vipSign && g.vip && <img className="star" src={ICONS.StarA} alt="vip" />}
                          {g.tags && g.tags?.length !== 0 && (
                            <div className={styles.tags}>
                              <TagsById tagsIDs={g.tags}/>
                            </div>
                          )}
                        </div>
                      </div>
                      <ClientInfoPopup clientId={g.client_id} placement="auto">
                        <Button
                          variant="phantom"
                          type="button"
                          className={styles.guestDetail}
                        >
                          <ICONS.Question />
                        </Button>
                      </ClientInfoPopup>
                    </div>
                  </section>
                }>
                </Collapse>
              ))}
            </div>
          </Card.Content>
        </Card>
      )}
    </>
  );
};
