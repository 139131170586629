import React, {useCallback, useEffect} from "react";
import {Spinner} from "ui-kit";
import {HideWhen} from "components/HideWhen";

import {useIsTablet} from "hooks/useIsTablet";

import {GuestCard} from "./GuestCard";
import {useLocation} from "react-router";
import * as queryString from "querystring";
import {useSelector} from "react-redux";
import {guestListSelectors, useGuestListActions} from "features/GuestsList";
import {useLazyFetchGuestDetailQuery} from "features/api/guest-api";
import {GuestViewContext, useGuestView} from "./GuestViewContext";
import {SelectGuestFromList} from "features/GuestsList/components/select-guest-from-list";
import {useQueryParam} from "hooks/useQueryParam";
import {useUnmount} from "react-use";
import {useIntlUtils} from "../../hooks/useIntlUtils";
import {ETranslations} from "../../types/translates";
import {Client} from '../../models/client.model';


function GuestsContainer() {
  const {getIntlCreatingOf, isRussianLocale} = useIntlUtils();
  const [view, setView] = useGuestView();
  const clientForClientCard = useSelector(guestListSelectors.selectedClient);
  const {setSelectedClient} = useGuestListActions();

  const isTablet = useIsTablet();
  const location = useLocation();
  const [fetchGuest] = useLazyFetchGuestDetailQuery();

  useEffect(() => {
    const {clientId} = queryString.parse(location.search.replace('?', ''));
    clientId && fetchGuest(+clientId).unwrap().then(setSelectedClient);
  }, [location]);

  const goToCreate = useCallback(() => setView('create'), [setView]);

  useUnmount(() => { setSelectedClient(undefined); });

  const selectClientHandler = async (client: Client) => {
    const {client_id} = client
    const detailClient = await fetchGuest(client_id);
    setSelectedClient(detailClient.data)
  }

  return (
    <>
      <React.Suspense fallback={<Spinner />}>
        <HideWhen condition={isTablet && (!!clientForClientCard || view === 'create')} noUnmount>
          <SelectGuestFromList
            onSelectGuest={selectClientHandler}
            isExpandable={false}
            controls={isTablet ? (
              <button type="button" className="primary" onClick={goToCreate}>
                {getIntlCreatingOf(isRussianLocale ? ETranslations.PLURAL_GUESTS_ALT : ETranslations.PLURAL_GUEST)}
              </button>
            ) : undefined}
          />
        </HideWhen>
      </React.Suspense>
      <HideWhen condition={isTablet && !clientForClientCard && view === 'view'} noUnmount>
        <GuestCard/>
      </HideWhen>
    </>
  );
}

export function Guests() {
  const phone = useQueryParam('phone');
  return (
    <GuestViewContext initial={phone ? 'create' : 'view'}>
      <GuestsContainer />
    </GuestViewContext>
  );
}
