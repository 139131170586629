import {FunctionComponent} from 'react';
import moment from 'moment';
import {useFetchBookingHistoryDetailsChronologicalStatusQuery} from 'features/api/bookings-api';
import {Spinner} from 'ui-kit';
import {Tag} from '../Tag';
import {useIntl} from "react-intl";
import {ETranslations} from "../../types/translates";
import {capitalizeString} from "../../utils";

interface Props {
  bookingId: number
}


const BookingHistoryModalStatus: FunctionComponent<Props> = ({bookingId}) => {
  const intl = useIntl()
  const {data} = useFetchBookingHistoryDetailsChronologicalStatusQuery(bookingId);

  if (!data) return <Spinner />;

  return (
    <div className="bookingHistoryStatusBody">
      <div className="bookingHistorySectionHeader">
        <div className="bookingHistoryValue bookingHistoryTitle">{intl.formatMessage({id: ETranslations.BASE_DATE})}</div>
        <div className="bookingHistoryValue">{capitalizeString(intl.formatMessage({id: ETranslations.PLURAL_STATUS}))}</div>
      </div>
      {data.map(({timeKey, status}) => (
        <div className="bookingHistoryStatusSection" key={status.id}>
          <div className="bookingHistoryValue bookingHistoryTitle">
            {moment(timeKey).format('DD.MM.YYYY HH:mm')}
          </div>
          <div>
            <div className="bookingHistoryValue">
              <Tag tag={{id: status.id,
                color: status.color!,
                name: status.name,
                status: status.systemName.toLowerCase()}}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BookingHistoryModalStatus;
