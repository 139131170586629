import {IResponse} from 'models/common';
import {useMemo} from 'react';
import {Tag} from 'types/tag';
import {api} from './api';

const tagsApi = api.injectEndpoints({
  endpoints: ($) => ({
    getTags: $.query<Tag[], void>({
      query: () => ({
        url: '/v2/tags',
      }),
      transformResponse: (response: IResponse<Array<{ color: string, description: string, tag_id: number }>>) => response
        .data.map((t) => ({color: t.color, name: t.description, id: t.tag_id})),
    }),
  }),
});


const {useGetTagsQuery} = tagsApi;

export function useTags(ids: number[]) {
  const {data} = useGetTagsQuery();
  return useMemo(() => data?.filter((t) => ids.includes(t.id)) ?? [], [ids]);
}

export function useTagsOptions() {
  const {data} = useGetTagsQuery();
  return useMemo(() => data?.map((t) => ({label: t.name, value: t.id, color: t.color})) ?? [], [data]);
}
