import {ETranslations} from "../types/translates";
import {TPluralsTitles} from "../types/commons";

export const FILTERS_TITLES: TPluralsTitles = [
  ETranslations.PLURAL_FILTER,
  ETranslations.PLURAL_FILTERS_ALT,
  ETranslations.PLURAL_FILTERS,
  ETranslations.PLURAL_FILTERS_NOM
]

export const CANCELLATIONS_TITLES: TPluralsTitles = [
  ETranslations.PLURAL_CANCELLATION,
  ETranslations.PLURAL_CANCELLATIONS,
  ETranslations.PLURAL_CANCELLATION_NOM,
  ETranslations.PLURAL_CANCELLATION_NOM
]

export const HALLS_TITLES: TPluralsTitles = [
  ETranslations.PLURAL_HALL,
  ETranslations.PLURAL_HALLS_ALT,
  ETranslations.PLURAL_HALLS,
  ETranslations.PLURAL_HALLS_NOM
]

export const BOOKINGS_TITLES: TPluralsTitles = [
  ETranslations.PLURAL_BOOKING,
  ETranslations.PLURAL_BOOKINGS_NOM,
  ETranslations.PLURAL_BOOKINGS,
  ETranslations.PLURAL_BOOKINGS_NOM
]
export const REQUESTS_TITLES: TPluralsTitles = [
  ETranslations.PLURAL_REQUEST,
  ETranslations.PLURAL_REQUESTS_NOM,
  ETranslations.PLURAL_REQUESTS,
  ETranslations.PLURAL_REQUESTS_NOM
]

export const ELEMENTS_TITLES: TPluralsTitles = [
  ETranslations.PLURAL_ELEMENT,
  ETranslations.PLURAL_ELEMENTS_ALT,
  ETranslations.PLURAL_ELEMENTS,
  ETranslations.PLURAL_ELEMENTS_NOM
]

export const STATUSES_TITLES: TPluralsTitles = [
  ETranslations.PLURAL_STATUS,
  ETranslations.PLURAL_STATUSES_ALT,
  ETranslations.PLURAL_STATUSES,
  ETranslations.PLURAL_STATUSES_NOM
]

export const CHANGES_TITLES: TPluralsTitles = [
  ETranslations.PLURAL_CHANGE,
  ETranslations.PLURAL_CHANGES_NOM,
  ETranslations.PLURAL_CHANGES,
  ETranslations.PLURAL_CHANGES_NOM
]

export const ACTIONS_TITLES: TPluralsTitles = [
  ETranslations.PLURAL_ACTION,
  ETranslations.PLURAL_ACTIONS_NOM,
  ETranslations.PLURAL_ACTIONS,
  ETranslations.PLURAL_ACTIONS_NOM
]

export const VALUES_TITLES: TPluralsTitles = [
  ETranslations.PLURAL_VALUE,
  ETranslations.PLURAL_VALUES_NOM,
  ETranslations.PLURAL_VALUES,
  ETranslations.PLURAL_VALUES_NOM
]

export const PERSONS_TITLES: TPluralsTitles = [
  ETranslations.PLURAL_PERSON,
  ETranslations.PLURAL_PERSONS_NOM,
  ETranslations.PLURAL_PERSONS,
  ETranslations.PLURAL_PERSONS_NOM
]

export const TABLES_TITLES: TPluralsTitles = [
  ETranslations.PLURAL_TABLE,
  ETranslations.PLURAL_TABLES_ALT,
  ETranslations.PLURAL_TABLES,
  ETranslations.PLURAL_TABLES_NOM
]

export const TAGS_TITLES: TPluralsTitles = [
  ETranslations.PLURAL_TAG,
  ETranslations.PLURAL_TAGS_ALT,
  ETranslations.PLURAL_TAGS,
  ETranslations.PLURAL_TAGS_NOM
]

export const MINUTES_TITLES: TPluralsTitles = [
  ETranslations.PLURAL_MINUTE,
  ETranslations.PLURAL_MINUTES_NOM,
  ETranslations.PLURAL_MINUTES,
  ETranslations.PLURAL_MINUTES_NOM
]

export const HOURS_TITLES: TPluralsTitles = [
  ETranslations.PLURAL_HOUR,
  ETranslations.PLURAL_HOURS_ALT,
  ETranslations.PLURAL_HOURS,
  ETranslations.PLURAL_HOURS_NOM
]

export const GUESTS_TITLE: TPluralsTitles = [
  ETranslations.PLURAL_GUEST,
  ETranslations.PLURAL_GUESTS_ALT,
  ETranslations.PLURAL_GUESTS,
  ETranslations.PLURAL_GUESTS_NOM,
]
