import {PositionModal} from 'models/common';
import {SettingsHostess} from '../models/settings.model';

export enum TypeModal {
  changesParams = 'changesParams',
  infoCall = 'infoCall',
  infoPhones = 'infoPhones',
  changeHostess = 'changeHostess',
  changeUser = 'changeUser',
  confirm = 'confirm',
}

export interface RootState {
  activeModal?: {type: TypeModal; position: PositionModal};
  settingsHostessModal: SettingsHostess
}
