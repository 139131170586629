import {BookingSubmit, Response, Table} from "models/common";
import {apiINT} from "./api-data";
import {AuthService} from "./auth.service";
import {RequestWrapper} from "./request-wrapper";
import {
  BookingInfoFilter,
  BookingAvailableInfo,
  BookingResponse,
  HistoryBooking,
  SwapTarget,
  BookingInfoItems
} from "models/booking.model";
import {Client} from "models/client.model";
import { BookingStatus as BS } from "types/booking";


/**
 * @deprecated использовать тип из "types/booking"
 */
export const BookingStatus = BS;

export interface BookingsFilter {
  date?: string,
  start_date?: string,
  end_date?: string,
  status?: BS[],
  place_id?: number[],
  term?: string,
  tags?: number[],
  client_id?: number,
  count: number,
  offset: number,
  sort_column?: string,
  sort?: string;
  table_id?: number[];
  user_name?: string;
  restaurant_id: number;
}

export class BookingService {
  static async getByFilter(filter: BookingsFilter): Promise<{ data: BookingResponse[] }> {
    return RequestWrapper.fetch(`${apiINT}/reservation/booking/search`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', Authorization: AuthService.getToken()},
      body: JSON.stringify(filter)
    }).then((r) => r.json());
  }

  static async createBooking(data: Partial<BookingSubmit>) {
    return RequestWrapper.fetch(`${apiINT}/reservation/booking`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', Authorization: AuthService.getToken()},
      body: JSON.stringify(data)
    }).then((r) => r.json())
  }

  static async getAvailableValues(data: BookingInfoFilter): Promise<Response<BookingInfoItems>> {
    return RequestWrapper.fetch(`${apiINT}/reservation/booking/info`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: AuthService.getToken()},
      body: JSON.stringify(data)
    }).then((res) => res.json(),
      err => console.warn(err))
  }

  static extractAvailableValues(response: Response<BookingInfoItems>, placeId: number): BookingAvailableInfo {
    if (response.data.items) {
      return response.data.items[placeId];
    }
    throw new Error(`Cant extract result value for ${placeId}`);
  }

  static async getHistoryById(id: number): Promise<{ data: HistoryBooking[] }> {
    return RequestWrapper.fetch(`${apiINT}/reservation/booking/history?booking_id=${id}`, {headers: {Authorization: AuthService.getToken()}})
      .then((response) => response.json())
  }

  static async swap(from: SwapTarget, to: Partial<SwapTarget>): Promise<{ data: HistoryBooking[] }> {
    try {
    const res = await RequestWrapper.fetch(`${apiINT}/reservation/booking/swap`, {
      method: 'POST',
      headers: {Authorization: AuthService.getToken(), 'Content-Type': 'application/json'},
      body: JSON.stringify({
        origin: from,
        destination: to
      })
    });
    const data = await res.json();
    if (data.status === 'ERROR'){
      throw data.error;
    }
    return data;
    } catch(e){
      throw e;
    }
  }

  static mockAll(filter?: BookingsFilter): BookingResponse[] {
    return [
      new BookingResponse(
        10,
        '2019-10-10T10:00',
        new Client(0, 'name', 'surname', 'phone', 'email', 0, 'grade', null, [], 'low', 0, '', '', '', ''),
        12,
        5,
        [5],
        20,
        6,
        true,
        10,
        BookingStatus.NEW,
        true,
        [
          new Table(0, 205, 10),
          new Table(99, 1, 10),
        ],
        '',
        '2020-01-28T10:00',
        1,
        3,
      ),

      new BookingResponse(
        11,
        '2019-10-10T14:15',
        new Client(2, 'name', 'surnameB', 'phone', 'email', 3, 'grade', null, [], 'low', 0, '', '', '', ''),
        12,
        5,
        [5],
        20,
        6,
        true,
        10,
        BookingStatus.NEW,
        true,
        [
          new Table(2, 2, 10)
        ],
        '',
        '2020-01-28T10:00',
        1,
        3,
      ),

      new BookingResponse(
        13,
        '2019-10-10T14:00',
        new Client(3, 'name', 'surname', 'phone', 'email', 3, 'grade', null, [], 'low', 0, '', '', '', ''),
        13,
        5,
        [5],
        20,
        6,
        true,
        10,
        BookingStatus.NEW,
        true,
        [
          new Table(2, 2, 10)
        ],
        '',
        '2020-01-28T10:00',
        1,
        3,
      ),
    ].filter(b => {
      if (filter) {
        const predicates = [];

      // if (filter.status && b.status) {
      //   predicates.push(filter.status.includes(b.status));
      // }

        if (filter.place_id) {
          predicates.push(filter.place_id.includes(b.place_id));
        }

        return predicates.every(_ => _);

      } else {
        return true;
      }
    });
  }

  static mocktAvailableValues(data: BookingInfoFilter): BookingAvailableInfo {
    return new BookingAvailableInfo(
      '2020-01-01',
      ['10:00', '11:00', '12:00', '13:00'],
      [15, 60, 90, 120, 180],
      [2, 5],
      [2, 3],
      [
        new Table(0, 10, 2),
        new Table(1, 11, 3),
        new Table(2, 12, 3),
      ],
      [15, 60, 90, 120, 180],
      ['10:00', '11:00', '12:00', '13:00'],
    )
  }

}
