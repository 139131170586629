import React, {useCallback, useMemo, useState} from 'react';
import {OrderType} from '../../types/commons';
import {useOrderList} from '../../features/api/booking-order-api';
import {Input, RadioButton, Spinner, Card} from 'ui-kit';
import {BookingsList} from 'components/BookingsList';
import {BookingOrder} from 'types/booking';
import {useFromProxyActions} from 'features/BookingFormProxy';
import {useIntl} from "react-intl";
import {ETranslations} from "../../types/translates";
import dayjs from 'dayjs';

export const BookingOrdersList: React.FC<any> = ({onSelectOrder}) => {
  const intl = useIntl()
  const [fetchMode, setFetchMode] = useState<OrderType>('active');
  const [searchKeyword, setSearchKeyword] = useState('');
  const {isLoading, data: apiResult} = useOrderList(fetchMode, searchKeyword);
  const {setEditMode} = useFromProxyActions();

  const handleEditClick = useCallback((order: BookingOrder) => {
    onSelectOrder(order);
    setEditMode(true);
  }, []);

  const sortedOrders = useMemo(() => {
    if (!apiResult) return [];

    return [...apiResult].sort((a, b) => {
      const firstDate = dayjs(`${a.bookingDate} ${a.bookingTime}`, 'YYYY-MM-DD HH:mm')
      const secondDate = dayjs(`${b.bookingDate} ${b.bookingTime}`, 'YYYY-MM-DD HH:mm')
      return firstDate.valueOf() - secondDate.valueOf()
    })
  }, [apiResult])

  return (
    <>
      <Card>
        <Card.Header title={intl.formatMessage({id: ETranslations.PLURAL_REQUESTS_NOM})}>
          <RadioButton value={fetchMode} onChange={setFetchMode} >
            <RadioButton.Button value="active">{intl.formatMessage({id: ETranslations.BASE_ACTIVE})}</RadioButton.Button>
            <RadioButton.Button value="history">{intl.formatMessage({id: ETranslations.UNPROCESSED})}</RadioButton.Button>
          </RadioButton>
          <Input.Search
            value={searchKeyword}
            onSearch={setSearchKeyword}
          />
        </Card.Header>
        <Card.Content>
          {isLoading && <Spinner />}
          <div style={{overflowY: 'auto', display: 'block', flex: '1.0', padding: '15px 0'}}>
            {apiResult
              && (
                <BookingsList
                  bookings={sortedOrders}
                  onClick={onSelectOrder}
                  onEdit={handleEditClick}
                  withActions
                  compact
                  withDate
                />
              )}
          </div>
        </Card.Content>
      </Card>
    </>
  );
};
