import React, {HTMLAttributes, useCallback, useEffect, useLayoutEffect, useState} from "react";
import {Card, ICONS, Spinner} from "ui-kit";
import {useSelector} from "react-redux";
import {guestListSelectors, useGuestListActions} from "features/GuestsList";
import {useIntlUtils} from "hooks/useIntlUtils";
import {ETranslations} from "types/translates";
import {TButtons} from "components/GuestContent/Control/Control";
import {useHistory} from "react-router";
import {useFetchClientQuery} from "features/api/guest-api";
import GuestInfo from "components/GuestInfo/GuestInfo";
import {useIsTablet} from "hooks/useIsTablet";
import {useGuestView} from "./GuestViewContext";
import GuestFormContainer from "components/GuestContent/GuestForm/GuestFormContainer";
import {useFromProxyActions} from '../../features/BookingFormProxy';

export type TDictionary = {
    id: number,
    name: string,
    nameEng: string
}

export type TView = "info" | "edit" | "create" | null

type TProps = Pick<HTMLAttributes<HTMLDivElement>, 'style'>

export function GuestCard({style}: TProps) {
    const isTablet = useIsTablet();
    const intlUtils = useIntlUtils()
    const {
        intl,
        isRussianLocale,
        getIntlEntityViewingAction,
        getIntlCreatingOf
    } = intlUtils

    const {clearSelectedClient} = useGuestListActions();
    const {setClient} = useFromProxyActions();

    const [mainView, setMainView] = useGuestView();

    const clientId = useSelector(guestListSelectors.selectedClient);


    const {data: clientForClientCard, isFetching: clientIsFetching}
        = useFetchClientQuery(clientId?.client_id ?? 0, {skip: !clientId?.client_id})

    const history = useHistory();

    const [view, setView] = useState<TView>(null);

    const handleClose = useCallback(() => {
        if (clientForClientCard) {
            clearSelectedClient();
        }
        setView(null);
    }, [clientForClientCard, clearSelectedClient])

    const INFO_BUTTONS: TButtons[] = [
        {
            child: intl.formatMessage({id: ETranslations.CREATE_BOOKING}),
            props: {
                onClick: () => {
                    history.push('/create-booking');
                    if(clientForClientCard){
                        setClient({client: clientForClientCard});
                    }
                },
                variant: 'primary'
            }
        },
        {child: <ICONS.Edit/>, props: {onClick: () => setView("edit"), variant: 'secondary'}},
    ]

    const CREATE_BUTTONS: TButtons[] = [
        {
            child: intl.formatMessage({id: ETranslations.SAVE}),
            props: {variant: 'primary', form: "guest-form", type: 'submit'}
        },
        {child: <ICONS.CrossWhite/>, props: {onClick: () => setView("info"), variant: 'secondary'}},
    ]

    useEffect(() => {
        if (!clientId?.client_id) {
            setView(null)
        } else setView("info")
    }, [clientId])

    useLayoutEffect(() => {
        if (isTablet && view === null) {
            setMainView('view')
        }
    }, [view])

    useLayoutEffect(() => {
        if (mainView === "create") {
            setView("create")
        }
    }, [mainView])

    if (clientIsFetching) return <Spinner/>

    return (
        <Card style={style} onClose={handleClose}>
            {view === "info" && <GuestInfo guest={clientForClientCard} buttons={INFO_BUTTONS}/>}
            {view === "edit" && (
              <GuestFormContainer
                view={view}
                buttons={CREATE_BUTTONS}
                guest={clientForClientCard}
                setView={setView}
              />
            )}
            {view === "create" && (
              <GuestFormContainer
                view={view}
                setView={setView}
                buttons={CREATE_BUTTONS}/>
            )}
            {!isTablet && view === null && <div className="guest-card-empty create-guest">
                <div className="img contact"/>
                <p>{getIntlEntityViewingAction(ETranslations.PLURAL_GUESTS_THEY, ETranslations.PLURAL_GUESTS_ALT)}</p>
                <button className="primary" type="button"
                        onClick={() => setView("create")}
                >
                    {getIntlCreatingOf(isRussianLocale ?
                        ETranslations.PLURAL_GUESTS_ALT : ETranslations.PLURAL_GUEST)}
                </button>
            </div>
            }
        </Card>
    );
}
