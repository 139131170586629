import {Client} from "./client";
import {Place} from "./place";
import {Tag} from "./tag";
import {Tenant} from "./tenant";
import {Pagination, SortDirection} from "./commons";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {IEvent} from "./event";
import {Restaurant} from "./restaurant";

export enum BookingStatus {
  NEW = "NEW",
  CONFIRMED = "CONFIRMED",
  IN_HALL = "IN_HALL",
  WAIT_LIST = "WAIT_LIST",
  EXTERNAL = "EXTERNAL",
  NOT_COME = "NOT_COME",
  CANCELED = "CANCELED",
  CLOSED = "CLOSED",
}

export interface StatisticValue {
  bookings: number;
  guests: number;
}

export interface Statistic {
  all: StatisticValue;
  inHall: StatisticValue;
  notInHall: StatisticValue;
}

export interface SearchResponse {
  bookings: Booking[];
  statistics: Statistic;
}

/* export interface BookingBase {

} */

export interface Booking {
  bookingId: number;
  version: number;
  bookingDate: string;
  bookingTime: string;
  visitTime: number;
  persons: number;
  comment: string;
  phone: string;
  useDeposit: boolean;
  depositStatus: string;
  depositAmount: number;
  isLocked: boolean;
  createdAt: string;
  lastModifyAt: string;
  tags: Tag[];
  places: Place[];
  source?: Source;
  status: Status;
  client: Client;
  isOverbooking?: boolean;
  notes?: Record<string, string>;
}

export interface BookingOrder {
  orderId: number;
  version: number;
  bookingDate: string;
  bookingTime: string;
  visitTime: number;
  persons: number;
  comment: string;
  useDeposit: boolean;
  depositAmount: number;
  createdAt: string;
  lastModifyAt: string;
  places: Place;
  source: Source;
  client: Client;
}

export interface Source {
  id: number;
  source_name: string;
}

export interface Status {
  id: number;
  tenantId: number;
  systemName: BookingStatus;
  locale: string;
  name: string;
  step: number;
  isTerminal: boolean;
  visible: boolean;
  color: string | null;
  tenant: Tenant;
}

export interface BookingOrderFilter {
  "restaurantId": number,
  "from": string,
  "to": string,
  "search_keyword": string,
}

export interface OrderCountRequest {
  "restaurantId": number,
  "date": string,
}

export interface BookingRequestFilter {
  "from": string,
  "to": string,
  "restaurant_id": number,
  "tables_filter": Array<number | typeof skipToken>,
  "exclude_halls": number[],
  "status_exclude": string[],
  "search_keyword": string,
  "sort_param": string,
  "sort_direction": SortDirection,
  userid?: number | null
}

export interface BookingFilterParams {
  "from": string,
  "to": string,
  "restaurant_id": number,
  "only_tables"?: number[],
  "places"?: number[];
  "statuses"?: string[];
  "search_keyword"?: string;
  "sort"?: Array<{
    "param": string;
    "direction": string;
  }>,
  user_id?: null | number
}

export interface BookingHistoryResponse {
  booking: Booking,
  events: Array<IEvent>
}

interface GenericHistoryDetail<T> {
  old: T,
  new: T,
}

export interface BookingHistoryDetailsParamResponse {
  client: GenericHistoryDetail<Client>,
  comment: GenericHistoryDetail<string>,
  date: GenericHistoryDetail<Date>,
  depositAmount: GenericHistoryDetail<number>,
  depositStatus: GenericHistoryDetail<string>,
  depositUse: GenericHistoryDetail<boolean>,
  overbooking: GenericHistoryDetail<boolean>,
  persons: GenericHistoryDetail<number>,
  status: GenericHistoryDetail<Status>,
  tables: GenericHistoryDetail<Array<string>>,
  tags: GenericHistoryDetail<Array<Tag>>,
  visitTime: GenericHistoryDetail<number>,
}

export type BookingHistoryDetailsChronologicalStatusResponse = Array<{
  status: Status,
  timeKey: Date
}>

export type BookingHistoryDetailsChronologicalPlacesResponse = Array<{
  timeKey: Date,
  places: string
}>

interface GenericParamsItem<N, T> {
  name: N,
  old: T,
  new: T;
  userId: number
}

export type ParamsItemsType = GenericParamsItem<"client", Client>
  | GenericParamsItem<"comment", string>
  | GenericParamsItem<"date", Date>
  | GenericParamsItem<"depositAmount", number>
  | GenericParamsItem<"depositStatus", string>
  | GenericParamsItem<"useDeposit", boolean>
  | GenericParamsItem<"overbooking", boolean>
  | GenericParamsItem<"persons", number>
  | GenericParamsItem<"status", Status>
  | GenericParamsItem<"tables", string[]>
  | GenericParamsItem<"tags", Tag[]>
  | GenericParamsItem<"visitTime", number>
  | GenericParamsItem<"source", Source>
  | GenericParamsItem<"phone", string>
  | GenericParamsItem<"clientName", string>

export type BookingHistoryDetailsChronologicalChangesResponse = Array<{
  timeKey: string,
  params: Array<ParamsItemsType>
}>

export interface BookingWithRest extends Booking {
  restaurant: Restaurant;
}

export type BookingHistoryClientResponse = Pagination<BookingWithRest>;

export type TUseBookingList = {
  search : string,
  includeStatuses?: string[],
  forTables?: Array<number>,
  fromDate?: Date | string | null, toDate?: Date | string | null,
  userid? : number | null,
  isPooling? : boolean,
  isSkip?: boolean,
  withOutPlaces?: boolean
}
