import React, {forwardRef, HTMLAttributes} from "react";
import cn from "classnames";

import styles from "./SvgForeignObject.module.scss";

export const SvgForeignObject: React.FC<JSX.IntrinsicElements["foreignObject"]> = ({
                                                                                     children,
                                                                                     className,
                                                                                     ...props
                                                                                   }) => (
  <foreignObject {...props} className={cn(styles.foreignObject, className)}>
    {children}
  </foreignObject>
);

export const SvgForeignObjectBody = forwardRef<HTMLBodyElement, HTMLAttributes<HTMLBodyElement>>(({
                                                                                                    children,
                                                                                                    ...props
                                                                                                  }, ref) => {
  if (process.env.NODE_ENV === "test") return <>{children}</>;
  return (
    // @ts-ignore
    <body xmlns="http://www.w3.org/1999/xhtml" ref={ref} {...props}>
    {children}
    </body>
  );
});
