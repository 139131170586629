import React, {useCallback} from 'react';
import {useFormState} from 'react-final-form';
import {noop} from 'lodash';
import {ConfirmBusyTable} from 'components/ConfirmBusyTable';



import {useSelector} from 'react-redux';
import * as select from 'features/BookingFormProxy/selectors';
import {FormBooking} from 'features/BookingFormProxy/types';
import {useChangeBookingStatus, useGetBookingStatusQuery} from 'features/api/bookings-api';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {Status} from 'types/booking';

import styles from './index.module.scss';
import {Recovery} from './Recovery';
import {Button, ButtonProps} from 'ui-kit';

const getVariantButton = (status: Status): ButtonProps['variant'] => {
  const variants: Partial<Record<Status['systemName'], ButtonProps['variant']>> = {
    ['IN_HALL']: "primary",
    ['NOT_COME']: "status",
    ['CLOSED']: 'dangerous',
    ['CONFIRMED']: 'primary',
  }

  return variants[status.systemName] || 'secondary';
}

export function VisitControl() {
  const {submitting, invalid} = useFormState<FormBooking>();
  const booking = useSelector(select.selectBooking);
  const isEdit = useSelector(select.isEdit);

  const {data} = useGetBookingStatusQuery(booking.bookingId ?? skipToken);
  const {force, nextStatus, terminalStatus, resetForce} = useChangeBookingStatus(booking.bookingId);

  const handleTerminal = useCallback(async () => {
    await terminalStatus();
   }, [terminalStatus]);


  if (isEdit) return null;

  return (
    <div className={styles.visitControls}>
      {data?.next && (
        <Button
          variant={getVariantButton(data.next)}
          onClick={submitting ? noop : nextStatus}
          disabled={submitting || invalid}
        >
          {data.next.name}
        </Button>
      )}
      {data?.terminal && (
        <Button
          variant={getVariantButton(data.terminal)}
          onClick={submitting ? noop : handleTerminal}
          disabled={submitting || invalid}
        >
          {data.terminal.name}
        </Button>
      )}
      <Recovery />
      <ConfirmBusyTable isOpen={force} confirm={nextStatus} decline={resetForce} />
    </div>
  );
}
