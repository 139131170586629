import {Restaurant} from './restaurant';
import {Table} from './table';


export interface Place {
  id: number;
  placeId: number;
  number: string;
  type: number;
  schemaTable: Table;
  place: {
    id: number;
    restaurantId: number;
    name: string;
    weight: number;
    restaurant: Restaurant;
  };
}


export enum SeatType {
  BOOK = 'BOOK',
  IN_WALK = 'IN_WALK',
  WAIT_LIST = 'WAIT_LIST'
}

export interface UpdatePlaceDTO {
  id?: number;
  restaurant_id: number;
  name: string;
  weight: number;
  schedule: unknown;
}
