import {Children, cloneElement,
  FC, forwardRef, isValidElement, MouseEvent, ReactElement, ReactNode,
  Ref, useCallback, useEffect, useMemo, useState,
  useImperativeHandle} from 'react';
import cn from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import {usePopper} from 'react-popper';
import {useBooleanState} from 'hooks/useBooleanState';
import {Placement} from '@popperjs/core';
import {stopPropagation} from 'utils';

import styles from './Popup.module.scss';

const Trigger: FC = ({children}) => <>{children}</>;

interface ContentProps{
  noPadding?: boolean | {
    top?: boolean, bottom?: boolean, left?: boolean, right?: boolean
  }
}
const Content: FC<ContentProps> = ({children}) => <>{children}</>;

export interface PopupRef{
close: () => void
}

export interface PopupProps {
  children: ReactNode;
  placement?: Placement;
  onClose?: () => void;
  onOpen?: () => void;
}

const PopupBase = forwardRef(({children, placement = 'auto', onClose, onOpen}: PopupProps, ref: Ref<PopupRef>) => {
  const [referenceElement, setReferenceElement] = useState<null | HTMLElement>(null);
  const [popperElement, setPopperElement] = useState<null | HTMLElement>(null);
  const [trigger, content] = useMemo(() => Children.toArray(children)
    .reduce<[ReactElement | undefined, ReactElement<ContentProps> | undefined]>((acc, el) => {
    if (!isValidElement(el)) return acc;
    if (el.type === Trigger) {
      acc[0] = el;
      return acc;
    }
    if (el.type === Content) {
      acc[1] = el;
      return acc;
    }
    return acc;
  }, [undefined, undefined]), [children]);

  const {styles: popperStyles, attributes} = usePopper(referenceElement, popperElement, {
    strategy: 'fixed',
    placement,
  });

  const [isOpen, open, _close] = useBooleanState();

  const close = useCallback(() => {
    onClose?.();
    _close();
  }, [onClose, _close]);

  useImperativeHandle(ref, () => ({
    close,
  }));

  useEffect(() => {
    window.addEventListener('scroll', close, true);
    return () => {
      window.removeEventListener('scroll', close, true);
    };
  }, []);

  const handleClick = useCallback((e: MouseEvent) => {
    stopPropagation(e);
    if (!isOpen) {
      onOpen?.();
    }
    const change = isOpen ? close : open;
    change();
  }, [open, isOpen]);

  const paddingStyles = useMemo(() => {
    const {noPadding} = content?.props || {};
    if (!noPadding) return undefined;
    if (typeof noPadding === 'boolean') {
      return {[styles.noPadding]: noPadding};
    }
    return {
      [styles.noLeftPadding]: noPadding.left,
      [styles.noRightPadding]: noPadding.right,
      [styles.noTopPadding]: noPadding.top,
      [styles.noBottomPadding]: noPadding.bottom,
    };
  }, [content?.props.noPadding]);

  return (
    <OutsideClickHandler onOutsideClick={close} useCapture display="contents">
      {trigger?.props?.children && cloneElement(trigger.props.children, {onClick: handleClick, ref: setReferenceElement})}
      {isOpen && (
        <div
          ref={setPopperElement}
          style={popperStyles.popper}
          {...attributes.popper}
          className={cn(styles.popup, attributes.popper?.className, paddingStyles)}
        >
          {content}
        </div>
      )}
    </OutsideClickHandler>
  );
});

export const Popup = Object.assign(PopupBase, {
  Trigger,
  Content,
});
