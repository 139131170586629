import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {buildTimeOptions} from 'components/registration/sub-renders/utils';
import {Props} from 'react-select';
import {SelectBasic} from 'ui-kit';
import moment from 'moment';

import styles from "./TimeRangePicker.module.scss";
import {SingleOption} from "models/common";
import {ICONS} from "common/helpers";
import {useIntlUtils} from "../../hooks/useIntlUtils";
import {ETranslations} from "../../types/translates";
import {useMobileDevice} from '../../hooks/useMobileDevice';

const components: Props<SingleOption>['components'] = {
  DropdownIndicator: null,
};
const getStyles: Props<SingleOption>['styles'] = {
  menu: (base, state) => {
    const {selectProps: {menuPlacement}} = state;
    const menuIsTop = menuPlacement === 'top';

    const marginTop = menuIsTop ? 0 : '-5px';
    const marginBottom = menuIsTop ? '-5px' : 0;

    return ({
      ...base,
      marginTop,
      marginBottom,
      border: 'none',
      boxShadow: 'none',
      backgroundColor: 'var(--select_default_background_1)',
      zIndex: 100,
    });

  },
    valueContainer() {
      return {
        height: 'var(--height-a)',
        flex: '1 0',
      };
    },
    control() {
      return {
        border: 'none',
        display: 'flex',
      };
    },
    input() {
      return {
        height: 'var(--height-a)',
        display: 'flex',
        alignItems: 'center',
        font: 'var(--font-12-r)'
      };
    },
    clearIndicator(s) {
      return {
        ...s,
        height: 'var(--height-a)',
        flex: '1 0',
        padding: '0 8px',
        display: 'flex',
        alignItems: 'center',
      };
    },
    indicatorsContainer() {
      return {
        height: 'var(--height-a)',
        flex: '1 0',
      };
    },
  };

export type Range = [string | null, string | null];

interface TimeRangePickerProps {
  range: Range;
  onRangeChange: (nextRange: Range) => void;
  restaurantWorkedTime: {start: string, duration : number}
}

export function TimeRangePicker({range: [from, to], onRangeChange, restaurantWorkedTime}: TimeRangePickerProps) {
  const {getIntlJoinedParts} = useIntlUtils();
  const isMobileDevice = useMobileDevice();

  const intlTitles = useMemo(() => ({
    timeFrom: getIntlJoinedParts([ETranslations.BASE_TIME, ETranslations.FROM]),
    timeTo: getIntlJoinedParts([ETranslations.BASE_TIME, ETranslations.TO])
  }), [])

  const defaultOptions = {
    bookingIntervalInMinutes: 0,
    minTime: restaurantWorkedTime.start,
    workDurationInMinutes: restaurantWorkedTime.duration,
    globalCurrentDate: moment(),
    selectedDate: moment(),
    skipDateCheck: true,
  };

  const [options, setOptions] = useState(() => buildTimeOptions(defaultOptions));

  useEffect(() => {
    setOptions(() => buildTimeOptions(
      {...defaultOptions, minTime: restaurantWorkedTime.start, workDurationInMinutes: restaurantWorkedTime.duration},
    ));
  }, [restaurantWorkedTime]);

  const changeStart = useCallback((option: SingleOption | null) => {
    onRangeChange([option?.label ?? null, to]);
  }, [to]);

  const changeEnd = useCallback((option: SingleOption | null) => {
    onRangeChange([from, option?.label ?? null]);
  }, [from]);

  const formValue = useMemo(() => options.find((o) => o.label === from), [from, options]);
  const toValue = useMemo(() => options.find((o) => o.label === to), [to, options]);

  return (
    <div className={styles.TimeRangePicker}>
        <SelectBasic
          options={options} placeholder={intlTitles.timeFrom}
          // @ts-ignore
          components={components}
          // @ts-ignore
          styles={getStyles}
          value={formValue}
          // @ts-ignore
          onChange={changeStart}
          isClearable
          isSearchable={!isMobileDevice}
        />
        <ICONS.TimeArrow />
        <SelectBasic
          options={options}
          placeholder={intlTitles.timeTo}
          // @ts-ignore
          components={components}
          // @ts-ignore
          styles={getStyles}
          value={toValue}
          // @ts-ignore
          onChange={changeEnd}
          isClearable
          isSearchable={!isMobileDevice}
        />
        <ICONS.Clock />
    </div>
  );
}
