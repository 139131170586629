import React, {FC} from "react";
import {SelectBasic, SelectBasicProps} from "./SelectBasic";
import {StylesConfig} from "react-select";
import {useIntlUtils} from "../../hooks/useIntlUtils";
import {ETranslations} from "../../types/translates";

type OptionTagType = {
  color: string,
  label: string,
  value: number
}

const customStyles: StylesConfig<OptionTagType, true> = {
  multiValue: (base, {data}) => ({
    ...base,
    borderRadius: '20px',
    height: '20px',
    color: 'var(--gl_text_constant_primary2)',
    alignItems: 'center',
    marginBottom: '3px',
    backgroundColor: data.color || 'var(--tags_constant_background_-5)',
    paddingLeft:10,
    paddingRight: 4,
    font: 'var(--font-12-b)'
  }),
  multiValueLabel: () => ({
    padding: '0',
    color: 'var(--gl_text_constant_primary2)',
  }),
  multiValueRemove: (base) => ({...base, ':hover': {backgroundColor: 'none', cursor: 'pointer'}}),
};


export const SelectTag: FC<SelectBasicProps<OptionTagType>> = (props) => {
  const {getIntlChooseEntity} = useIntlUtils();

  return (
    <SelectBasic
      // @ts-ignore
      styles={customStyles}
      isSearchable={false}
      isMulti
      isClearable
      closeMenuOnSelect={false}
      placeholder={getIntlChooseEntity(ETranslations.PLURAL_TAGS_NOM)}
      {...props}
    />
  );
};
