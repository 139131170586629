import {ReactNode} from 'react';
import cn from 'classnames';

import {useIsTablet} from '../hooks';
import {ICONS, Button} from '..';
import {useCloseCard} from './useCloseCard';

import styles from './Card.module.scss';

interface BlockHeaderProps{
  children?: ReactNode;
  title?: ReactNode;
  controls?: ReactNode;
  className?: string;
}

export function Header({children, title, controls, className}: BlockHeaderProps): JSX.Element {
  const close = useCloseCard();
  const isTablet = useIsTablet();
  return (
    <div className={cn(styles.header, className)}>
      <div className={cn(styles.headerContent)}>
        {title && <h2 className={styles.title}>{title}</h2>}
        {!isTablet && children && <div className={styles.subHeader}>{children}</div>}
        {controls && (
          <div className={styles.controls}>
            {controls}
          </div>
        )}
        {close && (
          <Button variant="phantom" square onClick={close} className={styles.close}>
            <ICONS.Cross />
          </Button>
        )}
      </div>
      {isTablet && children && (
        <div className={styles.subHeader}>
          {children}
        </div>
      )}
    </div>
  );
}
